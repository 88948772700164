appServices.service('AuthService', [
    '$state',
    '$rootScope', 
    '$http', 
    'Session', 
    '$cookies', 
    '$q', 
    //'$intercom', 
    'AUTH_EVENTS', 
    function(
        $state, 
        $rootScope, 
        $http, 
        Session, 
        $cookies, 
        $q, 
        //$intercom, 
        AUTH_EVENTS
    ) {

	var authService = this
	this.login = function(credentials) {
        var self = this
        var deferred = $q.defer();

        self.isTokenValid()
            .then( function(user) {
                deferred.resolve(user)
            }, function(){
                $http.post('/api/user/login', credentials).then(function(res, status) {
                    Session.create(res.data.user)
                    deferred.resolve(res.data);
                }, function(res, status) {
                    deferred.reject(res.data);
                });
            })
        return deferred.promise;
    }
    
    this.forgotPassword = function(credentials) {
	    
	    var deferred = $q.defer();
	    
		$http.post('/forgot_password', credentials).then(function(res, status) {
			deferred.resolve(res.data);
		}, function(res, status) {
            deferred.reject(res.data);
        });
	    
	    return deferred.promise;
	    
    }
    
    this.isTokenValid = function(){
        var deferred = $q.defer()
        //var token = $cookies.get('token')
        //if(token) {
        $http.post ( '/api/user/check_auth' ).then ( function ( res ) {
            Session.destroy ()
            Session.create ( res.data.user)
            deferred.resolve(res.data.user)

            $rootScope.$broadcast ( AUTH_EVENTS.loginSuccess );

            // $intercom.update({
            //     id: res.data.user.id,
            //     email: res.data.user.email,
            //     name: res.data.user.first_name + " " + res.data.user.last_name
            // });
        },function(error){
            $rootScope.$broadcast ( AUTH_EVENTS.notAuthenticated );
            deferred.reject()
        })

        return deferred.promise
    }
    
    this.isAuthenticated = function () {
        var deferred = $q.defer()
        if(!Session.userId){
			authService.isTokenValid().then(function(user){
                deferred.resolve(!!Session.userId)
            }, function(){
                deferred.reject(!!Session.userId)
            })
        } else {
            deferred.resolve(!!Session.userId)
        }
        return deferred.promise
    }
    
	this.isAuthorized = function (authorizedRoles) {
        if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
        }
        return (authService.isAuthenticated() && authorizedRoles.indexOf(Session.userRole) !== -1);
    }
        
}]);