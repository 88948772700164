/*appFactories.factory('AuthService',["$http","Session","$cookies","$q",
    function($http, Session, $cookies, $q) {
        var authService = {}
        authService.login = function ( credentials ) {
            return $http
                .post ( '/api/user/login', credentials )
                .then ( function ( res ) {
                Session.create ( res.data.user)
                return res.data.user
            } )
        }

        authService.isTokenValid = function(){
            var deferred = $q.defer()
            var token = $cookies.get('token')
            if(token) {
                $http.post ( '/api/user/check_auth', {token: token} ).then ( function ( res ) {
                    Session.destroy ()
                    Session.create ( res.data.user)
                    deferred.resolve(res.data.user)
                } )
            } else {
                deferred.reject()
            }
            return deferred.promise
        }

        authService.isAuthenticated = function () {
            var deferred = $q.defer()
            if(!Session.userId){
                this.isTokenValid().then(function(user){
                    deferred.resolve(!!Session.userId)
                }, function(){
                    deferred.reject(!!Session.userId)
                })
            } else {
                deferred.resolve(!!Session.userId)
            }
            return deferred.promise
        }

        authService.isAuthorized = function (authorizedRoles) {
            if (!angular.isArray(authorizedRoles)) {
                authorizedRoles = [authorizedRoles];
            }
            return (authService.isAuthenticated() && authorizedRoles.indexOf(Session.userRole) !== -1);
        };

        return authService;
    }
]);*/