angular.module('app.constants', [])

.constant('AUTH_EVENTS', {
	loginSuccess: 'auth-login-success',
	loginFailed: 'auth-login-failed',
	logoutSuccess:'auth-logout-success',
	sessionTimeout:'auth-session-timeout',
	notAuthenticated:'auth-not-authenticated',
	notAuthorized:'auth-not-authorized'
})

.constant("PORTFOLIO_WEBSERVER", {
	app: ""
})

.constant('USER_ROLES',{
	all:'*',
	admin:'admin',
	guest:'guest',
	user:'user'
})

.constant('MATH_OPERATORS',{
	"+" : 0,
	"-" : 1,
	"*" : 2,
	"/" : 3,
	"set" : 4
})

.constant('LOGIC_OPERATORS',{
	"==": 0,
	"<": 1,
	"<=": 2,
	">" : 3,
	">=" :4
})

.constant(
	'_', window._
)

.constant(
	'INTERCOM_APPID', window.intercom_id
);