appControllers.controller('DocusignController',["$scope","Session","$http","$sce",
    function($scope, Session, $http, $sce){
        $scope.user = {
            id: Session.id,
            first_name:Session.first_name,
            last_name:Session.last_name,
            email:Session.email
        }
        $scope.account_holder = {}
        $scope.account_holder.AHEmail = $scope.user.email
        $scope.account_holder.AHFirstName = $scope.user.first_name
        $scope.account_holder.AHLastName = $scope.user.last_name
        $scope.docusign = {
            valid: false,
            url:''
        }


        $scope.save = function(){
            $http.put('/api/nbcn_account', {account_holder: $scope.account_holder, accounts: $scope.account} ). then(function(res){
                //$scope.account_holder = res.data.account_holder_data
            }, function(error){
            })
        }

        $scope.submit = function(){
            $http.post('/api/nbcn_account', {account_holder: $scope.account_holder, accounts: $scope.account} ). then(function(res){
                //$scope.account_holder = res.data.account_holder_data
                $scope.docusign.url = $sce.trustAsResourceUrl(res.data.url)
                $scope.docusign.valid = true
            }, function(error){
            })
        }

        var getForm = function(){
            $http.get('/api/nbcn_account'). then(function(res){
                $scope.account_holder = res.data.account_holder_data
                $scope.account_holder.AHEmail = $scope.user.email
                $scope.account_holder.AHFirstName = $scope.user.first_name
                $scope.account_holder.AHLastName = $scope.user.last_name
                $scope.account = res.data.accounts_data

                var temp = new Date($scope.account_holder.AHDob)
                if(typeof $scope.account_holder.AHSDob != 'undefined'){
                    var tempAHSDob = new Date($scope.account_holder.AHSDob)
                    $scope.account_holder.AHSDob =     new Date(tempAHSDob.getTime() + tempAHSDob.getTimezoneOffset()* 60 * 1000)
                }
                $scope.account_holder.AHDob = new Date(temp.getTime() + temp.getTimezoneOffset()* 60 * 1000)

            }, function(error){
            })
        }

        getForm()


    }
]);