angular.module('app.sso-implicit-callback.config', [
	'ui.router'
])

	.config([
		"$stateProvider",
		'NWUI_AUTH_ROLES',
		"$locationProvider",
		"$urlRouterProvider",
		function(
			$stateProvider,
			NWUI_AUTH_ROLES,
			$locationProvider,
			$urlRouterProvider
		) {

			$stateProvider.state('sso_implicit_callback', {
				url: '/sso/implicit/callback',
				templateUrl: 'join_webserver/sso-implicit-callback/template.html',
				controller: "SsoImplicitCallbackController",
				data: {
					auth_roles: []
				}
			});

		}
	]);