angular.module('app.maintenance.config', [
  'ui.router'
])

  .config([
    '$stateProvider',
    function(
      $stateProvider,
    ) {
      $stateProvider.state('onboarding.maintenance', {
        url: '/maintenance',
        views: {
          maintenance: {
            templateUrl: 'join_webserver/maintenance/template.html',
          }
        }
      });
    }
  ]);
