appControllers.controller('OnboardingController', [
	'$scope', 
	'$sce', 
	'$timeout',
	'$state', 
	'Session', 
	'$http',
	'AuthModalLaunchService', 
	'intercomUser',
	function(
		$scope, 
		$sce, 
		$timeout,
		$state, 
		Session, 
		$http, 
		AuthModalLaunchService, 
		intercomUser
	) {
		delete $scope.chartConfig;
		AuthModalLaunchService.register(function(){
			$scope.authShow = AuthModalLaunchService.show
		})
		$scope.authShow = AuthModalLaunchService.show
		//$scope.isAuthPage = AuthModalLaunchService.show;
		$scope.show_chart = false;
		$scope.existing = false;
		$scope.onboardingStep = 1;
		
		$scope.intercom_user = intercomUser;
		// $intercom.hide();

		$scope.toggleIntercom = function(e) {

			$scope.$parent.toggleIntercom(e);

		}
		
		$scope.closeIntercom = function() {

			// $scope.$parent.closeIntercom();

		}

		$scope.user = {
			first_name: '',
			last_name: '',
			email: '',
			age: '',
			principle: null,
			yearly_contribution: null,
			yearly_draw: null,
			draw:null,
			horizon: '',
			reason: '',
			user_saved: false,
			income: null,
			loss_comfort: null,
			amount: null,
			risk_score: null,
			savings: null,
			province: "Select One",
			dob: null,            
			for_choice: null
		}
		
		$scope.yearly_savings = null
		$scope.locations = [
			{text:"Select One", value:-2},
			{text:"Ontario", value:0},
			{text:"Alberta", value:1},
			{text:"British Columbia", value:2},
			{text:"Manitoba", value:3},
			{text:"New Bunswick", value:4},
			{text:"Newfoundland & Labrador", value:5},
			{text:"Nova Scotia", value:6},
			{text:"Northwest Territories", value:7},
			{text:"Nunavut", value:8},
			{text:"Prince Edward Island", value:9},
			{text:"Quebec", value:10},
			{text:"Saskatchewan", value:11},
			{text:"Yukon", value:12},
			{text:"Other", value:-1}
		]
		
		$scope.choices = [{ 
			id: 0, 
			val: 'build my retirement fund',
			val_html: $sce.trustAsHtml('build my retirement fund'),
			target_text: "Retired" 
		}, {
			id: 1, 
			val: 'generate income for retirement',
			val_html: $sce.trustAsHtml('generate income <br />for retirement'),
			target_text: "" 
		}, {
			id: 2, 
			val: 'save for a home',
			val_html: $sce.trustAsHtml('save for a home'), 
			target_text: "Target" 
		}, {
			id: 3, 
			val: 'create an education fund for a child',
			val_html: $sce.trustAsHtml('create an education <br />fund for a child'), 
			target_text: "Target"
		}];
		
		$scope.prefixes = [{
			id: 0,
			val: 'Mr.'
		}, {
			id: 1,
			val: 'Mrs.'
		}, {
			id: 2,
			val: 'Miss.'
		}, {
			id: 3,
			val: 'Ms.'
		}, {
			id: 4,
			val: 'Dr.'
		}]
		
		$scope.citizenship = [{
			id: 0,
			val: 'Canada'
		}, {
			id: 1,
			val: 'United States'
		}, {
			id: 2,
			val: 'Other'
		}]

		/*$scope.user.principle = 125000;
		$scope.user.yearly_contribution = 15000;
		$scope.user.age = 28;
		$scope.user.horizon = 35;
		$scope.user.reason = $scope.choices[0];*/
		
		function currencyFormat(num) {
			return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		}

		var draw_chart = function(chart_data){
			$scope.show_chart = true
			var title_gap = chart_data[0].data[ chart_data[0 ].data.length -1 ] - chart_data[1].data[ chart_data[1].data.length -1 ]
			title_gap = currencyFormat(title_gap)
			$scope.chartConfig = {//This is not a highcharts object. It just looks a little like one!
				options: {
					chart: {
						type: 'line',
						animation: {
							duration: 1000
						}
					},
					tooltip: {
						formatter: function(){
							if(this.series.index==0){
								return $filter('NwuiOrgSlugToShortName')($scope.user.org) + " Portfolio size: " + currencyFormat(this.y)
									+ '<br/>Which is ' + currencyFormat(this.y - chart_data[1 ].data[this.x]) + ' more wealth.';
							} else {
								return "Average Canadian Fund size: " + currencyFormat(this.y) + '<br/>'
									+ 'Which is ' + currencyFormat(chart_data[0 ].data[this.x] - this.y) + ' less wealth.';
							}
						},
						style: {
							padding: 10,
							fontWeight: 'bold'
						}
					},
					animation: {duration: 1000 }
				},
				series: chart_data
				,
				title: {
					text: 'Wealth Gap of ' + title_gap
				},
				yAxis: {
					opposite: true,
					min: chart_data[0 ].data[0],
					title : {
						text : "Portfolio Size"
					}
				},
				func: function (chart) {
				}
			};
		}
		
		var impressMe = function(){
			$http.post('/api/onboarding/page1', $scope.user ).then(function(res){
				draw_chart(res.data.series)
			})
		}

		var createFromLead = function(){
			$http.post('/api/user/' + $scope.user.id + '/create_from_lead', $scope.user ).then(function(res){
				angular.extend($scope.user, res.data.user)
				$state.go('questions')
			}, function(error){
			})
		}
		var createLead = function(){
			$http.post('/api/user/create_lead', $scope.user ).then(function(res){

				angular.extend($scope.user, res.data.user)
				Session.destroy()
				Session.create(res.data.user)
				//nextPage()
			},function(error){
				if(error.data == "Error: Email address already registered."){
					$scope.existing = true
				}
			})
		}

		$scope.createLead = createLead
		$scope.createFromLead = createFromLead

		$scope.yearlySavings = function(){
			if($scope.yearly_savings != null) {
				$scope.user.yearly_savings = $scope.yearly_savings.replace(/[, ]+/g, " ").trim();
			}
		}

		$scope.impressMe = impressMe
		
		var setOnboardingStep = function(num) {
			$scope.onboardingStep = num;
		}
		
		$scope.setOnboardingStep = setOnboardingStep;
		/*
		var setAuthPage = function(bool) {
			$scope.isAuthPage = true;
		}*/
		
		//$scope.setAuthPage = setAuthPage;

	}
]);
