appDirectives.directive('nwupload', ['FormService', 'ValidationService', 'NwuiSession', 'Upload', '$q', '$timeout', '$http', function(FormService, ValidationService, NwuiSession, Upload, $q, $timeout, $http) {
    
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
	        field: '=field'
        },
        templateUrl: 'join_webserver/partials/common/form/upload.html',
        link: function(scope, element, attrs) {
			
			scope.step_showing = false;
			scope.field.input.valid = true;
			scope.processing = false;
			scope.field.input.answer = FormService.answers[scope.field.input.model] || "";
			scope.text = scope.field.input.answer;
			scope.filled = (scope.field.input.answer) 
			scope.type = scope.field.input.answer.split('/')[1];
			
			var step_change = function(is_showing) {
				scope.step_showing = is_showing;
			}

			var readFile = function(file) {


				var deferred = $q.defer();

				var reader = new FileReader();
				reader.onload = function(e) {
					deferred.resolve(e.target.result);
				};
				reader.onerror = function(e) {
					deferred.reject(e);
				};

				if(file === null) {
					deferred.reject(e);
				} else {
					reader.readAsDataURL(file);
				}

				return deferred.promise;
			}

			var dataURItoBlob = function ( dataURI ) {
				// convert base64/URLEncoded data component to raw binary data held in a string
				var byteString;
				if ( dataURI.split( ',' )[ 0 ].indexOf( 'base64' ) >= 0 )
					byteString = atob( dataURI.split( ',' )[ 1 ] ); else
					byteString = unescape( dataURI.split( ',' )[ 1 ] );

				// separate out the mime component
				var mimeString = dataURI.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ];
				
				scope.field.input.valid = ValidationService.image(mimeString, ['image']);
				
				if(!scope.field.input.valid) {
					
					scope.delete();
					return;
					
				}
				
				scope.type = mimeString.split('/')[1];
				

				// write the bytes of the string to a typed array
				var ia = new Uint8Array( byteString.length );
				for ( var i = 0; i < byteString.length; i++ )
				{
					ia[ i ] = byteString.charCodeAt( i );
				}
				return new Blob( [ ia ], {type : mimeString} );
			}

			var uploadFileToUrl = function(file, uploadUrl, user_id, filename, callback) {
				var fd = new FormData();
				fd.append('file', file, filename);
				fd.append('id', user_id)
				fd.append('type', file.type)

				var uploadInputTemplate = scope.field.input.model;
				var isBTStatement = uploadInputTemplate.startsWith('BTStatement');

				$http.get('/api/user/document-templates').then(function(obj) {
					var document_id = obj.data.filter(function(doc) {
						if (isBTStatement) {
							return doc.template_slug === uploadInputTemplate;
						} else {
							return 'File' + doc.template_slug === uploadInputTemplate;
						}
					})
					.pop()
					._id;

					$http.post('/api/user/user/' + NwuiSession.user.id + '/document/' + document_id, fd, {
						transformRequest: angular.identity,
						headers: {'Content-Type': undefined }
					})
					.then(function(res){
						scope.processing = false;
						scope.filled = true;
						scope.show_thumb = true;
						scope.field.input.answer = file.type;
						scope.text = scope.field.input.answer;
						FormService.answers[scope.field.input.model] = scope.field.input.answer;
						
						scope.field.input.valid = FormService.validate('change', scope.field, ['blank']);
					}, function(res){
						scope.processing = false;
						scope.field.input.valid = false;
					});
				});
			}
			
			scope.delete = function() {
				scope.filled = false;
				scope.processing = false;
				scope.field.input.answer = undefined;
				FormService.answers[scope.field.input.model] = scope.field.input.answer;
				
				$timeout(function() {
					scope.text = scope.field.input.answer;
				}, 500);
			}
			
			scope.upload = function(file) {
				
				scope.processing = true;
				//var files = event.files;
				readFile(file).then(function(result){
					var blob = dataURItoBlob(result)
					var filename = file.name
					var uploadUrl = scope.field.input.upload_url
					uploadFileToUrl( blob, uploadUrl, NwuiSession.user.id,filename, function ( data ) {
					} )
				}, function(error){
					scope.processing = false;
					scope.field.input.answer = 'invalid';
					scope.field.input.valid = FormService.validate('change', scope.field, ['upload_format']);
				})

			};
	        
      FormService.register_input_step_observer_cb(step_change, scope.field.input.model);
	        
	    },
	    controller: ['$scope', function($scope) {
		}]
	}

}]);