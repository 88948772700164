appServices.service('User', ['$http', '$q', function($http, $q) {
    
    this.create = function(user) {
	    
	    var deferred = $q.defer();
        $http.post('api/user/create', user).then(function(res, status) {
            deferred.resolve(res.data);
        }, function(res, status) {
            deferred.reject(res.data);
        });

        return deferred.promise;
	    
    }
    
    this.revalidate = function(credentials) {
	    
	    var deferred = $q.defer();
	    
        $http.post('api/user/revalidate', credentials).then(function(res, status) {
            deferred.resolve(res.data);
        }, function(res, status) {
            deferred.reject(res.data);
        });

        return deferred.promise;
	    
    }

    this.updateUserLang = function(lang) { 
	    return $http.put('/api/user', JSON.stringify({
 		    language: lang
 		}));
 	}
    
}]);