appServices.service('PageTitleService', [
	'$filter',
	function(
		$filter
	) {
	
	//self
	var self = this;
	var observerCallbacks = [];
	
	this.title = "Join";
	
	/* public methods */
	
	//register an observer
	this.registerObserverCallback = function(cb) {
		observerCallbacks.push(cb);
	}
	
	this.setPageTitle = function(title) {

		this.title = (title)
			? "Join - " + title
			: "Join";
		
		if(window.org_slug) {
			this.title += (' - ' + $filter('NwuiOrgSlugToShortName')(window.org_slug));
		}

		notifyObservers();
		
	}
	
	/* private methods */
	
	//notify observers
	var notifyObservers = function() {
		angular.forEach(observerCallbacks, function(cb) { cb(); });
	}
	
}]);