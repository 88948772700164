appControllers.controller('CalcChartController',['$scope', function($scope) {

	var showDrawDown = function() {
		$scope.$parent.show_chart_draw = true;
		$scope.updatePhrase();
	}
	
	$scope.showDrawDown = showDrawDown;
	
	var hideDrawDown = function() {
		$scope.$parent.show_chart_draw = false;
		$scope.updatePhrase();
	}
	
	$scope.hideDrawDown = hideDrawDown;
	
}]);