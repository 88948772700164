angular.module('app.login.controllers', [])

.controller('LoginController',[
	"$scope", 
	"$rootScope", 
	"$timeout", 
	"$state",
	"$cookies",
	"$sce",
	"DomainParserService",
	"locale",
	"NWUI_AUTH_EVENTS",
	"AuthService",
	"GeneralService",
	"NwuiButtonV3Factory",
	function(
		$scope, 
		$rootScope, 
		$timeout, 
		$state, 
		$cookies, 
		$sce,
		DomainParserService,
		locale,
		NWUI_AUTH_EVENTS, 
		AuthService,
		GeneralService,
		NwuiButtonV3Factory
	) {
		const domain = DomainParserService.parse(window.location.href);
		$scope.FINANCIAL_ADVISOR_ROLE = 'Advisor';
		$scope.FINANCIAL_PLANNER_ROLE = 'Financial Planner';
		$scope.isReferredByAdvisor = false;
		$scope.isReferredByNonAdvisor = true;
		$scope.referral_terms_url = com_web + '/referral-terms-and-conditions';
		$scope.showAdvisorConsentPage = false;

		const updateAdvisorConsentPage = function() {
			$scope.showAdvisorConsentPage = $scope.isReferredByAdvisor && !$scope.referrer.referral_signup;
			$scope.showSignupPage = ($scope.isReferredByAdvisor && $scope.referrer.referral_signup ) || !$scope.referrer && $scope.data.show_welcome_text;
		}

		const updateReferredByFlags = function() {
			$scope.isReferredByAdvisor = ($scope.referrer && $scope.referrer.role_name &&
				($scope.referrer.role_name === $scope.FINANCIAL_ADVISOR_ROLE || 
				$scope.referrer.role_name === $scope.FINANCIAL_PLANNER_ROLE));

			$scope.isReferredByNonAdvisor = ($scope.referrer && !$scope.isReferredByAdvisor);
		}
		const getDefaultData = function() {
			$scope.data = {
				org_slug: org_slug,
				copy: [],
				show_signup: organization_data.join_data.auth.sign_up,
				show_referral_source: organization_data.join_data.auth.referral_source,
				show_welcome_text:  organization_data.join_data.show_welcome_text,
				show_welcome_image:  organization_data.join_data.show_welcome_image,
				show_company_signup: organization_data.join_data.auth.company,
				show_language_preference: organization_data.join_data.auth.language_preference,
				default_form: 1
			}
		}

		getDefaultData();
		
		$scope.altLogoOpts = {};

		$scope.show_nbf_login_banner = organization_data && organization_data.slug === 'nbf'
			? true
			: false;

		$scope.referral_consent_accept_consent_checkbox = {
			id: 'referral_consent_accept_consent_checkbox',
			text_i18n: 'referral.financial_planner.consent',
			value: false
		};

		$scope.not_advised_signup_click = function(e) {
			$cookies.remove('nw-referrer');
			$cookies.remove('nw-referrer-code');
			delete $scope.referrer;
			getDefaultData();
		}


		const getCookie = function() {
			if($cookies.get('nw-referrer')) {
				$scope.referrer = JSON.parse($cookies.get('nw-referrer'));
				if ($scope.referrer && $scope.referrer.referral_signup) {
					$scope.data.default_form = 2;
				}

				updateReferredByFlags();

				updateAdvisorConsentPage();
				
			}
		};
		
		getCookie();

		$scope.referral_consent_signup_button = new NwuiButtonV3Factory({
			id: 'referral_consent_signup_button',
			text_i18n : 'referrals.referral_consent_signup_text',
			color: '',
			stretch: false,
			onClick: function() {
				$scope.referrer.referral_signup = true;
				$cookies.putObject('nw-referrer', $scope.referrer, {domain: domain});
				getCookie();
			}
		});
	



		$scope.has_sub_logo = (
			organization_data && 
			organization_data.join_data && 
			organization_data.join_data.header && 
			organization_data.join_data.header.sub_logo
		)

		$scope.changeLocale = function(new_lang) {			
			locale.setLocaleCookie(new_lang, domain);

			GeneralService.setLanguage(new_lang).then(function(res) {
				window.location.href = res.data[new_lang] + window.location.pathname + '?lang='+new_lang;
			}).catch(function() {
				console.error("There was an error switching the language");
			})

		}

		if($scope.has_sub_logo) {
			$scope.altLogoOpts = {
				imgName : 'alt-header-logo.png', 
				imgPath: 'img/brand/'+org_slug, 
				'class': 'alt-logo'
			}
		}


	}
]);