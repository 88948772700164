appServices.service('KYCInstance', [
	"KYCInstanceResource", 
	"KYCService", 
	"$http",
	"$q",
	function(
		KYCInstanceResource, 
		KYCService, 
		$http, 
		$q
	) {
	
		var KYCInstance = {}

		KYCInstance.get = function(kyc_instance_id) { 
			return KYCInstanceResource.get({id: kyc_instance_id}).$promise
		}

		KYCInstance.create = function() {
			var deferred = $q.defer()
			KYCInstanceResource.save().then(function(res){
				KYCService.save(res);
				return deferred.resolve(res)
			}, function(error){
				return deferred.reject(error)
			})
			return deferred.promise
		}

		KYCInstance.getChartData = function(kyc_instance_id) {
			return KYCInstanceResource.getChartData({id:kyc_instance_id}).$promise
		}

		return KYCInstance;

	}
]);