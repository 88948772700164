'use strict';

angular.module('app.remote-logging.service', [])
  .service('RemoteLoggingService', ['$http', 
    function($http) {
      this.remoteLoggingUrl = 'https://logs.logdna.com/logs/ingest';

      this.log = function(contents) {
        return this.sendToRemoteServer(contents);
      };

      this.sendToRemoteServer = function(contents) {
        var ingestionKey = this.getIngestionKey();
        if (!ingestionKey) {
          return;
        }

        var requestParameters = {
          method: 'POST',
          url: this.buildUrl(),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + this.base64Encode(ingestionKey)
          },
          data: this.formatBody(contents)
        };

        return $http(requestParameters);
      };

      this.buildUrl = function() {
        return this.remoteLoggingUrl +
          '?hostname=' + this.getHostName() +
          '&tags=' + this.getTags() +
          '&now=' + new Date().getTime();
      };


      this.getHostName = function() {
        return window.location.hostname;
      };

      this.getIngestionKey = function() {
        return organization_data && organization_data.remoteLoggingId;
      };

      this.base64Encode = function(str) {
        return btoa(str);
      };

      this.getTags = function() {
        return ['client-side', this.getEnv()].join(',');
      };

      this.formatBody = function(loggingLine) {
        return {
          lines: [
            {
              line: loggingLine,
              app: 'join-web',
              level: 'INFO'
            }
          ]
        };
      };

      this.getEnv = function() {
        switch (env) {
          case 'dev':
            return 'dev';
          case 'uat':
            return 'uat';
          case 'production':
            return 'prod';
          default:
            return '';
        }
      };
    }
  ]);
