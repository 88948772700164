appDirectives.directive('formresults', ['$timeout', '$window', function($timeout, $window) {
    return {
        restrict: "E",
        transclude: true,
        replace: true,
        scope: {
	        'overview': '=overview',
	        'portfolio': '=portfolio'
        },
        templateUrl: 'join_webserver/partials/form/form-results.html',
        link: function(scope, element, attrs) {
	        
	        var targets = $('.accordion-block .accordion');
	        
	        scope.style_content = [];
	        scope.organization_data = scope.$parent.organization_data;
	        
	        scope.toggle = function(e, i) {
		        
		        var accordion = $(e.currentTarget).parent();
		        var size = accordion.find('.size');
		        var content = accordion.find('.content');
		        
		        if(accordion.hasClass('open')) {
			        
			        accordion.removeClass('open');
			        
			        scope.style_content[i] = {
				        height: 0
			        }
			        
		        } else {
			        
			        accordion.addClass('open');
			        
			        
			        scope.style_content[i] = {
				        height: size.height() + 'px'
			        }
			        
		        }
		        
	        }
	        
	        var calibrate = function(obj, i) {
		        
		        var accordion = $(obj);
		        var accordion_number = accordion.attr('data-accordion');
		        var size = accordion.find('.size');
		        var content = accordion.find('.content');
		        
		        if(accordion.hasClass('open')) {
			        
			        scope.style_content[accordion_number] = {
				        height: size.height() + 'px'
			        }
			        
		        } else {
			        
			        scope.style_content[accordion_number] = {
				        height: 0
			        }
			        
		        }
		        
		    }
	        
	        $timeout(function() {
		        targets = $('.accordion-block .accordion');
		        //var count = 2-targets.length;
		        
		        angular.forEach(targets, function(title, i) {
			        calibrate(title, i);
		        })
			}, 500);
	        
        }
    }
}]);