appDirectives.directive('numberMask', ['$filter', function($filter) {
    return {
        restrict: "A",
        scope: {
	    	model: '=ngModel'    
        },
		link: function(scope, element, attrs, ngModelController) {
			
			scope.$watch('model', function(newVal) {
				
				if(newVal === undefined || newVal === null) return;

				if(newVal)
					newVal = (newVal === parseInt(newVal, 10)) ? newVal : parseInt(newVal.replace(/[^0-9]/g, '').trim(), 10);
				
				scope.model = $filter('number')(newVal, 0);
				
			});
			
	    }
    }
}]);