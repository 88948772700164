appDirectives.directive('question', ['$compile', function ($compile) {
    return {
        restrict: 'AE',
        scope:{
            node : '=question'
        },
        controller: ["$scope","$element","$http","ResponseFactory", function ($scope, $element,$http, ResponseFactory) {
            $scope.question = $scope.node.KycSchemaQuestion
            $scope.kyc_instance_id = $scope.node.kyc_instance_id


            if($scope.question.type==2){
                var tpl = 'join_webserver/partials/questions/integer.html'
                $http.get(tpl ).then(function(res){
                    $element.html($compile(res.data)($scope))
                })

                var response = {
                    type_integer: true,
                    kyc_instance_id: $scope.kyc_instance_id,
                    kyc_version_node_id: $scope.node.id
                }
                angular.extend(response, $scope.node.response)

                $scope.response = new ResponseFactory(response)


            }
            if($scope.question.type==4){
                $scope.question.radio = angular.fromJson($scope.question.type_radio_text)
                var tpl = 'join_webserver/partials/questions/radio.html'
                $http.get(tpl ).then(function(res){
                    $element.html($compile(res.data)($scope))
                })
                var response = {
                    type_radio: true,
                    kyc_instance_id: $scope.kyc_instance_id,
                    kyc_version_node_id: $scope.node.id
                }
                angular.extend(response, $scope.node.response)
                $scope.response = new ResponseFactory(response)



            }
            if($scope.question.type==5){
                $scope.question.checkbox = angular.fromJson($scope.question.type_checkbox_text)
                var tpl = 'join_webserver/partials/questions/checkbox.html'
                $http.get(tpl ).then(function(res){
                    $element.html($compile(res.data)($scope))
                })

            }

        }]
    };
}] )