appServices.service('SigningStatus', [
	'$q',
	'$http',
	function(
		$q,
		$http
	) {
		
		var self = this;
	
		this.get = function() {
	
			var deferred = $q.defer()
	
			$http.get('/api/onboarding_status').then(function(obj){
				deferred.resolve(obj)
			}, function(err) {
				deferred.reject(err)
			})
	
			return deferred.promise;
	
		}

		this.poll = function(envelope_id) {
	
			var deferred = $q.defer()
	
			$http.get('/api/user/signing-envelope/' + envelope_id + '/poll').then(function(obj){
				deferred.resolve(obj.data)
			}, function(err) {
				deferred.reject(err)
			})
	
			return deferred.promise;
	
		}
		
	}
]);