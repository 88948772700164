angular.module('app.signature.directives', [])

	.directive("ngOnload", [
		function () {
			return {
				restrict: "A",
				scope: {
					callback: "&ngOnload"
				},
				link: function (scope, element, attrs) {

					element.on("load", function (event) {
						scope.callback({ event: event })
					});
				}
			}
		}
	]);