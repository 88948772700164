angular.module('app.signup.config', [
	'ui.router'
])

.config([
	"$stateProvider", 
	'NWUI_AUTH_ROLES', 
	"$locationProvider", 
	"$urlRouterProvider", 
	function(
		$stateProvider, 
		NWUI_AUTH_ROLES, 
		$locationProvider, 
		$urlRouterProvider
	) {

		if(organization_data.join_data.sign_up.show !== true) return;

		$stateProvider.state('signup', {
			url:'/signup',
			templateUrl: 'join_webserver/signup/template.html',
			controller: "SignupController",
			data: {
				auth_roles: []
			}
		});
		
	}
]);