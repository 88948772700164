angular.module('app.footer.directives', [])

.directive('joinFooter', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: "E",
			transclude: true,
			replace: true,
			templateUrl: 'join_webserver/footer/template.html',
			link: function(scope, element, attrs) {

				scope.data = {
					year: moment().format('YYYY'),
					org: organization_data
				}

			}
		}
	}
]);