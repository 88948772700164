appFactories.factory('ResponseFactory', ["ResponseResource","$rootScope", function(ResponseResource, $rootScope){
    /*
        type_string  : DataTypes.BOOLEAN,
        type_boolean : DataTypes.BOOLEAN,
        type_integer : DataTypes.BOOLEAN,
        type_number  : DataTypes.BOOLEAN,
        type_radio   : DataTypes.BOOLEAN,
        type_checkbox: DataTypes.BOOLEAN,

        response_string  : DataTypes.TEXT,
        response_boolean : DataTypes.BOOLEAN,
        response_integer : DataTypes.INTEGER,
        response_number  : DataTypes.DECIMAL(15,2),
        response_radio   : DataTypes.INTEGER,
        response_checkbox: DataTypes.TEXT
        */
    function Response(obj){
        var ir = this;
        ir.risk = ''
        ir.setData(obj)
    }
    Response.prototype = {
        setData : function(data){
            angular.extend(this, data)
        },
        save : function() {
            var response = this

            return ResponseResource.save(this).$promise
                .then(function(data){
                    /*for(var i = 0; i < data.kyc_instance_response.risk.length; i ++){
                        var myRes = data.kyc_instance_response.risk[i]
                        if(myRes.id == response.kyc_version_node_id){
                            response.risk = myRes.risk
                        }

                    }*/
                    $rootScope.$broadcast("update_chart")
                }, function(error){
                })
        }
    }
    return Response
}]);