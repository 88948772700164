'use strict';

appDirectives.directive('nwindicators', [
  '$timeout', 
  '$state',
  '$stateParams',
  '$window',
  'FormService',
  'NwuiSession',
  'FormAnswers',
  function(
    $timeout, 
    $state,
    $stateParams,
    $window,
    FormService,
    NwuiSession,
    FormAnswers
  ) {

    return {
      restrict: 'E',
      replace: true,
      scope: {
        steps: '=steps'
      },
      templateUrl: 'join_webserver/partials/common/nw-indicators.html',
      link: function(scope, element, attrs) {
        
        var indicator_width = 0;
        var count;
        const clientUserData = NwuiSession.userData.user;
        scope.is_wrapped = false;
        
        var calcWidth = function(count) {
          var width = (angular.element(element).children('.inner').width() - (indicator_width*count))/(count-1);
          scope.is_wrapped = (width < 5);

          return width
        }
        
        var calcStepIndicators = function() {
          count = 0;
          angular.forEach(scope.steps, function(step, i) {
            if(step.ng_if === undefined || !Object.keys(step.ng_if).length || scope.showing_step_ifs[i])
              count++;
            
            scope.indicator_step_number[i] = count;
          })
          
          var calc_width = calcWidth(count);
          
          angular.forEach(scope.steps, function(step, i) {
            if(step.ng_if === undefined || !Object.keys(step.ng_if).length || scope.showing_step_ifs[i])
              scope.style_spacer[i] = { width: calc_width + 'px' }
            else
              scope.style_spacer[i] = {}
          });
        }
        
        var showing_step = function() {
          scope.showing_step_ifs = FormService.showing_step_ifs;
          calcStepIndicators();
        }
        
        var step_change = function() {
          scope.current_step_index = FormService.current_step_index;
        }

        scope.goToStep = function(step_index, step_index_no_skip) {
          // console.log('Step Index: ' + step_index);
          // console.log('Current Step Index: ' + scope.current_step_index);
          if (NwuiSession.isAdvisorImpersonating()) {
            console.log(FormService.current_step);
            if (FormService.isKYC) {
              console.log(FormService.current_step);
              FormService.prepareAndSendKycInstance(FormService.current_step);
            } else if (!FormService.isKYC) {
              FormService.prepareAndSendFormInstance(FormService.current_step);
            }
          }
          if (clientUserData.use_rpq && step_index === 1 && scope.current_step_index === 2) {
            // this means this is going back from PI to account selection - this is for MD now only.
            let accountsList = '';
            if (FormAnswers.ownershipType === 'joint') {
              if (FormAnswers.Joint && FormAnswers.JointRESPFam) {
                accountsList = 'Joint,JointRESPFam';
              } else if (FormAnswers.AHJoint) {
                accountsList = 'Joint';
              } else if (FormAnswers.AHJointRESP) {
                accountsList = 'JointRESPFam';
              }
            } else {
              accountsList = FormAnswers.AHAccountTypeList;
            }
            const url = clientUserData.rpq_url + `?accounts=${accountsList}`;
            $window.location.href = url;
          } else {
            if (step_index < scope.current_step_index || NwuiSession.isAdvisorImpersonating()) {
              FormService.current_step = FormService.steps[step_index_no_skip];
              FormService.set_step_by_index(step_index);
              // $state.go($state.current.name, { step_index: current_step_index });
            }
          }
        }
        
        FormService.register_showing_step_observer_cb(showing_step);
        FormService.register_step_change_observer_cb(step_change);
        
        scope.indicator_step_number = {};
        scope.showing_step_ifs = {};
        scope.style_spacer = {};
        
        scope.hasIf = function(step_if) {

          return step_if !== undefined && Object.keys(step_if).length > 0;

        }

        scope.calcStepIndicators = calcStepIndicators;

        $timeout(function() {
          indicator_width = angular.element(element).find('.td').width();
          calcStepIndicators();
        }, 0);

        scope.$watch(function() {
          return angular.element($window)[0].innerWidth;
        }, function(new_val, old_val) {
          if(new_val === old_val) return;
          $timeout(function() {
            scope.calcStepIndicators();
          }, 50);
        });
        
      }
    }
  
  }
]);