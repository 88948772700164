appControllers.controller('ForgotPasswordController',["$scope","$rootScope","$http", function($scope, $rootScope, $http){

     $scope.credentials= {
         email:''
     }

     $scope.submit = function (){
         $http.post('forgot_password', $scope.credentials).then(function(res){
         }, function(error){
         })
     }




 }]);