angular.module('app.intro.controllers', [])

  .controller('IntroController', [
    '$scope',
    '$rootScope',
    '$location',
    '$state',
    '$window',
    '$timeout',
    '$cookies',
    'InputValidationService',
    'ErrorService',
    'MaskService',
    'NwuiSession',
    'NwuiButtonV3Factory',
    'NwuiAnalyticsService',
    function(
      $scope,
      $rootScope,
      $location,
      $state,
      $window,
      $timeout,
      $cookies,
      InputValidationService,
      ErrorService,
      MaskService,
      NwuiSession,
      NwuiButtonV3Factory,
      NwuiAnalyticsService
    ) {

      var tooltip_timeout = undefined;

      $scope.user.age = $location.search().age || null;
      $scope.user.principle = ($location.search().age && $location.search().principle)
        ? MaskService.unmask('currency', $location.search().principle)
        : null;
      $scope.user.reason = null;
      $scope.user.horizon = null;
      $scope.user.yearly_contribution = null;
      $scope.user.yearly_draw = null;
      $scope.user.user_saved = false;
      $scope.show_helper = false;

      $cookies.remove('user');

      $timeout(function() {
        $scope.introLoaded = true;
        $scope.steps[0].show();
      }, 500);

      $scope.load = function() {

        if($scope.user.age) {
          $scope.steps[0].input.validate($scope.user.age);
          $scope.steps[0].noTransition = true;
        }

        if($scope.user.principle) {
          $scope.steps[1].input.validate($scope.user.principle);
          $scope.steps[1].noTransition = true;
        }

        $timeout(function() {

          $scope.introLoaded = true;

          if($scope.user.age)
            return;

          tooltip_timeout = $timeout(function() {
            $scope.show_helper = true;
          }, 1500);

        },1000);

      }

      $scope.clearTooltip = function() {
        $timeout.cancel(tooltip_timeout);
        $scope.show_helper = false;
      }

      function Input(num, placeholder, maxlength, validator, analytics_tag) {

        var self = this;
        this.num = num;
        this.isValid = false;
        this.report = true;
        this.value = null;
        this.placeholder = placeholder;
        this.maxlength = maxlength;
        this.validator = validator;
        this.promise;
        this.error = null;
        this.analytics_tag = analytics_tag;

        this.validate = function(val, force) {

          self.value = val;
          var res = self.validator(self.value);

          if(res === true) {
            self.isValid = true;

            if(self.error) {
              ErrorService.remove(self.error.id);
              self.error = null;
            }

            //keypress delay on showing next step to more accurately determine input completion
            $timeout.cancel(self.promise);
            self.promise = $timeout(function() {
              $scope.steps[self.num].show();

              if(self.report) {
                if (window.Fides && window.Fides.consent.analytics === true) {
                  NwuiAnalyticsService.track(self.analytics_tag, { data: val });
                }
              }

              self.report = false;
            }, 500);
          }

          else if(self.isValid || val.length == self.maxlength || force) {
            self.isValid = false;
            self.error = res;
            ErrorService.remove(self.error.id);
            $scope.errors.push(res);
          }

        }
      }

      function Step(input_num, input_placeholder, input_maxlength, input_validator, error_message, analytics_tag) {

        this.isShown = null;
        this.noTransition = false;
        this.errorMessage = error_message;
        this.gtmEvent = analytics_tag;
        this.input = new Input(input_num, input_placeholder, input_maxlength, input_validator, analytics_tag);
        this.style = {
          width: '0px',
          height: '0px'
        };

        this.show = function() {
          this.isShown = true;
        }

      }

      $scope.errors = ErrorService.errors;
      $scope.steps = [
        new Step(1, '42', 2, InputValidationService.validateAge, 'Error message', 'Intro - Age Entered', 'age'),
        new Step(2, '150,000', 8, InputValidationService.validateAmount, 'Error message', 'Intro - Amount Saved Entered', 'dollar'),
        new Step(3, 'select an option', 100, InputValidationService.validateSelect, 'Error message', 'Intro - Goal Picked', 'goal')
      ]

      var setNextStep = function(input_num, input_placeholder, input_maxlength, input_validator, error_message, analytics_tag){
        $scope.steps.push(new Step(input_num, input_placeholder, input_maxlength, input_validator, error_message, analytics_tag))
      }

      $scope.last_step = {
        val:99
      };

      $scope.isShowingSelect = false;

      var goToLogin = function() {

        var token = $cookies.get('token')

        var expiry = new Date();
        expiry.setHours(expiry.getHours()+1);
        $cookies.putObject('user', $scope.user, {'expires': expiry});
        NwuiSession.create($scope.user);

        if(!token) {
          $rootScope.$emit('AuthModalUpdate', { open: true, locked: false, default_form: 2 });
        } else {
          $scope.introCompleted = true;
          $timeout(function() {
            $state.go('onboarding.question');
          }, 500);
        }

      }

      $scope.goToLogin = goToLogin;

      var goToCalc = function() {

        if($scope.errors.length != 0) return true;

        $scope.introCompleted = true;

        var expiry = new Date();
        expiry.setHours(expiry.getHours()+1);
        $cookies.putObject('user', $scope.user, {'expires': expiry});

        $timeout(function() {
          if(!$scope.user.horizon) $scope.user.horizon = 67 - $scope.user.age;

          $state.go('onboarding.calc');
        }, 500);

      };

      $scope.goToCalc = goToCalc;

      var showNextStep = function() {

        for(var i=0; i < $scope.isShowingStep.length; i++)
          if(!$scope.isShowingStep[i])
            break;

        $scope.isShowingStep[i] = true;

      }

      $scope.showNextStep = showNextStep;
      $scope.selectedChoiceIndex;

      var selectChoice = function(choice, choiceIndex, validate) {

        if(choice == $scope.user.reason) return;

        var delay = 0;

        if($scope.steps.length > 3) {
          $scope.steps.splice(3, $scope.steps.length-3);
          delay = 750;
        }

        $timeout(function() {

          $scope.user.horizon = '';
          $scope.user.yearly_draw = null;
          $scope.user.yearly_contribution = null;

          var temp = $scope.steps.length;

          switch(choice.id) {
            case 0:
              $scope.show_chart_draw = false;
              setNextStep(temp+1, '35', 2, InputValidationService.validateHorizon, "Error Message", 'Intro - Horizon Entered', 'years')
              setNextStep(temp+2, '10,000', 8, InputValidationService.validateAmount, "Error Message", 'Intro - Yearly Savings Entered')
              setNextStep(temp+3, null, null, null, null)
              $scope.last_step.val = temp + 3
              break;
            case 1:
              $scope.show_chart_draw = true;
              setNextStep(temp + 1, '75,000', 8, InputValidationService.validateAmount, "Error Message", 'Intro - Yearly Draw Entered', 'dollar')
              setNextStep(temp + 2, null, null, null, null)
              $scope.last_step.val = temp + 2
              break;
            case 2:
              $scope.show_chart_draw = false;
              setNextStep(temp +1, '10', 2, InputValidationService.validateHorizon, "Error Message", 'Intro - Horizon Entered', 'years')
              setNextStep(temp +2, '10,000', 8, InputValidationService.validateAmount, "Error Message", 'Intro - Yearly Savings Entered', 'dollar')
              setNextStep(temp +3, null, null, null, null)
              $scope.last_step.val = temp + 3
              break;
            case 3:
              $scope.show_chart_draw = false;
              setNextStep(temp + 1, '10', 2, InputValidationService.validateHorizon, "Error Message", 'Intro - Horizon Entered', 'years')
              setNextStep(temp + 2, '10,000', 8, InputValidationService.validateAmount, "Error Message", 'Intro - Yearly Savings Entered', 'dollar')
              setNextStep(temp + 3, null, null, null, null)
              $scope.last_step.val = temp + 3
              break;
          }

          $scope.user.reason = choice;
          $scope.selectedChoiceIndex = choiceIndex;
          $scope.drop_tab_index = 0;
          hideSelect();

          if(validate)
            validate('done');

        }, delay);

      }

      $scope.selectChoice = selectChoice;

      var showSelect = function($event) {
        $scope.isShowingSelect = true;
        $event.stopPropagation();
      }

      $scope.showSelect = showSelect;

      var hideSelect = function() {
        $scope.isShowingSelect = false;
      }

      $scope.hideSelect = hideSelect;

      $scope.buttons = {
        reason_1: new NwuiButtonV3Factory({
          id: 'join-intro-reason-1',
          text_i18n: 'intro.button.go_to_calculator',
          color: '',
          size: '',
          icon: {
            name: 'build'
          },
          onClick: function() {
            $scope.goToLogin();
          }
        }),
        reason_other: new NwuiButtonV3Factory({
          id: 'join-intro-reason-other',
          text_i18n: 'intro.button.direct_sign_up',
          color: '',
          size: '',
          icon: {
            name: 'help'
          },
          onClick: function() {
            $scope.goToCalc();
          }
        })
      }

      //SELECT EVENTS
      var select_focussed = false;
      $scope.drop_tab_index = 0;
      $scope.selectFocus = function(e) {
        select_focussed = true;
        $timeout(function() {
          $scope.isShowingSelect = true;
        }, 500);
      }

      $scope.selectBlur = function(e) {
        select_focussed = false;
      }

      $scope.selectKeyPress = function(event) {

        if(select_focussed) {

          //down arrow key
          if(event.keyCode == 40) {
            if($scope.isShowingSelect === false)
              $scope.isShowingSelect = true;
            else if($scope.drop_tab_index < $scope.choices.length-1)
              $scope.drop_tab_index++;

            event.stopPropagation();
            event.preventDefault();
          }

          //up arrow key
          else if(event.keyCode == 38) {
            if($scope.isShowingSelect === false)
              $scope.isShowingSelect = true;
            else if($scope.drop_tab_index > 0)
              $scope.drop_tab_index--;

            event.stopPropagation();
            event.preventDefault();
          }

          //enter key
          else if(event.which === 13) {
            if($scope.isShowingSelect === true)
              selectChoice($scope.choices[$scope.drop_tab_index],$scope.drop_tab_index+1, $scope.steps[2].input.validate);
          }

          //esc key
          else if(event.keyCode === 27) {
            $scope.isShowingSelect = false;
          }

          //tab key
          else if(event.keyCode === 9) {
            if($scope.isShowingSelect === true && $scope.drop_tab_index !== -1) {
              selectChoice($scope.choices[$scope.drop_tab_index],$scope.drop_tab_index+1, $scope.steps[2].input.validate);
            }
          }

        }

      }

    }
  ]);