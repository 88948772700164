'use strict';

appDirectives.directive('nwfield', [
  '$rootScope',
  '$sce',
  'FormService',
  'NwuiSession',
  function(
    $rootScope,
    $sce,
    FormService,
    NwuiSession
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        index: '<index',
        field: '<field',
        islast: '<islast',
        locale_prefix: '<localePrefix'
      },
      templateUrl: 'join_webserver/partials/common/form/field.html',
      link: function(scope, element, attrs) {
        var classes = 'col-' + scope.field.width;

        if (scope.field.width_m)
          classes += ' col-s-' + scope.field.width_m;

        if (scope.field.input.input_type === 'subheading')
          classes += ' subheading';

        if (scope.field.input.input_type === 'disclaimer')
          classes += ' disclaimer';

        scope.classes = classes;

        scope.field.label_i18n = undefined;
        scope.field.label_i18n_data = {};

        scope.openModal = function() {
          $rootScope.$emit('modalUpdate', { model: scope.field.input.model, open: true });
        };

        if ((scope.field.input.mask === 'date' || scope.field.input.mask === 'date_dashed')
			&& typeof scope.field.input.defaultYear !== 'undefined') {
          scope.field.input.default_year = Number(scope.field.input.defaultYear);
        }

        var unbind_label_i18n = scope.$watchGroup(['field.input.model', 'locale_prefix'], function(new_val, old_val) {
          var model = new_val[0];
          var locale_prefix = new_val[1];
          var label = 'label';

          if (model === undefined || locale_prefix === undefined) return;

          if (NwuiSession.user.org.rsp_contribution_match === 0
				&& scope.field.input.model === 'PayrollContributionType')
            label = 'label_other';

          if (scope.field.input.model === 'PayrollContributionType') {
            scope.field.label_i18n_data.percent = NwuiSession.user.org.rsp_contribution_percent;
            scope.field.label_i18n_data.match = NwuiSession.user.org.rsp_contribution_match;
          }

          scope.field.label_i18n = 'nwui.' + scope.locale_prefix + '.questions.' +
								scope.field.input.i18n + '.input.' + label;

          unbind_label_i18n();
        });
      }
    };
  }
]);
