/* eslint-disable */
angular.module('app.status.config', [
  'ui.router'
])
  .config([
    '$stateProvider',
    '$locationProvider',
    '$urlRouterProvider',

    'NWUI_AUTH_ROLES',
    function(
      $stateProvider,
      $locationProvider,
      $urlRouterProvider,
      NWUI_AUTH_ROLES
    ) {

      $stateProvider.state('onboarding.status', {
        url: '/status',
        views: {
          'status': {
            templateUrl: 'join_webserver/status/template.html',
            controller: 'StatusController'
          }
        },
        resolve: {
          OnboardingStatusData: [
            'NwuiAuthService',
            '$http',
            'NwuiSession',
            function(NwuiAuthService, $http, NwuiSession) {
              return NwuiAuthService.isTokenValid()
                .then(function() {
                  return $http.get('api/user/onboarding-status-detail')
                }, function(error) {
                  console.error(error);
                });
            }
          ],
          FormInstanceData: [
            'FormInstance',
            'NwuiAuthService',
            function(FormInstance, NwuiAuthService) {
              return NwuiAuthService.isTokenValid()
                .then(function(res) {
                  return FormInstance.create()
                }, function(error) {
                  console.error(error);
                })
            }
          ],
          KYCInstanceData: [
            'KYCInstance',
            'KYCService',
            'NwuiAuthService',
            function(KYCInstance, KYCService, NwuiAuthService) {
              return NwuiAuthService.isTokenValid()
                .then(function() {
                  return KYCInstance.create();
                }, function(error) {
                  console.error(error);
                })
            }
          ],
          SigningPowerData: [
            '$http',
            '$q',
            'NwuiSession',
            function($http, $q, NwuiSession) {
              var roleName = '';
              if (NwuiSession.user.Role) {
                roleName = NwuiSession.user.Role.name;
              } else if (NwuiSession.user.role) {
                roleName = NwuiSession.user.role.name;
              }
              if (
                !organization_data.join_data.signing ||
                !organization_data.join_data.signing.check_signing_power ||
                roleName !== 'HR Rep'
              ) {
                var deferred = $q.defer();
                deferred.resolve({ HasSigningPower: true });
                return deferred.promise;
              }

              return $http.post('/api/form_instance')
                .then(function(res) {
                  var form_answers = res.data.form_answers;
                  var hasSigningPower = form_answers.HasSigningPower === 'Yes';
                  var signingPowerData = {
                    HasSigningPower: hasSigningPower,
                    SignerEmail: form_answers.SignerEmail,
                    SignerFirstName: form_answers.SignerFirstName,
                    SignerLastName: form_answers.SignerLastName,
                    SignerJobTitle: form_answers.SignerJobTitle
                  };

                  if (hasSigningPower) {
                    return signingPowerData;
                  }

                  return $http.post('/api/form_instance/submit', {})
                    .then(function() {
                      return signingPowerData;
                    });
                });
            }
          ]
        },
        data: {
          auth_roles: [NWUI_AUTH_ROLES.user]
        }
      })
    }
  ]);
