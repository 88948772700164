appDirectives.directive('nwtooltip', [
	'$timeout', 
	'$sce',
	function(
		$timeout, 
		$sce
	) {
	
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			scope: {
				tooltip: '<tooltip',
				locale_prefix: '<localePrefix',
				model: '<'
			},
			templateUrl: 'join_webserver/partials/common/form/tooltip.html',
			link: function(scope, element, attrs) {


				var tooltip_timeout;
				var event_fired;
				
				scope.opts = {
					show: false,
					flip: false,
					invert: false,
					shrink: false,
					transclude: true,
					xwide: true
				}

				scope.data = {
					is_left: false,
					active: false
				}

				scope.style = {}

				scope.tooltipEventOver = function(e) {
						
					// var offset = element.offset().left;
					// var offset_parent = $(element).closest('.size').offset().left;

					if($(window).width() < 500) {
						scope.data.is_left = true;
						scope.opts.flip = true;
					} else {
						scope.opts.flip = false;
					}

					// scope.data.active = true;
					if(scope.tooltip.focus !== true)
						scope.opts.show = true;
					
				}
				
				scope.tooltipEventOut = function(e) {
					// scope.data.active = false;
					if(scope.tooltip.focus !== true)
						scope.opts.show = false;
				}
				
				scope.$watch('tooltip.focus', function(new_val) {
					
					if(new_val === undefined) return;
				// 	scope.tooltip.content_safe = $sce.trustAsHtml(new_val);

					scope.opts.show = new_val;
					
				});
				
			}
		}
		
	}
]);