'use strict';

angular.module('app.signature.controllers', [])

  .controller('SignatureController', [
    "$scope",
    "$interval",
    "$timeout",
    "$http",
    "$sce",
    "$cookies",
    "$state",
    "$stateParams",
    "FormService",
    "User",
    "Session",
    "NwuiSession",
    "SigningStatus",
    "FormInstanceData",
    "KYCInstanceData",
    "MaskService",
    "ValidationService",
    'NwuiToastManagerService',
    'NwuiToastFactory',
    'FormAnswers',
    'SigningPowerData',
    'NwuiAnalyticsService',
    function(
      $scope,
      $interval,
      $timeout,
      $http,
      $sce,
      $cookies,
      $state,
      $stateParams,
      FormService,
      User,
      Session,
      NwuiSession,
      SigningStatus,
      FormInstanceData,
      KYCInstanceData,
      MaskService,
      ValidationService,
      NwuiToastManagerService,
      NwuiToastFactory,
      FormAnswers,
      SigningPowerData,
      NwuiAnalyticsService
    ) {
      $scope.isAdvisorImpersonating = false;
      $scope.advisorErrors = {};
      var advisorKycErrors = false;

      $scope.setOnboardingStep(3);

      $scope.loaded = false

      $scope.data = {
        org: organization_data,
        is_admin: (NwuiSession.user.Role.name_slugged !== 'client'),
        showErrorsList : lodashGet(organization_data,'join_data.showErrorsListafterSubmitWhileImpersonating', false)
      }

      $scope.modules = {
        loading: {
          color: '',
          active: true,
          determinate: false
        }
      }

      $scope.buttons = {
        revalidate: {
          id: 'signature-revalidate-button',
          text_i18n: 'signature.validation.step_1.button',
          processing: false,
          complete: false,
          onClick: function() {
            $scope.buttons.revalidate.processing = true;

            var credentials = {
              email: NwuiSession.user.email
            };

            User.revalidate(credentials).then(
              function(user) {
                $scope.buttons.revalidate.processing = false;
                $scope.buttons.revalidate.complete = true;
              },
              function() { }
            );

          }
        },
        signing_cancel: {
          text_i18n: 'nwui.common.modal.close',
          id: 'signing-cancel',
          size: 'short',
          sharp_corners: true,
          icon: {
            name: "close"
          },
          onClick: function () {
            $scope.buttons.signing_cancel.processing = true;
            setUserUrl('/forms').then(function(res) {
              destroy();
              $state.go('onboarding.form');
            });
          }
        }
      }

      $scope.user = {
        id: Session.id,
        first_name:Session.first_name,
        last_name:Session.last_name,
        email:Session.email,
        envelope_id: undefined
      }

      $scope.signature = {
        valid: false,
        url:''
      }

      var setUserUrl = function(redirectUrl) {
        return $http.put('/api/users', {redirect_url: redirectUrl} )
      }

      var destroy = function() {
        $scope.user.envelope_id = undefined;
        $scope.signature.url = undefined;
        $scope.signature.valid = undefined;
      }

      $scope.goToErrorStep = function() {
        window.sessionStorage.setItem("showErrorWhileImpersonating", true);
        var typeError = Object.keys($scope.advisorErrors)[0];
        var stepError = Object.keys($scope.advisorErrors[typeError])[0];
        var firstError = $scope.advisorErrors[typeError][stepError][0];
        $state.go('onboarding.'+firstError.type + '.step', {step_index: firstError.step + 1});
      }

      function parseConditions(ng_if, showConsole) {
        if(!ng_if) {
          return true;
        }
        if(showConsole) console.log('parse block', ng_if);

        if (ng_if.or) {
          return ng_if.or.some(function (item) {
            return parseIndividualCondition(item, showConsole);
          });
        } else if (ng_if.and) {
          return ng_if.and.every(function (item) {
            return parseIndividualCondition(item, showConsole);
          });
        } else {
          return parseIndividualCondition(ng_if, showConsole);
        }
      }

      function isValidationError(input, validator) {
        var validationResult = ValidationService[validator](input)
        return typeof validationResult === 'boolean' ?
                      !validationResult :
                      validationResult.constructor && validationResult.constructor.name === 'Error'
      }

      function parseIndividualCondition(item, showConsole) {
        if(showConsole) console.log('parsing ind condition', item);

        if(item.ng_if_model) {

          var value = item.ng_if_value_model === undefined
            ? item.ng_if_value
            : FormInstanceData.form_answers[item.ng_if_value_model];

          if (showConsole) {
            console.log('parseIndividualCondition', FormInstanceData.form_answers[item.ng_if_model]);
          }

          switch(item.ng_if_operator) {
            case 'gt':
              return FormInstanceData.form_answers[item.ng_if_model] > value;
              break;
            case 'gte':
              return FormInstanceData.form_answers[item.ng_if_model] >= value;
              break;
            case 'lt':
              return FormInstanceData.form_answers[item.ng_if_model] < value;
              break;
            case 'lte':
              return FormInstanceData.form_answers[item.ng_if_model] <= value;
              break;
            case 'ne':
              return FormInstanceData.form_answers[item.ng_if_model] != value;
              break;
            case 'regex':
              var regex = new RegExp(value, 'gi');
              return regex.test(FormInstanceData.form_answers[item.ng_if_model]);
              break;
            default:
              return FormInstanceData.form_answers[item.ng_if_model] == value;
          }

        } else if (item.or) {
          return parseConditions(item, showConsole);
        } else if (item.and) {
          return parseConditions(item, showConsole);
        } else {
          if(showConsole) console.log('returning result', FormInstanceData.form_answers[item.ng_if_model], item.ng_if_value);
          return FormInstanceData.form_answers[item.ng_if_model] == item.ng_if_value;
        }
      }

      var checkForErrors = function() {
        var tenant = $scope.data.org.slug;

        var kycErrors = loopThroughFields('question', KYCInstanceData.kyc_instance.steps, KYCInstanceData.kyc_answers);
        const clientUserData = NwuiSession.userData.user;
        if (clientUserData.use_rpq) {
          kycErrors = [];
        }
        var formErrors = loopThroughFields('form', FormInstanceData.form_instance.steps, FormInstanceData.form_answers);
        if(kycErrors.length > 0) {
          advisorKycErrors = true;
        }
        if(
          tenant === 'vpic' ||
          tenant === 'ipc_mfda'
        ){
          return formErrors.concat(kycErrors);
        }
        return kycErrors.concat(formErrors);
      }

      $scope.advisorGoBackButton = {
        text_i18n: 'signature.advisor.errors.buttons.goBack',
        onClick: function(){
          $scope.goToErrorStep();
        },
        id: 'advisor-go-back-button'
      };

      var loopThroughFields = function(type, steps, answerData) {
        var errors = [];
        var errorMsg;
        var error;
        var isVisible;
        var isStepVisible;
        var stepIndex = 0;
        steps.forEach(function(step){
          if(!parseConditions(step.ng_if)) {
            return false;
          }
          if(step.title=='use me to find me') {
            return false;
          }
          isStepVisible = false;
          step.rows.forEach(function (row){
            if(parseConditions(row.ng_if)) {
              row.fields.forEach(function (field) {
                error = false;
                errorMsg='';
                isVisible = true;
                if (field.ng_if) {
                  isVisible = parseConditions(field.ng_if);
                }

                if (isVisible && field.input.input_type == 'check' && field.input.optional !== true) {
                  error = !field.input.options.some(function(answer, i){
                    return answerData[answer.model] === true ||
                      (
                        answerData[field.input.model]
                        && answerData[field.input.model][i]
                        && answerData[field.input.model][i].value === true
                      )
                  });

                  errors.push({
                    type: type,
                    step: stepIndex,
                    model: field.input.model,
                    question : field.label,
                    fromCheck: true,
                    error: error
                  });
                }
                else if(isVisible) {
                  if(field.input.validate && field.input.optional !== true) {
                    var validator = field.input.validate;
                    if(ValidationService[validator]) {
                      var validationInput = field.input.mask ? MaskService.unmask(field.input.mask, answerData[field.input.model]) : answerData[field.input.model]
                      error = isValidationError(validationInput, validator)
                    }
                  }
                  if(field.input.optional !== true) {
                    if (answerData[field.input.model] === 0) {
                      error = false;
                      return;
                    }
                    if(!answerData[field.input.model]) {
                      error = true;
                    }
                  }
                  if(error && type === 'form') {
                    answerData[field.input.model] = null
                    FormService.updateAnswers(answerData)
                  }
                  errors.push({
                    type: type,
                    step: stepIndex,
                    model: field.input.model,
                    question : field.label,
                    error: error
                  });
                }

                if(isVisible) {
                  isStepVisible = true;
                }
              });
            }
          });
          //increment step index only if an input in that step is actually visible
          if(isStepVisible) {
            stepIndex++;
          }

        });
        return errors
          .filter(function(errorObj){
            return errorObj.model !=='forceFalse'
          })
          .filter(function(errorObj) {
            return errorObj.error === true;
          });
      }

      var init = function() {
        if(NwuiSession.isAdvisorImpersonating()) {
          $scope.isAdvisorImpersonating = true;

          var errorsList = checkForErrors();
          errorsList.forEach(function(error){
            if(!$scope.advisorErrors.hasOwnProperty(error.type)) {
              $scope.advisorErrors[error.type] = {};
            }
            var stepIndex = parseInt(error.step)+1;
            if(!$scope.advisorErrors[error.type].hasOwnProperty(stepIndex)) {
              $scope.advisorErrors[error.type][stepIndex] = [];
            }
            $scope.advisorErrors[error.type][stepIndex].push(error)
          })

          if(Object.keys($scope.advisorErrors).length > 0 ) {
            $scope.loaded = true;
          }
          if(Object.keys($scope.advisorErrors).length === 0 && window.sessionStorage.getItem("showErrorWhileImpersonating")){
            window.sessionStorage.setItem("showErrorWhileImpersonating", false);
          }
        }
        if(Object.keys($scope.advisorErrors).length === 0) {
          $cookies.put('nw-sig-start', 1);

          var requestObject = { accessCodeId: FormService.access_code };
          if ($stateParams.client_present)
            requestObject.client_present = $stateParams.client_present;
          $http.post('/api/form_instance/submit', requestObject).then(function(res) {

            if(res.data.advisor_led_onboarding || res.data.skipSigning) {
              $state.go('onboarding.status');
              return;
            }

            $scope.loaded = true;

            if(res.data.valid) {
              $scope.valid = true;
              $scope.user.envelope_id = res.data.envelope_id;
              $scope.signature.url = $sce.trustAsResourceUrl(res.data.url);
              $scope.signature.valid = true;

              poll();

            } else {
              $scope.valid = false;
            }
          }, function() {
            NwuiToastManagerService.register(
              new NwuiToastFactory({
                text_i18n: 'nwui.common.error.unknown',
                type: 'error',
                icon: {
                  name: 'close'
                }
              }));
          });
        }
      };

      init()

      window.sigLoaded = function() {
        $scope.sigIframeLoaded = true;

        //poll
        var interval = $interval(function() {

          if(document.activeElement == document.getElementsByTagName("iframe")[0]) {
            $scope.sigStarted = true;
            $interval.cancel(interval);
          }

        }, 500);
      }

      // polling for signing status
      var poll = function() {
        var resendPoll = function() {
          $timeout(function() {
            poll();
          }, 5000);
        };
        var advanceUser = function() {
          console.log('signing complete');
          if (window.analytics) {
            window.analytics.track('Documents Signed');
          }
          $state.go('onboarding.status');
        };
        var DOCUSIGN_ERROR_CODE = 4311;
        SigningStatus.poll($scope.user.envelope_id).then(
          function(response) {
            if (response.is_complete === true) {
              advanceUser();
            } else {
              resendPoll();
            }
          }, function(error) {
            if (error.data && error.data.code === DOCUSIGN_ERROR_CODE) {
              advanceUser();
            }
          }
        );
      };

      var signingStartedTriggered = false;

      $scope.onIframeLoaded = function(event) {

        var url = false;

        if (!signingStartedTriggered) {
          signingStartedTriggered = true;
        }

        //try because cross domain
        try {
          url = document.getElementById("signature-container").contentWindow.location.href;
        } catch(e) {
          url = false;
        }

        if(url !== false) {

          if(
            url.indexOf('event=cancel') !== -1 ||
            url.indexOf('event=session_timeout') !== -1 ||
            url.indexOf('event=ttl_expired') !== -1 ||
            url.indexOf('event=decline') !== -1
          ) {
            window.location.href = '/status';
          }

        }

      }

    }

  ]);