appServices.service('AuthModalLaunchService', [function() {
	this.show = false
	this.return_state = null
	var observer_callback = []
	this.register = function(cb){
		observer_callback.push(cb)
	}

	this.setShow = function(bool, state){
		this.show = bool
		this.return_state = state
		notifyObservers()
	}
	var self = this
	var notifyObservers = function() {
		angular.forEach(observer_callback, function(cb) {
			cb();
		});
	}

}])
