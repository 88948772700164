appControllers.controller('SetPasswordController',["$scope","$rootScope","$http","$cookies","$location","$q","$timeout","$sce", function($scope, $rootScope, $http, $cookies, $location, $q, $timeout, $sce) {
	
	$scope.loaded = true
	$scope.error = {
		message: '',
		inputs: []
	}
	
	$scope.credentials = {
		password_1:'',
		password_2:''
	}
	
	var validate = function(form, type) {
		
		var deferred = $q.defer();
		
		$scope.valid = true;
		
		$timeout(function() {
			
			$scope.error.inputs = [];
			
			if((type == 'submit') || form.password_1.$touched) {
				if(!$scope.credentials.password_1) {
					$scope.error.message = $sce.trustAsHtml("Please set your password.");
					$scope.error.inputs.push('password_1');
					$scope.valid = false;
				}
			}
			
			if((type == 'submit') || form.password_2.$touched) {
				if(!$scope.credentials.password_2) {
					$scope.error.message = $sce.trustAsHtml("Please set your password.");
					$scope.error.inputs.push('password_2');
					$scope.valid = false;
				}
			}
			
			if(type == 'submit') {
				if($scope.credentials.password_1 != $scope.credentials.password_2) {
					$scope.error.message = $sce.trustAsHtml("Your passwords must match.");
					$scope.error.inputs.push('password_1');
					$scope.error.inputs.push('password_2');
					$scope.valid = false;
				} else if($scope.credentials.password_1.length < 6) {
					$scope.error.message = $sce.trustAsHtml("Your password must be at least six characters.");
					$scope.error.inputs.push('password_1');
					$scope.error.inputs.push('password_2');
					$scope.valid = false;
				}
			}
			
			
			return deferred.resolve();
		
		}, 400);
		
		return deferred.promise;
		
	}
	
	$scope.blur = function(form) {
		
		validate(form, 'blur')
			.then(function(res) {}, function(res) {});
			
	}
	
	$scope.submit = function(form) {
		
		if($scope.processing) return true;
		
		$scope.processing = true;
		
		validate(form, 'submit')
			.then(function(res) {
				
				if($scope.valid) {
					
					//var token = $cookies.get('token');
			
					//if(typeof token != 'undefined') {
					$http.post('set_password', { password: $scope.credentials.password_1 }).then(function(res){
						$scope.processing = false;
						$location.path(res.data.redirect_url)
					}, function(error){
					});
					//}
				
				} else {
					$scope.processing = false;
				}
				
			});
		
		/*
		
		var token = $cookies.get('token');
		
		if(typeof token != 'undefined') {
			$http.post('set_password?token=' + token, $scope.credentials).then(function(res){
				$location.path(res.data.redirect_url)
			}, function(error){
			});
		}
		
		*/
	}
         
}]);