appServices.service('ErrorService', [function() {
	
	function Error(id, message) {
		this.id = id;
		this.message = message;
	}
	
	var self = this;
	
	this.errors = [];
	
	this.create = function(id, message) {
		return new Error(id, message);
	}
	
	this.add = function(error) {
		this.remove(error.id);
		this.errors.push(error);
	}
	
	this.remove = function(id) {
		this.errors.forEach(function(obj, i) {
			if(obj.id == id) {
				self.errors.splice(i, 1);
				//return true;
			}
		});
	}
    
}]);