appServices.service('FixedsliderService', [function(){
    
    var observers = [];
    
    var notify_observers = function(i) {
		angular.forEach(observers, function(cb) {
			cb(i);
		});
	};
	
	this.register_observer_cb = function(cb) {
		observers.push(cb);
	};
	
	this.notify = function(step_num) {
		notify_observers(step_num);
	}
    
}]);