appDirectives.directive('nwbankverifications', [
  '$q',
  '$timeout',
  'FormService',
  'MaskService',
  'ValidationService',
  'NwuiInputService',
  'BankVerificationService',
  "locale",
	'$cookies', 
  '$state',
  '$stateParams',
  function(
    $q,
    $timeout,
    FormService,
    MaskService,
    ValidationService,
    NwuiInputService,
    BankVerificationService,
    locale,
    $cookies, 
    $state,
    $stateParams
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        field: '<field',
        step_index: '<stepIndex',
        current_step_index: '<currentStepIndex',
        locale_prefix: '<localePrefix'
      },
      templateUrl: 'join_webserver/partials/common/form/bankverifications.html',
      link: function(scope, element, attrs) {

        scope.entry_model = scope.field.model;

        scope.value = FormService.answers[scope.entry_model];

        var updateModel = function(value) {
          FormService.answers[scope.entry_model] = value;
          scope.value = value;
          var input = NwuiInputService.inputs['join-forms-' + scope.entry_model];
          if (input) {
            input.model = value;
            input.onChange(value);
            input.valid = true;
          }
        };

        function setInputsDisabled(disabled) {
          scope.field.bank_verification.mapping.forEach(function(m) {
            var input = NwuiInputService.inputs['join-forms-' + m.model];
            if (input) {
              input.disabled = disabled;
            }
          });
        }

        if (FormService.answers[scope.entry_model] === 'auto_verified') {
          setInputsDisabled(true);
        } else {
          setInputsDisabled(false);
        }

        if (!FormService.answers[scope.entry_model]) {
          updateModel('auto');
        }

        scope.data = {
          add_source_button: {
            icon: {
              name: 'create'
            },
            size: 'sm',
            onClick: function() {
              scope.compounds.bank_verifications.open = true;
            },
            text_i18n: 'forms.bank_verification.button.add_source'
          },
          go_back_button: {
            size: 'sm',
            onClick: function() {
              updateModel('auto');
              scope.field.bank_verification.mapping.forEach(function(m) {
                FormService.answers[m.model] = '';
                var input = NwuiInputService.inputs['join-forms-' + m.model];
                if (input) {
                  input.model = '';
                }
              });
              setInputsDisabled(false);
              scope.compounds.bank_verifications.open = true;
            },
            text_i18n: 'forms.bank_verification.button.go_back'
          },
          skip_button: {
            size: 'sm',
            onClick: function() {
              $state.go('onboarding.form.step', { step_index: +$stateParams.step_index + 1 });
            },
            color: 'alternate',
            text_i18n: 'forms.bank_verification.button.skip'
          }
        };

        scope.compounds = {
          bank_verifications: {
            provider: window.organization_data.bankVerification && window.organization_data.bankVerification.provider,
            open: false,
            singleAccountSelector: true,
            needsSigning: false,
            getAccountsCb: function(data) {
              var accessToken = $cookies.get('openid_access_token');
              var login_id = data.data.loginId;

              return BankVerificationService.getBankDetails(data.provider, accessToken, login_id)
            },
            getWidgetCb: function(provider) {
              var language = locale.getLocale().toLowerCase();
              var accessToken = $cookies.get('openid_access_token');

              return BankVerificationService.getWidgetDetails(provider, accessToken, language)
            },
            manualClickedCb: function() {
              scope.compounds.bank_verifications.open = false;
              updateModel('manual');
            },
            submitCb: function(data) {
              var deferred = $q.defer();

              if(data.length > 0) {
                var answers = {};
                answers[scope.entry_model] = 'auto_verified';
                scope.field.bank_verification.mapping.forEach(function(m) {
                  answers[m.model] = data[0][m.field];
                });
                FormService.updateAnswers(answers)
                  .then(function(answers) {
                    updateModel('auto_verified');
                    scope.field.bank_verification.mapping.forEach(function(m) {
                      FormService.answers[m.model] = answers[m.model];
                      var input = NwuiInputService.inputs['join-forms-' + m.model];
                      if (input) {
                        input.model = answers[m.model];
                      }
                    });
                    setInputsDisabled(true);
                    deferred.resolve(true);
                  })
  
              } else {
                deferred.resolve(true);
              }
              return deferred.promise;
            }
          }
        };

        scope.field.input.valid = true;

        scope.field.input.answer = FormService.answers[scope.field.input.model];
      }
    }
  }
]);