appServices.service('ScoreData', [function(){
    this.data;

    this.create = function(obj){
        this.data = obj
       
    }
    this.destroy = function(){
        angular.extend(this, null)
    }
}]);