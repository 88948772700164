appControllers.controller('SetPasswordController',[
  "$scope",
  "$rootScope",
  "$timeout",
  "$state",
  "$cookies",
  '$location',
  "DomainParserService",
  "locale",
  "NWUI_AUTH_EVENTS",
  "AuthService",
  'SetPasswordService',
  'NwuiSession',
  'GeneralService',
  'NwuiAnalyticsService',
  function(
    $scope,
    $rootScope,
    $timeout,
    $state,
    $cookies,
    $location,
    DomainParserService,
    locale,
    NWUI_AUTH_EVENTS,
    AuthService,
    SetPasswordService,
    NwuiSession,
    GeneralService,
    NwuiAnalyticsService
  ) {

    $scope.linkExpired = !!$location.search().link_expired;

    $scope.data = {
      org_slug: window.org_slug
    }

    $scope.altLogoOpts = {};

    $scope.changeLocale = function(new_lang) {

      var domain = DomainParserService.parse(window.location.href);

      locale.setLocaleCookie(new_lang, domain);

      GeneralService.setLanguage(new_lang).then(function(res) {
        window.location.href = res.data[new_lang] + window.location.pathname + '?lang='+new_lang;
      }).catch(function() {
        console.error("There was an error switching the language");
      })

    }

    $scope.has_sub_logo = (
      organization_data &&
      organization_data.join_data &&
      organization_data.join_data.header &&
      organization_data.join_data.header.sub_logo
    )

    if($scope.has_sub_logo) {
      $scope.altLogoOpts = {
        imgName : 'alt-header-logo.png',
        imgPath: 'img/brand/'+window.org_slug,
        'class': 'alt-logo'
      }
    }

    $scope.compounds = {
      set_password: {
        open: true,
        user: undefined,
        formResource: SetPasswordService.setPassword,
        submitCb: function(res) {
          window.location.href = res.user.redirect_domain + res.user.redirect_url;
        },
        linkExpired: $scope.linkExpired
      }
    };

    $rootScope.$on(NWUI_AUTH_EVENTS.authDone, function() {

      $scope.compounds.set_password.user = NwuiSession.user;

    });

    $timeout(function() {
      $scope.loaded = true;
    }, 500);

  }
]);