appDirectives.directive('numbersOnly', ['$filter', 'InputValidationService', function($filter, InputValidationService) {
    return {
        restrict: "A",
        scope: {
	    	model: '=ngModel'    
        },
		link: function(scope, element, attrs, ngModelController) {
			
			scope.$watch('model', function(newVal) {
				
				scope.model = (!newVal) ? '' : InputValidationService.prepInteger(newVal);
				
			});
			
	    }
    }
}]);