appFactories.factory('FormFactory', ["FormResource", function(FormResource){
    function Form(form_instance_id){
        var form = this
        form.questions = []
        form.getQuestions(form, form_instance_id)
    }
    Form.prototype = {
        getQuestions: function(form, form_instance_id){
            FormResource.query({params: {form_instance_id: form_instance_id}}).$promise.then(function(data){
                angular.extend(form.questions, data.map(function(datum){ return datum }))
            })
        }
    }
    return Form
}])