appFilters.filter('noWidow', ['$sce', function($sce) {
	return function(input) {
		
		if(!input) return '';
		
		var arr = String(input).split(" ");
		var phrase = "";
		angular.forEach(arr, function(word, i) {
			var spacer = "";
			
			if(phrase) {
				spacer = " ";
				if(i == arr.length-1)
					spacer = "&nbsp;";
			}
			
			phrase += spacer + word;
		});
		
		return $sce.trustAsHtml(phrase);
	};
	
}]);