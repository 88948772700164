appServices.service('Session', ['$window', function($window) {
    this.create = function(user){
	    
        if(!'userRole' in user){
            user.userRole = 'user'
        }
        angular.extend(this, user)
    }
    this.destroy = function(){
        angular.extend(this, null)
    }
}]);