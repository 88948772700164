appServices.service('InputValidationService', ['ErrorService', function(ErrorService) {
	
	var self = this;
	
    this.parameters = {
	    age: {
		    min: 18,
		    max: 85
	    },
	    amount: {
		    min: 100,
		    max: 9999999
	    },
	    contribution: {
		    min: 0,
		    max: 9999999
	    },
	    horizon: {
		    min: 5
	    }
    }
    
    //clean expected integer before check
    this.prepInteger = function(val) {
	    
	    return val+''.replace(/[^0-9]/g, '') | '';
	    
    }
    
    this.validateAge = function(val) {
	    

	    val = self.prepInteger(val);
	    
	    if(val >= self.parameters.age.min && val <= self.parameters.age.max)
		    return true;
		    
	    else if(val < self.parameters.age.min)
		    return ErrorService.create('age', 'intro.error.age_low');
	    	
	    else if(val > self.parameters.age.max)
	    	return ErrorService.create('age', 'intro.error.age_high');
	    
    	return ErrorService.create(['gen', 'age'], 'intro.error.unknown');
    	
    }
    
    this.validateAmount = function(val) {


	    val = typeof val != 'undefined'? parseInt(val.replace(/[, \u00a0]+/g, '').trim(), 10) : 0;
	    
	    if(val >= self.parameters.amount.min && val <= self.parameters.amount.max)
	    	return true
	    	
	    else if(val < self.parameters.amount.min)
		    return ErrorService.create('amt', 'intro.error.amount_low');
	    	
	    else if(val > self.parameters.amount.max)
	    	return ErrorService.create('amt', 'intro.error.amount_high');
	    
	    return ErrorService.create(['gen', 'amt'], 'intro.error.unknown');
	    
    }
    
    this.validateHorizon = function(val) {

		val = self.prepInteger(val);
	    
	    if(val >= self.parameters.horizon.min)
	    	return true
	    	
	    else if(val < self.parameters.horizon.min)
	    	return ErrorService.create(['hor'], 'intro.error.duration_low');
	    
	    return ErrorService.create(['gen', 'hor'], 'intro.error.unknown');
	    
    }
    
    this.validateSelect = function(val) {
	    
	    if(val.length > 0)
	    	return true;
	    	
	    return ErrorService.create(['gen', 'sel'], 'intro.error.unknown');
	    
    }
    
}]);