'use strict';
angular.module('app.sso-implicit-callback.controllers', [])
  .controller('SsoImplicitCallbackController', [
    '$scope',
    '$window',
    'RemoteLoggingService',
    function(
      $scope,
      $window,
      RemoteLoggingService
    ) {
      var callbackUrl = $window.location.origin + '/sso/callback';

      function handleRequestError(error, msg) {
        RemoteLoggingService.log('SsoImplicitCallbackController: Error ' + (msg || '') + JSON.stringify(error));
        $scope.errored = true;
      }

      function handleRequests(data) {
        if (data.email && data.keys) {
          data.language = getDomainLanguage($window.location.origin);
          var callbackPostRequest = new XMLHttpRequest();
          callbackPostRequest.open('POST', callbackUrl, true);
          callbackPostRequest.setRequestHeader('Content-Type', 'application/json');
          callbackPostRequest.setRequestHeader('Accept', 'application/json');
          callbackPostRequest.onload = function() {
            if (callbackPostRequest.status !== 200) {
              if (callbackPostRequest.response &&
                callbackPostRequest.response.errorCode === 'E0000007') {
                RemoteLoggingService.log('SsoImplicitCallbackController: Resource not found: me (Session). Bypassing..');
                throw new Error(callbackPostRequest.response);
              } else {
                return handleRequestError(callbackPostRequest.response, callbackUrl);
              }
            }
            var res = JSON.parse(callbackPostRequest.response);
            RemoteLoggingService.log('SsoImplicitCallbackController - processed callback - redirecting to: ' + res.redirect_domain + res.redirect_url);
            if (res.redirect_domain && res.redirect_url) {
              $window.location.href = res.redirect_domain + res.redirect_url;
            }
          };
          callbackPostRequest.onerror = function() {
            handleRequestError('Error in handleRequests', callbackUrl);
          };
          callbackPostRequest.send(JSON.stringify(data));
        }
      }

      function getDomainLanguage(domain) {
        var result = 'en-CA';
        Object.keys(organization_data.url_bases).forEach(function(baseKey) {
          var base = organization_data.url_bases[baseKey];
          if (!Array.isArray(base) && typeof base === 'object') {
            Object.keys(base).forEach(function(lang) {
              var innerDomain;
              base[lang].forEach(function(d) {
                if (d === domain) {
                  innerDomain = d;
                }
              });
              if (innerDomain) {
                result = lang;
              }
            });
          }
        });
        return result;
      };

      function getKeysRequest(sso_data, data) {
        var getKeysRequest = new XMLHttpRequest();
        getKeysRequest.open('GET', sso_data.jwk_url, true);
        getKeysRequest.setRequestHeader('Content-Type', 'text/plain;');
        getKeysRequest.setRequestHeader('Accept', 'application/json');
        getKeysRequest.setRequestHeader('Cache-Control', 'no-cache');
        getKeysRequest.setRequestHeader('Pragma', 'no-cache');
        getKeysRequest.onload = function() {
          if (getKeysRequest.status !== 200) {
            return handleRequestError(getKeysRequest.response, sso_data.jwk_url);
          }
          data.keys = JSON.parse(getKeysRequest.response).keys;
          handleRequests(data);
        };
        getKeysRequest.onerror = function() {
          handleRequestError('Error in getKeys', sso_data.jwk_url);
        };
        getKeysRequest.send();
      };

      function getSessionRequest(sso_data, data) {
        var getSessionRequest = new XMLHttpRequest();
        getSessionRequest.open('GET', sso_data.get_session_url, true);
        getSessionRequest.setRequestHeader('Content-Type', 'text/plain;');
        getSessionRequest.setRequestHeader('Accept', 'application/json');
        getSessionRequest.setRequestHeader('Cache-Control', 'no-cache');
        getSessionRequest.setRequestHeader('Pragma', 'no-cache');
        getSessionRequest.withCredentials = true;
        getSessionRequest.onload = function() {
          if (getSessionRequest.status !== 200) {
            return handleRequestError(getSessionRequest.response, sso_data.get_session_url);
          }
          data.email = JSON.parse(getSessionRequest.response).login;
          handleRequests(data);
        };
        getSessionRequest.onerror = function() {
          handleRequestError('Error in getSessionUrl', sso_data.get_session_url);
        };
        getSessionRequest.send();
      };

      function queryStringToObject(str) {
        var obj = {};
        var pairs = str.split('&');
        pairs.forEach(function(pair) {
          pair = pair.split('=');
          var key = pair[0];
          var value = pair[1] || '';
          if (key !== '') {
            obj[key] = decodeURIComponent(value);
          }
        });
        return obj;
      };

      function extend(obj, src) {
        Object.keys(src).forEach(function(key) {
          obj[key] = src[key];
        });
        return obj;
      };

      function merge(obj1, obj2) {
        var obj = {};
        obj = extend(obj, obj1);
        obj = extend(obj, obj2);
        return obj;
      };

      RemoteLoggingService.log('SsoImplicitCallbackController: bootstrap successful');
      $scope.errored = false;
      $scope.modules = {
        loading: {
          color: '',
          active: true,
          determinate: false
        }
      };

      $scope.return_link = {
        url: organization_data.sso_data.return_url,
        text: organization_data.sso_data.return_text
      };

      var sso_data = organization_data.sso_data;
      var queryString = $window.location.search.substring(1);
      var hash = $window.location.hash.substring(1);

      var queryStringObject = queryStringToObject(queryString);
      var hashObject = queryStringToObject(hash);

      var data = merge(queryStringObject, hashObject);

      getSessionRequest(sso_data, data);

      getKeysRequest(sso_data, data);

      handleRequests(data);
    }
  ]);

