angular.module('app.questionnaire.step.controllers', [])

.controller('QuestionStepController', [
	'$scope',
	'$http',
	'$timeout',
	'$stateParams',
	'$window',
	'FormService',
	function(
		$scope,
		$http,
		$timeout,
		$stateParams,
		$window,
		FormService
	) {

		// $scope.data = {
		// 	step_index: parseInt($stateParams.step_index, 10) || 10
		// }

		// $scope.form_steps = FormService.steps;

		// $scope.$on('form-steps-loaded', function() {
		// 	$timeout(function() {
				
		// 		var index = 0;

		// 		for(var i = 0; i < $scope.form_steps.length; i++) {
		// 			if($scope.form_steps[i].showing) {
		// 				index = i;
		// 				break;
		// 			}
		// 		}

		// 		var next_step = Math.min(index, $scope.data.step_index-1);
		// 		FormService.set_step_by_index(next_step);
				
		// 	}, 0)
		// });

		// try {
		// 	FormService.set_step_by_index($scope.data.step_index-1);
		// } catch(e) {

		// }

		var loadStep = function() {

			if(angular.equals(FormService.showing_step_ifs, {})) return;

			var zero_step_index = $stateParams.step_index-1;

			var index = 0;
			
			for(var i = 0; i < $scope.form_steps.length; i++) {
				if($scope.form_steps[i].showing) {
					index = i;
					break;
				}
			}

			var count = 0;
			while(FormService.showing_step_ifs[zero_step_index+FormService.getSkippedStepsCount(zero_step_index)+count] === false) {
				count++;	
			}

			var next_step = Math.min(index, zero_step_index+FormService.getSkippedStepsCount($stateParams.step_index)+count);
			FormService.set_step_by_index(next_step);

		}

		var setUserUrl = function(redirectUrl) {
			return $http.put('/api/users', {redirect_url: redirectUrl} )
		}

		$scope.data = {
			step_index: $stateParams.step_index
		}

		$scope.form_steps = FormService.steps;

		$scope.$on('form-steps-loaded', function() {
			$timeout(loadStep, 0)
		});

		if(FormService.current_step) {

			if(FormService.current_step_index !== undefined) {
				FormService.set_step_by_index($stateParams.step_index-1+FormService.getSkippedStepsCount(FormService.current_step_index));
			}
			
		}

		if(FormService.blockKYCCondition()) {
			setUserUrl('/questionnaire');
		}
	}
]);