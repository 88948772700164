appServices.service('KYCService', [function() {
	
	var self = this;
	var kyc = undefined;

	this.save = function(obj) {
		kyc = obj;
	}

	this.get = function() {
		return kyc;
	}
        
}]);