angular.module('app.run', [])

.run([
	"$rootScope", 
	"$location", 
	"$timeout", 
	"$window",
	"$http",
	"User",
	'NWUI_AUTH_EVENTS', 
	'NwuiAuthService', 
	"PageTitleService", 
	"$state", 
	"$stateParams",
	"AuthModalLaunchService", 
	"intercomUser", 
	'NwuiSession', 
	"$cookies", 
	'tmhDynamicLocale',
	'locale',
	'localeConf',
	'NwuiLocalizeService',
	'DomainParserService',
	'NwuiAnalyticsService',
	function(
		$rootScope,
		$location, 
		$timeout,
		$window,
		$http,
		User,
		NWUI_AUTH_EVENTS, 
		NwuiAuthService, 
		PageTitleService, 
		$state, 
		$stateParams,
		AuthModalLaunchService, 
		intercomUser, 
		NwuiSession, 
		$cookies,
		tmhDynamicLocale,
		locale,
		localeConf,
		NwuiLocalizeService,
		DomainParserService,
		NwuiAnalyticsService
	) {
		var langParam;
		var domainParam = DomainParserService.parse(window.location.href);

		//priorize url param over current cookie
		if($location.$$search && $location.$$search.lang) {
			langParam = $location.$$search.lang;
		} else if(
			window.MDFM &&
			window.MDFM.SharedMenu &&
			window.MDFM.SharedMenu.currentLanguage
		) {
			switch(MDFM.SharedMenu.currentLanguage) {
				case 'fr':
					langParam = 'fr-CA';
					break;
				default:
					langParam = 'en-CA';
			}
		} else {
			langParam = $cookies.get(localeConf.cookieName);
		}

		if(!langParam) {

			if(
				organization_data &&
				organization_data.url_bases
			) {

				//iterate over envs
				for(var key in organization_data.url_bases) {

					var environment = organization_data.url_bases[key];

					//check for object
					if(environment !== null && typeof environment === 'object') {

						//iterate over env languages
						for(var prop in environment) {

							//check for lang/url match
							if(window.location.href.indexOf(environment[prop]) !== -1) {

								//override default lang
								langParam = prop;
								break;
							}

						}

					}
					
				}

			}

		}

		locale.setLocale(langParam, domainParam);
		moment.locale(locale.getLocale().toLowerCase());
  	tmhDynamicLocale.set(locale.getLocale());
 		NwuiLocalizeService.setLang(locale.getLocale());

		var onLangToggle = function(overrideLang) {
			var domain = DomainParserService.parse(window.location.href);
			var lang;

			if(overrideLang) {
				lang = overrideLang;
			}
			else if(NwuiLocalizeService.getLang() == "en-CA"){
				lang = 'fr-CA';
			}
			else{
				lang= 'en-CA';
			}
			locale.setLocaleCookie(lang, domain);
			if(NwuiSession.user) {
				User.updateUserLang(lang).then(function(){
					window.location.reload();
				});
			}else {
				window.location.reload();
			}
		}

		if (window.MDFM) {
			var intervalCounter = 0;
			var interval = setInterval(function() {
				if (intervalCounter > 50) {
					clearInterval(interval);
					return;
				}
				intervalCounter++;
				if (
					window.MDFM &&
					window.MDFM.SharedMenu &&
					window.MDFM.SharedMenu.currentLanguage
				) {
					var mdLang;
					switch(MDFM.SharedMenu.currentLanguage) {
						case 'fr':
							mdLang = 'fr-CA';
							break;
						default:
							mdLang = 'en-CA';
					}
					if (mdLang !== langParam) {
						onLangToggle(mdLang)
					}
					clearInterval(interval);
				}
			}, 100);
		}

		window.globalRootScope = $rootScope;
	
		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {

			//console.log('$stateChangeStart toState', toState.name, fromState.name)
			 
			//var token = $cookies.get('token');
			////console.log('token', token, toState);
			
			if(toState.name !== 'login') {
				$rootScope.returnToState = toState;
				$rootScope.returnToParams = toParams;
			}
			 
			//check if auth required
			if(toState.data.auth_roles.length > 0) {
				
				//check auth
				NwuiAuthService.isAuthenticated().then(function(res) {

					//check role access
					if(!NwuiAuthService.isAuthorized(toState.data.auth_roles)) {
						//console.log('NWUI_AUTH_EVENTS.notAuthorized FIRE')
						$rootScope.$broadcast(NWUI_AUTH_EVENTS.notAuthorized);
					} else {
						//console.log('NWUI_AUTH_EVENTS.authDone FIRE')
							$rootScope.$broadcast(NWUI_AUTH_EVENTS.authDone);
					}
					 
				}, function() {
					
					NwuiSession.destroy();
					
					//$cookies.remove('token');
						//$cookies.remove('token', { domain: '.nestwealth.com' });
						
					$rootScope.$broadcast(NWUI_AUTH_EVENTS.notAuthenticated);
					
				});
				
			} else {
				
				NwuiSession.destroy();
				
				//$cookies.remove('token');
					//$cookies.remove('token', { domain: '.nestwealth.com' });
					
				$rootScope.$broadcast(NWUI_AUTH_EVENTS.authDone);

			}
			
		});
		
		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			//console.log('$stateChangeSuccess', $location.url(), $state.current.name);

			if(fromState.name === 'onboarding.form' && toState.name === 'onboarding.question.step') {
				//console.log('$stateChangeSuccess', 'go to onboarding.question.step');
				$state.go('onboarding.question.step')
			}

			else if(toState.name === 'onboarding.form' && fromState.name === 'onboarding.form.step') {
				//console.log('$stateChangeSuccess', 'go to onboarding.question');
				$state.go('onboarding.question')
			}

			var title;
			
			switch($location.url()) {
				case '/':
					title = 'Introduction';
					break;
				case '/login':
					title = 'Login';
					break;
				case '/signup':
					title = 'Sign Up';
					break;
				case '/calculator':
					title = 'Calculator';
					break;
				case '/questionnaire':
					title = 'Questionnaire';
					break;
				case '/forms':
					title = 'Forms';
					break;
				case '/status':
					title = 'Status';
					break;
				case '/signature':
					title = 'Signature';
					break;
				default:
					break;
			}

			if(title === undefined) {

				switch($state.current.name) {
					case 'onboarding.question':
						title = 'Questionnaire';
						// $state.go('onboarding.question.step', { step_index: 1 });
						break;
					case 'onboarding.question.step':
						title = 'Questionnaire - Step ' + $stateParams.step_index;
						break;
					case 'onboarding.form':
						title = 'Forms';
						// $state.go('onboarding.form.step', { step_index: 1 });
						break;
					case 'onboarding.form.step':
						title = 'Forms - Step ' + $stateParams.step_index;
						break;
					default:
						break;
				}

			}
			
			PageTitleService.setPageTitle(title);
			
			if (window.Fides && window.Fides.consent.analytics === true) {
				NwuiAnalyticsService.trackPage($location.url());
			}
			
		});

		var protocol = window.location.protocol;
		var host = window.location.host;
		var redirectUrl = protocol + '//' + host;

		$rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, function() {
			if (organization_data.sso_enabled) {
				window.location.href = redirectUrl;
			} else {
				$state.go('login');
			}
		});
		
		$rootScope.$on(NWUI_AUTH_EVENTS.notAuthenticated, function() {
			if (organization_data.sso_enabled) {
				window.location.href = redirectUrl;
			} else {
				$state.go('login');
			}
		});
		
		$rootScope.$on(NWUI_AUTH_EVENTS.notAuthorized, function() {
			if (organization_data.sso_enabled) {
				window.location.href = redirectUrl;
			} else {
				$state.go('login');
			}
		});

		setInterval(function() {
		  if (NwuiSession.user.uuid) {
		    $http.get('/check-session').then(function(res) {

        }, function(err) {
					if (err.data.message === 'redirect required' && err.data.redirect) {
						window.location.href = err.data.redirect;
					}
        });
      }
    }, 30 * 1000);
	}
]);