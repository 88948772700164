appFactories.factory('UserService',["$http","Session",
    function($http, Session) {
        var userService = {}
        userService.update = function ( new_user ) {
            return $http
                .post ( '/api/user/' + Session.userId +'/update?TOKEN='+Session.token, new_user )
                .then ( function ( res ) {
                //TODO: destroy and create session
                return res.data.user
            })
        }

        return userService;
    }
]);