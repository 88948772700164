angular.module('app.questionnaire.results.directives', [])

.directive('joinQuestionnaireResults', [
	'$timeout', 
	'PieData',
	function(
		$timeout,
		PieData
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'join_webserver/questionnaire/results/template.html',
			scope: false,
			link: function(scope, element, attrs) {
	
				var locks = {
					selected_dimension: false
				}

				if (organization_data.join_data.questionnaire.pie.allocation_only) scope.pie.data = [scope.pie.data[0]];

				scope.data = {
					year: moment().format('YYYY'),
					visualization: ((((organization_data || {}).join_data || {}).questionnaire || {}).visualization || 'pie')
				}

				scope.pie_view = {
					active_pie_slice: undefined,
					hovered_pie_slice: undefined,
					hover_invert: organization_data.join_data.questionnaire.pie.hover_invert
				}

				scope.changeActiveSlice = function(num) {
					scope.pie_data.forEach(function(o,i) {
						if(num == o.name)
							scope.pie_view.active_pie_slice = o;
					});
				}

				scope.changeSelectedDimension = function(num) {

					if(num < 0) num = 0;
					if(num > scope.pie.data.length-1) num = scope.pie.data.length-1;

					scope.selected_dimension_index = num;
					scope.selected_dimension = scope.pie.data[scope.selected_dimension_index];

				}

				scope.selected_dimension_index = 0;
				scope.selected_dimension = undefined;
				
				scope.$watch('pie.data', function(new_val, old_val) {
					var temp;
					temp = scope.pie.data[scope.selected_dimension_index].dimensions;
					temp.reverse();

					scope.selected_dimension = scope.pie.data[scope.selected_dimension_index];
					
				});

				scope.$watch('selected_dimension', function(new_val) {

					var dimensions = new_val.dimensions;

					if(new_val === undefined || locks.selected_dimension) return;

					locks.selected_dimension = true;

					dimensions = dimensions.map(function(d,i) {
						return {
							name: i,
							percent: d.y,
							type: d.type,
							key: d.key,
							i18n_key: d.i18n_key,
							description: d.description
						}
					});
					var temp_trimmed = [];
					dimensions.forEach(function(o,i) {
						if(o.percent > 0)
							temp_trimmed.push(o);
					});
					scope.pie_data = temp_trimmed;
					scope.pie_view.active_pie_slice = scope.pie_data[0];

					$timeout(function() {
						locks.selected_dimension = false;
					}, 150)

				})

			}
		}
	}
])