appServices.service('ChartService', [function() {
	
	if(typeof organization_data.join_data.calculator.constants === "undefined") {
		console.warn("Warning: calculator constants are not defined in tenant config.")
		return false;
	}
	var chart_constants = organization_data.join_data.calculator.constants;
	
    var annuityPayout = function(p,r,y) {
		return futureValue(p,r,y-1)/geomSeries(1+r,0,y-1);
	}
	
	var futureValue = function(p,r,y) {
		return p*Math.pow(1+r,y);
	}
	
	var geomSeries = function(z,m,n) {
		var amt;
		if (z == 1.0) amt = n + 1;
		else amt = (Math.pow(z,n + 1) - 1)/(z - 1);
		if (m >= 1) amt -= geomSeries(z,0,m-1);
		return amt;
	}
	
	var calcDraw = function(t,p,r) {
		var y = t;
		var w = annuityPayout(p,r,y);
		w *= (1 + r);
		return w;
	}
    
    var nestFeeCalc = function(p) {
	    
	    var flat = (p <= 75000)
	    	? chart_constants.flat_dollar_fee.small
	    	: (p <= 150000)
	    		? chart_constants.flat_dollar_fee.medium
	    		: chart_constants.flat_dollar_fee.large;
	       
	    return p * chart_constants.fund_expenses + (flat*12) + chart_constants.trading_costs
	    
    }
    
    var competitorFeeCalc = function(p) {
	    
	    return p * (chart_constants.advisory_fee + chart_constants.fund_expenses);
	    
    }
    
    var formatSeries = function(raw_series) {
	    
	    var series = [];
        
        angular.forEach(raw_series, function(obj) {
            series.push(Math.round(obj.total));
        });
        
        return series;
	    
    }
    
    var getMax = function(series) {
	    
	    var max = 0;
	    
	    angular.forEach(series, function(obj) {
            max = (obj > max) ? obj : max;
        });
	    
	    return max;
	    
    }
    
    var getMin = function(series) {
	    
	    var min = series[0];
	    
	    angular.forEach(series, function(obj) {
            min = (obj < min) ? obj : min;
        });
	    
	    return min;
	    
    }
    
    var drawCalc = function(p, c, t, d, feeCalc, series) {
	    
	    for(var i = 1; i < t +1; i++){

            var portfolio = ((1 + chart_constants.rate.draw) * (p)) + c
            var fees = feeCalc(portfolio);
            p = portfolio - fees - d
			
			/*if(p < 0) {
				p = 0;
			}*/
			
            series.push(
                {
                    year: i,
                    portfolio: portfolio,
                    fees: fees,
                    total: p
                }
            )
        }
        
        return formatSeries(series);
	    
    }
    
    var growthCalc = function(p, c, t, feeCalc, series) {
	    
	    for(var i = 0; i < t ; i++){
	        var portfolio = ((1 + chart_constants.rate.growth) * p) + c
	        var fees = feeCalc(portfolio);
	        p = portfolio - fees
	        series.push(
	            {
	                year: i,
	                portfolio: portfolio,
	                fees: fees,
	                total: p
	            }
	        )
		}
	    
	    return formatSeries(series);
	    
    }
    
    var buildSeries = function(p,c,a,t,d,feeCalc) {
	    
	    //growth series
	    var growth = growthCalc(p, c, t, feeCalc, [{ year: 0, portfolio: p, fees: 0, total: p }]);
	    
	    //get start value for draw target calc
	    var p_draw_target = growth[growth.length-1];
	    var t_draw_target = (chart_constants.target_age - a - t);
	    var d_draw_target = calcDraw(t_draw_target,p_draw_target,chart_constants.rate.draw);
	    
	    //draw series
	    var draw = 
	    	drawCalc(
	    		p, 
	    		0, 
	    		(chart_constants.target_age - a), 
	    		d, 
	    		feeCalc, 
	    		[{ year: 0, portfolio: p, fees: 0, total: p }]
	    	);
	    
	    //draw series with target year of 90 and draw unknown
	    var drawTarget = 
	    	drawCalc(
	    		p_draw_target, 
	    		0, 
	    		t_draw_target, 
	    		d_draw_target, 
	    		function() { return 0 }, //TODO: add fees to draw down target calc
	    		[{ year: 0, portfolio: p_draw_target, fees: 0, total: p_draw_target }]
	    	)
	    
	    return {
		    growth: {
			    data: growth,
			    max: getMax(growth),
			    min: getMin(growth),
			    draw: 0
			},
		    draw: {
			    data: draw,
			    max: getMax(draw),
			    min: getMin(draw),
			    draw: 0
			},
		    drawTarget: {
			    data: drawTarget,
			    max: getMax(drawTarget),
			    min: getMin(drawTarget),
			    draw: d_draw_target
			}
	    }
	    
    }

    this.compCalc = function(p,c,a,t,d) {
	    return buildSeries(p,c,a,t,d,competitorFeeCalc);
	}

	this.nestCalc = function(p,c,a,t,d) {
		return buildSeries(p,c,a,t,d,nestFeeCalc);
	}
	
}]);