angular.module('app.login.config', [
	'ui.router'
])

.config([
	"$stateProvider", 
	'NWUI_AUTH_ROLES', 
	"$locationProvider", 
	"$urlRouterProvider", 
	function(
		$stateProvider, 
		NWUI_AUTH_ROLES, 
		$locationProvider, 
		$urlRouterProvider
	) {

		$stateProvider.state('login', {
			url:'/login',
			templateUrl: 'join_webserver/login/template.html',
			controller: "LoginController",
			data: {
				auth_roles: []
			}
		});

	}
]);