/* eslint-disable */

angular.module('app.status.controllers', [])

  .controller('StatusController', [
    '$scope',
    '$state',
    '$cookies',
    '$q',
    '$http',
    '$location',
    '$window',
    'locale',
    'OnboardingStatusData',
    'FormService',
    'FormInstanceData',
    'FormAnswers',
    'FormSteps',
    'FormInstanceID',
    'Session',
    'User',
    'NwuiSession',
    'NwuiToastManagerService',
    'KYCInstanceData',
    'NwuiFormV3Factory',
    'NwuiToastFactory',
    'SigningPowerData',
    'NwuiAnalyticsService',
    function (
      $scope,
      $state,
      $cookies,
      $q,
      $http,
      $location,
      $window,
      locale,
      OnboardingStatusData,
      FormService,
      FormInstanceData,
      FormAnswers,
      FormSteps,
      FormInstanceID,
      Session,
      User,
      NwuiSession,
      NwuiToastManagerService,
      KYCInstanceData,
      NwuiFormV3Factory,
      NwuiToastFactory,
      SigningPowerData,
      NwuiAnalyticsService
    ) {
      var url = window.location.href;

      var redirect_signed = !(
        url.indexOf('event=cancel') !== -1 ||
        url.indexOf('event=session_timeout') !== -1 ||
        url.indexOf('event=ttl_expired') !== -1 ||
        url.indexOf('event=decline') !== -1
      )

      var domains = {
        port_web: port_web,
        admin_web: admin_web,
        nww_web: nww_web
      }

      $scope.inPerson = {};
      $scope.inPerson.name = 'inPersonOption';
      $scope.inPerson.enabled = NwuiSession.isAdvisorImpersonating()
        && NwuiSession.user
        && NwuiSession.user.in_person_enabled
        && !is_docusign_callback;
      $scope.inPerson.model = 'no';

      if ($scope.setOnboardingStep) {
        $scope.setOnboardingStep(3);
      }
      $scope.processing = false;
      $scope.complete = true;

      var client = NwuiSession.user.Role.name === 'Client';
      var jump_to_port = false;
      var jump_to_admin = false;
      var isClientSelfBuilt = KYCInstanceData.kyc_answers.built_by === '1';
      //OnboardingStatusData.jump_port === true means advisor built;

      if (
        client &&
        (
          OnboardingStatusData.data.jump_port === true ||
          (
            OnboardingStatusData.data.jump_port !== false &&
            organization_data &&
            organization_data.join_data &&
            organization_data.join_data.status &&
            organization_data.join_data.status.jump &&
            organization_data.join_data.status.jump.port === true
          )
        )
      ) {
        jump_to_port = true
      } else if (
        NwuiSession.user.Role.name !== 'Client' &&
        organization_data &&
        organization_data.join_data &&
        organization_data.join_data.status &&
        organization_data.join_data.status.jump &&
        organization_data.join_data.status.jump.admin === true
      ) {
        jump_to_admin = true;
      }

      $scope.organization_data = organization_data;
      $scope.data = {
        user: NwuiSession.user,
        year: moment().format('YYYY')
      }

      // pull all the booleans that mark stage completion from the onboarding status detail
      var user_status = {
        validated_email: OnboardingStatusData.data.email.is_validated,
        kyc_complete: OnboardingStatusData.data.risk_profile.is_complete,
        forms_complete: OnboardingStatusData.data.form.is_complete,
        signing_incomplete: OnboardingStatusData.data.sorting.signatures <= 0,
        pending_custodian: OnboardingStatusData.data.custodian.is_approved,
        integration_errors: OnboardingStatusData.data.integrationError
          && OnboardingStatusData.data.integrationError.error_thrown,
        is_funded: OnboardingStatusData.data.funding.numerator > 0
      }
      $scope.inPerson.enabled = $scope.inPerson.enabled && user_status.signing_incomplete;
      $scope.title;
      if (jump_to_port || !user_status.signing_incomplete) {
        $scope.title = 'status.lead.signing.complete.title';
      } else {
        $scope.title = 'status.lead.signing.pending.title';
      }

      /**
       * Returns `true` if organization_data.join_data.invite_after_impersonation.after_form_questions
       * or organization_data.join_data.invite_after_impersonation.after_KYC are true
       */
      var isInviteAfterImpersonation = function () {
        return lodashGet(organization_data, 'join_data.invite_after_impersonation.after_form_questions', false)
          || lodashGet(organization_data, 'join_data.invite_after_impersonation.after_KYC', false)
      };

      function getSignatures() {

        var deferred = $q.defer();
        var signers = [];

        /*
        Step "port_jump" is where the status will be given if the client has signed. This was hardcoded as true, 
        because Client is the first who signs in multiple cases. For IPC this is not the case Advisor signs first 
        and Client signs second. When adding a function to check if Client has signed it will not work, because 
        the list of steps only loads the changes on initial load. The check for clients signature has to come from 
        docusign, which is not going to work if client has signed and we wait for docusign to give us the status.
        */
        function clientSigned(userEmail) {
          var client = OnboardingStatusData.data.signatures.find(recipients => recipients.email === userEmail);
          return (client && client.is_signed && !client.is_declined);
        }

        // a flag from config_api to ignore the client signed method, because of the reason mentioned above.
        var ignoreClientSignedStatus = lodashGet(organization_data, 'join_data.status.ignoreClientSignedStatus', false);

        // dont want to wait if jumping to port
        if (jump_to_port || jump_to_admin) {
          locale.ready('status.step.port_jump')
            .then(function () {
              signers.push({
                title: 'status.step.port_jump.signers.title',
                data: {
                  name: NwuiSession.user.first_name + ' ' + NwuiSession.user.last_name,
                  email: NwuiSession.user.email
                },
                status: (ignoreClientSignedStatus) ? clientSigned(NwuiSession.user.email) : true
              });

              deferred.resolve(signers);

            });
        } else {
          locale.ready('status.step.signers')
            .then(function () {
              OnboardingStatusData.data.signatures.forEach(function (signer, i) {

                signers.push({
                  title: 'status.step.signers.title',
                  data: {
                    name: signer.name,
                    email: signer.email
                  },
                  status: signer.is_signed
                });

              });

              deferred.resolve(signers);

            });
        }

        return deferred.promise;
      }

      var stepOptions = {
        $scope,
        $state,
        user_status,
        isInviteAfterImpersonation,
        NwuiSession,
        FormInstanceData,
        User,
        redirect_signed,
        isClientSelfBuilt,
        domains,
        jump_to_port,
        SigningPowerData,
        jump_to_admin,
        getSignatures,
        client
      }

      getSteps(stepOptions)

      $scope.modules = {
        upload_form: new NwuiFormV3Factory({
          name: 'document-upload-form',
          submit_button: false,
          onSubmit: function (post_data) { },
          onComplete: function (obj) { },
          steps: [{
            columns: [{
              directive: 'input',
              width: 100,
              data: {
                directive: 'upload',
                data: {
                  id: 'document-upload-form-input-upload-file',
                  label: '',
                  model: '',
                  url: '/api/user/user/' + NwuiSession.user.id + '/document/-1',
                  placeholder_i18n: 'status.sidebar.uploads.input_placeholder',
                  required: false,
                  onSuccess: function () {

                    NwuiToastManagerService.register(
                      new NwuiToastFactory({
                        text_i18n: 'status.sidebar.uploads.toast_success',
                        icon: {
                          name: 'check'
                        }
                      }));

                  }
                }
              }
            }]
          }]
        })
      }

      $scope.onChange = function (value) {
        if ($scope.inPerson.enabled) {
          $scope.inPerson.model = value;
        }
        getSteps(stepOptions)
      };

      //legacy ga event
      if (user_status.signing_incomplete) {

        if ($cookies.get('nw-sig-start') && org_slug === 'nestwealth') {
          $cookies.remove('nw-sig-start');
        }
      }

    }
  ]);
