angular.module('app.questionnaire.step.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		$stateProvider.state('onboarding.question.step', {
			url: '/step/:step_index',
			views: {
				"step": {
					templateUrl: 'join_webserver/questionnaire/step/template.html',
					controller: 'QuestionStepController'
				}
			},
			params: {
				step_index: null
			},
			resolve: {},
			data: {
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		})

	}
]);