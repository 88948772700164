'use strict';

appDirectives.directive('nwform', [
  '$timeout',
  '$state',
  '$rootScope',
  'FormService',
  'NwuiSession',
  'locale',
  function(
    $timeout,
    $state,
    $rootScope,
    FormService,
    NwuiSession,
    locale
  ) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        locale_prefix: '<localePrefix',
        steps: '<steps',
      },
      templateUrl: 'join_webserver/partials/common/form/form.html',
      link: function(scope, element, attrs) {
        var timeouts = {
          height: undefined,
          height_inner: undefined
        };
        var bindModel = function(step_index) {
          return function(value) {
            var flag = FormService.checkShow(scope.steps[step_index].ng_if);
            if (!flag) {
              FormService.remove_showing_step_if(step_index);
            } else {
              FormService.add_showing_step_if(step_index);
            }
            scope.show_if = flag;
          };
        };

        var bindIfs = function(ng_if, step_index) {
          // is and
          if (ng_if.and) {
            bindIfs(ng_if.and, step_index);
          }
          // is or
          else if (ng_if.or) {
            bindIfs(ng_if.or, step_index);
          }
          // is model
          else if(ng_if.ng_if_model) {

            if(ng_if.ng_if_value_model)
              FormService.register_step_observer_cb(bindModel(step_index), ng_if.ng_if_value_model);
            
            FormService.register_step_observer_cb(bindModel(step_index), ng_if.ng_if_model);

            var flag = FormService.checkShow(scope.steps[step_index].ng_if);
            
            if(!flag) {
              FormService.remove_showing_step_if(step_index);
            } else {
              FormService.add_showing_step_if(step_index);
            }
                
            scope.show_if = flag;

          }

          else if(ng_if && ng_if.length > 0) {
            angular.forEach(ng_if, function(obj, i) {
              bindIfs(obj, step_index);
            });
          }

          else {
            FormService.add_showing_step_if(step_index);
          }

        }

        var iterateSteps = function(steps) {

          steps.forEach(function(step, i) {
            if (step.ng_if)
              bindIfs(step.ng_if, i);
            else
              FormService.add_showing_step_if(i);
          });

        }

        var step_change = function() {
          checkForDisabledInputs(FormService.current_step);
          scope.current_step = FormService.current_step;
          scope.current_step_index = FormService.current_step_index;
          $state.go($state.current.name, { step_index: FormService.current_step_index + 1 - FormService.getSkippedStepsCount(FormService.current_step_index) });
        };
        
        var error_change = function() {
          if (!scope.current_step) return;

          $timeout.cancel(timeouts.height);
          $timeout.cancel(timeouts.height_inner);
          timeouts.height = $timeout(function() {
            scope.style.height = scope.current_step.element.height() + 'px';

            timeouts.height_inner = $timeout(function() {
              delete scope.style.height;
            }, 1000);
          }, 0);
        };

        scope.current_step;
        scope.style = {};
        scope.style_inner = {};

        FormService.register_step_change_observer_cb(step_change);
        FormService.register_error_observer_cb(error_change);

        scope.$watch('current_step', function(newVal, oldVal) {
          if (!newVal || !newVal.element) return;

          $timeout.cancel(timeouts.height);
          $timeout.cancel(timeouts.height_inner);
          $timeout(function() {
            scope.style.height = newVal.element.height() + 'px';

            $timeout(function() {
              delete scope.style.height;
            }, 1000);
            checkForDisabledInputs(FormService.current_step);
          }, 0);
        });

        var unbind_steps = scope.$watch('steps', function(new_val, old_val) {
          if (new_val === undefined || new_val.length === 0) return;

          iterateSteps(new_val);

          $timeout(function() {
            $rootScope.$broadcast('form-steps-loaded');
          });
          
          unbind_steps();
        });

        function checkForDisabledInputs(step) {
          step.rows.forEach(function(row) {
            row.fields.forEach(function(field) {
              //checkboxes need to be handled completely differently, look in check.js
              if (field.input.readonly && field.input.input_type !== 'check') {
                field.input.original_directive = field.input.original_directive || field.input.input_type;
                field.input.input_type = 'input';
                field.input.directive = 'text';
                field.input.validate = undefined;
              }
            });
          });
        }
      }
    };
  }
]);