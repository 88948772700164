appFactories.factory('AuthInterceptor', ["$rootScope","$q","AUTH_EVENTS","Session",
    function($rootScope, $q, AUTH_EVENTS, Session) {
        return {
            responseError: function ( response ) {
                $rootScope.$broadcast ( {
                    401: AUTH_EVENTS.notAuthenticated,
                    403: AUTH_EVENTS.notAuthorized,
                    419: AUTH_EVENTS.sessionTimeout,
                    440: AUTH_EVENTS.sessionTimeout,
                    499: AUTH_EVENTS.sessionTimeout
                }[response.status], response );
                
                return $q.reject ( response );
            },
            request: function(request){

                if(!!Session.token){
                    if(request.url.indexOf('api/') != -1){
                        request.params = {TOKEN: Session.token}
                    }
                }
                return $q.when(request)
            }
        };
    }
]);