'use strict';
var appDirectives;
appDirectives.directive('nwinput', [
  '$timeout',
  'FormService',
  'MaskService',
  'ValidationService',
  'NwuiInputService',
  'locale',
  function(
    $timeout,
    FormService,
    MaskService,
    ValidationService,
    NwuiInputService,
    locale
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        field: '<field',
        step_index: '<stepIndex',
        current_step_index: '<currentStepIndex',
        locale_prefix: '<localePrefix'
      },
      templateUrl: 'join_webserver/partials/common/form/input.html',
      link: function(scope, element, attrs) {
        var timeout = {
          validate: undefined
        };

        var has_blurred = false;

        var mask_map = {
          age: 'age',
          date: 'date_dashed',
          postal: 'postal',
          '3_digit': 'bank_institution',
          numeric: 'numeric',
          transit: 'transit',
          currency: 'currency'
        };
        if (scope.field.input.mask === 'transit')
          scope.field.input.validate = 'transit';
        if (scope.field.input.mask === '3_digit')
          scope.field.input.validate = 'institution';

        var model;

        if (scope.field.input.readonly) {
          if (scope.field.input.original_directive === 'radio'
						|| scope.field.input.original_directive === 'select'
          ) {
            model = locale.getString('nwui.' + scope.locale_prefix + '.questions.' + scope.field.input.i18n +
			'.options.' + (FormService.answers[scope.field.input.model] || scope.field.input.default_value));
          } else {
            model = FormService.answers[scope.field.input.model];
          }
        } else {
          model = FormService.answers[scope.field.input.model];
        }
        var validate = function(type) {
          if (!type) type = 'change';
          return FormService.validate(type, scope.field, [scope.field.input.validate]);
        };
        FormService.register_input_validate_observer_cb(validate, scope.field.model);
        scope.data = {
          input: {
            directive: 'text',
            data: {
              label: locale.getString(
                'nwui.' + scope.locale_prefix + '.questions.' + scope.field.input.i18n + '.input.label'
              ),
              model: model || '',
              id: 'join-forms-' + scope.field.input.model,
              stretch: true,
              required: true,
              sr_only_label: true,
              disabled: scope.field.input.readonly,
              maxlength: scope.field.input.maxlength,
              default_value: scope.field.input.default_value,
              default_year: scope.field.input.default_year,
              size: '',
              disable_browser_autocomplete: true,
              // validators: validate_map[scope.field.input.validate],
              mask: mask_map[scope.field.input.mask] || mask_map[scope.field.input.validate] || scope.field.input.mask,
              // placeholder: scope.field.input.placeholder,
              placeholder_i18n: 'nwui.' + scope.locale_prefix + '.questions.'
								+ scope.field.input.i18n + '.input.placeholder',
              onBlur: function(model) {
                validate('blur');
                has_blurred = true;
              },
              onFocus: function(model) {
                // if(scope.field.tooltip)
                // 	scope.field.tooltip.focus = true;
              },
              onChange: function(model) {
                FormService.answers[scope.field.input.model] = scope.field.input.answer = model;
                if (has_blurred === true)
                  validate();
                select(model);
              }
            }
          }
        };

        var select = function(choice) {
          scope.row_observer_cbs = FormService.public_observer_cbs.row_ifs[scope.field.input.model] || [];
          scope.step_observer_cbs = FormService.public_observer_cbs.step_ifs[scope.field.input.model] || [];
          angular.forEach(scope.row_observer_cbs, function(cb) {
            cb(choice);
          });

          angular.forEach(scope.step_observer_cbs, function(cb) {
            cb(choice);
          });
        };
        // var step_change = function(is_showing) {
        // 	step_showing = is_showing;
        // }

        var clear = function() {
          $timeout.cancel(timeout.validate);
          NwuiInputService.inputs['join-forms-' + scope.field.input.model].model = '';
          FormService.answers[scope.field.input.model] = '';
        };

        var error_change = function() {
          // scope.field.input.answer, scope.field.input.model, FormService.errors[scope.field.input.model]);

          if (NwuiInputService.inputs['join-forms-' + scope.field.input.model])
            NwuiInputService.inputs['join-forms-' + scope.field.input.model].error =
				FormService.errors[scope.field.input.model];

          if (!FormService.errors[scope.field.input.model]) {
            scope.field.input.valid = true;
          }
        };

        scope.field.input.valid = true;

        // scope.change = function() {


        // 	// if(scope.field.input.mask)
        // 	// 	scope.field.input.answer = MaskService.mask(scope.field.input.mask, scope.field.input.answer);

        // 	scope.field.input.answer = scope.data.input.data.model;

        // 	// if(scope.field.input.valid === false)
        // 	scope.field.input.valid = FormService.validate('change', scope.field, [scope.field.input.validate]);

        // }

        // scope.blur = function() {
        // 	var validators = ['blank'];

        // 	if(scope.field.input.validate)
        // 		validators.push(scope.field.input.validate);

        // 	scope.field.input.valid = FormService.validate('blur', scope.field, validators);
        // }

        scope.field.input.answer = FormService.answers[scope.field.input.model] !== undefined
			&& FormService.answers[scope.field.input.model] !== null
          ? FormService.answers[scope.field.input.model]
          : scope.field.input.default_value;

        if (scope.field.input.answer === -1)
          scope.field.input.answer = '';

        // FormService.register_input_row_observer_cb(row_change, scope.field.input.model);
        // FormService.register_input_step_observer_cb(step_change, scope.field.input.model);
        FormService.register_input_error_observer_cb(error_change, scope.field.input.model);
        FormService.register_input_clear_observer_cb(clear, scope.field.input.model);
      }
    };
  }
]);
