appDirectives.directive('inputslider', ['$timeout', function($timeout) {
	
	var timeout = undefined;
	var drag = {
		start_x: 0
	};
	var width_px = 0;
	var is_first = true;
	
    return {
        restrict: "E",
        replace: true,
        scope: {
	        'context': '=context',
	        'min': '=min',
	        'max': '=max'
        },
        templateUrl: 'join_webserver/partials/common/input-slider.html',
		link: function(scope, element, attrs) {
			
			scope.transition = true;
			
			
			width_px = $(element).width();
			
			scope.click = function(e) {
				
				var perc = calc(e.offsetX);
				update(parseInt(perc, 10), 0);
				
			}
			
			scope.mousedown = function(e) {
				
				drag.start_x = e.pageX;
				var perc = (scope.context-scope.min)/(scope.max-scope.min) * 100
				var val = 0;
				scope.transition = false;
				
				$(window).bind('mousemove.inputslider', function(e) {
					val = (e.pageX - drag.start_x)/width_px*100;
					update(parseInt(scope.min+((perc + val)/100)*(scope.max - scope.min), 10), 0);
				});
				
				$(window).bind('mouseup.inputslider', function(e) {
					$(window).unbind('.inputslider');
					scope.transition = true;
				});
				
			}
			
			scope.$watch('context', function(newVal, oldVal) {
				
				if((newVal != oldVal) || is_first)
					update(parseInt(newVal, 10), 500);
					
				is_first = false;
				
			});
			
			var update = function(val, delay) {
				
				$timeout.cancel(timeout);
				timeout = $timeout(function() {
					
					var text = (val < scope.min) ? scope.min : (val > scope.max) ? scope.max : val;
					var perc = (text-scope.min)/(scope.max-scope.min) * 100;
					
					scope.handleStyle = { left: perc + '%' }
					scope.fillStyle = { width: perc + '%' }
					scope.context = text;
				
				}, delay);
				
			}
			
			var calc = function(val) {
				return (val/width_px)*(scope.max-scope.min) + scope.min;
			}
			
	    },
	    controller: ['$scope', function($scope) {
		    
		    
		    
	    }]
    }
}]);