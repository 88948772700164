/* eslint-disable no-mixed-spaces-and-tabs */
'use strict';
appDirectives.directive('nwselect', [
  '$timeout',
  'FormService',
  'NwuiInputService',
  function(
    $timeout,
    FormService,
    NwuiInputService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        field: '<field',
        step_index: '<stepIndex',
        locale_prefix: '<localePrefix'
      },
      templateUrl: 'join_webserver/partials/common/form/select.html',
      link: function(scope) {
        /* ngIfCollectionPerModel is keeping a unique reference to ng_if models
        as collected from all the options. This is becuase it will be use to
        register observers to those models so that a option list update
        trigger can be performed. So this prevents the same model from having
        multple obervers attached which would then trigger update multiple
        times when that model changes. */
        var ngIfCollectionPerModel = {};

        var update_options = function() {
          scope.data.input.data.options = build_options(false);
        };

        /* recurse was copied from elsewhere in the code. It's purpose is to find
        all the models associated within ng_if so that a they can be collected
        and assigned an observer */
        var recurse = function(ng_if) {
          if (ng_if.and) {
            recurse(ng_if.and);
          } else if (ng_if.or) {
            recurse(ng_if.or);
          } else if (ng_if.ng_if_model) {
            // only one observer per model should be registered for the update call.
            /* TODO - technically, if multiple models where registered and changed
            at the same time, the update be called for each. A new obervable
            design should be implemented to allow a flattening of these callbacks
            into a signle one if the callbacks are the same. */
            if (!ngIfCollectionPerModel[ng_if.ng_if_model]) {
              FormService.register_row_observer_cb(update_options, ng_if.ng_if_model);
              ngIfCollectionPerModel[ng_if.ng_if_model] = ng_if.ng_if_model;
            }
            if (ng_if.ng_if_value_model && ngIfCollectionPerModel[ng_if.ng_if_value_model]) {
              FormService.register_row_observer_cb(update_options, ng_if.ng_if_value_model);
              ngIfCollectionPerModel[ng_if.ng_if_value_model] = ng_if.ng_if_value_model;
            }
          } else if (ng_if) {
            angular.forEach(ng_if, function(obj) {
              recurse(obj);
            });
          }
        };

        var clear = function() {
          if (NwuiInputService.inputs['join-forms-' + scope.field.input.model]) {
            NwuiInputService.inputs['join-forms-' + scope.field.input.model].model = '';
            FormService.answers[scope.field.input.model] = '';
          }
        };

        var error_change = function() {
          if (!FormService.errors[scope.field.input.model]) {
            scope.field.input.valid = true;
          }
        };
        var build_options = function(isInit) {
          var triggerClear = true;
          var newOptions = scope.field.input.options
		        .reduce(function(options, option) {
              if (isInit) {
                if (option.ng_if) {
                  recurse(option.ng_if);
                }
              }
              // If there is no ng_if then include option, otherise check evaluation of ng_if.
              if (!option.ng_if || (option.ng_if && FormService.checkShow(option.ng_if))) {
                if (isInit || (triggerClear && FormService.answers[scope.field.input.model] === option.value)) {
                  triggerClear = false;
                }
                options.push({
                  id: option.id,
                  text: option.localize === false ? option.text : undefined,
                  text_i18n: (option.localize || option.localize == null) ?
                    'nwui.' + scope.locale_prefix + '.questions.'
							      + scope.field.input.i18n + '.options.' + option.value : undefined,
                  value: option.value
                });
              }

              return options;
            }, []);
          if (triggerClear) {
            clear();
            scope.field.input.answer = '';
          }
          return newOptions;
        };

        var validate = function(type) {
          if (!type) type = 'change';
          return FormService.validate(type, scope.field, [scope.field.input.validate]);
        };
        FormService.register_input_validate_observer_cb(validate, scope.field.model);

        var updateModel = function(model, value) {
					if(NwuiInputService.inputs['join-forms-' + model]) {
						NwuiInputService.inputs['join-forms-' + model].model = value;
						FormService.answers[model] = value;
					}else if(NwuiInputService.inputs['join-questionnaire-' + model]){
						NwuiInputService.inputs['join-questionnaire-' + model].model = value;
						FormService.answers[model] = value;
					}
				};

        scope.data = {
          input: {
            directive: 'select',
            data: {
              label: '',
              label_i18n: 'nwui.' + scope.locale_prefix + '.questions.' + scope.field.input.i18n + '.input.label' || '',
              sr_only_label: true,
              model: scope.field.input.default_value || '',
              id: 'join-forms-' + scope.field.input.model,
              stretch: true,
              required: true,
              default_value: scope.field.input.default_value || undefined,
              size: '',
              search: scope.field.input.search,
              placeholder_i18n: 'nwui.' + scope.locale_prefix + '.questions.'
                + scope.field.input.i18n + '.input.placeholder',
              sortBy: scope.field.input.sort_by,
              options: build_options(true),
              onChange: function(model) {
                if (FormService.current_step_index !== scope.step_index) return;

                scope.field.input.options.forEach(function(option) {
                  if (option.value === model) {
                    scope.select(option);
                  }
                });
              }
            }
          }
        };

        $timeout(function() {
          scope.row_observer_cbs = FormService.public_observer_cbs.row_ifs[scope.field.input.model] || [];
          scope.step_observer_cbs = FormService.public_observer_cbs.step_ifs[scope.field.input.model] || [];

          angular.forEach(scope.row_observer_cbs, function(cb) {
            cb(scope.field.input.answer);
          });

          angular.forEach(scope.step_observer_cbs, function(cb) {
            cb(scope.field.input.answer);
          });
        }, 0);

        scope.field.input.valid = true;
        scope.field.input.answer = FormService.answers[scope.field.input.model] || scope.field.input.default_value;
        scope.is_open = false;
        scope.input_value = scope.field.input.placeholder;
        scope.model_temp = scope.field.input.placeholder;

        angular.forEach(scope.field.input.options, function(option) {
          if (option.value === scope.field.input.answer) {
            scope.input_value = option.text;
          }
        });

        scope.select = function(choice) {
          scope.field.input.answer = choice.value;

          FormService.answers[scope.field.input.model] = choice.value;

          scope.field.input.valid = validate();

          if (scope.field.input.model === 'province') {
            FormService.notify_province(scope.field);
          }

          if (scope.field.company_code_autocomplete) {
            var mappings = scope.field.company_code_autocomplete.mapping;
            var companyCodeModel = mappings[0].model;
            updateModel(companyCodeModel, choice.value);
          }

          angular.forEach(scope.field.input.options, function(option) {
            if (option.value === scope.field.input.answer) {
              scope.input_value = option.text;
            }
          });

          angular.forEach(scope.row_observer_cbs, function(cb) {
            cb(choice.value);
          });

          angular.forEach(scope.step_observer_cbs, function(cb) {
            cb(choice.value);
          });
        };
        FormService.register_input_error_observer_cb(error_change, scope.field.input.model);
        FormService.register_input_clear_observer_cb(clear, scope.field.input.model);

        scope.$watch('field.input.valid', function(new_val) {
          if (new_val === undefined) return;

          if (NwuiInputService.inputs['join-forms-' + scope.field.input.model])
            NwuiInputService.inputs['join-forms-' + scope.field.input.model].error = !new_val;
        });

        var unbind = scope.$watch('field.input.answer', function(new_val) {
          if (new_val === undefined) return;

          scope.data.input.data.model = new_val;

          unbind();
        });
      }
    };
  }
]);
