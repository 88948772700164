angular.module('app.signature.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		$stateProvider.state('onboarding.signature', {
			url: '/signature',
			views: {
				"signature": {
					templateUrl: 'join_webserver/signature/template.html',
					controller: 'SignatureController'
				}
			},
			params: {
				client_present: null
			},
			data: {
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				FormInstanceData: ["FormInstance", "NwuiAuthService", function( FormInstance, NwuiAuthService) {
					return NwuiAuthService.isTokenValid().then(function(res) {
						return FormInstance.create()
					}, function(error) {
					})
				}],
				KYCInstanceData: ["KYCInstance", "KYCService", "NwuiAuthService", function(KYCInstance, KYCService, NwuiAuthService) {
					return NwuiAuthService.isTokenValid().then(function() {
						return KYCInstance.create();
					}, function(error) {
					})
				}],
        SigningPowerData: ['$http', '$q', '$state', 'NwuiSession', function($http, $q, $state, NwuiSession) {
          var roleName = '';
          if (NwuiSession.user.Role) {
            roleName = NwuiSession.user.Role.name;
          } else if (NwuiSession.user.role) {
            roleName = NwuiSession.user.role.name;
          }
          if (
            !organization_data.join_data.signing ||
            !organization_data.join_data.signing.check_signing_power ||
            roleName !== 'HR Rep'
          ) {
            var deferred = $q.defer();
            deferred.resolve({HasSigningPower: true});
            return deferred.promise;
          }
          return $http.post('/api/form_instance')
            .then(function(res) {
              var form_answers = res.data.form_answers;
              return {
                HasSigningPower: form_answers.HasSigningPower === 'Yes',
                SignerEmail: form_answers.SignerEmail,
                SignerFirstName: form_answers.SignerFirstName,
                SignerLastName: form_answers.SignerLastName,
                SignerJobTitle: form_answers.SignerJobTitle
              };
            })
            .then(function(data) {
              if (!data.HasSigningPower) {
                return $state.go('onboarding.status');
              } else {
                return data;
              }
            });
        }]
			},
		});

	}
]);