appServices.service('SetPasswordService', [
	'$state',
	'$rootScope', 
	'$http', 
	'Session', 
	'$cookies', 
	'$q', 
	//'$intercom', 
	'AUTH_EVENTS', 
	function(
		$state, 
		$rootScope, 
		$http, 
		Session, 
		$cookies, 
		$q, 
		//$intercom, 
		AUTH_EVENTS
	) {

	var self = this;
	
	this.setPassword = function(data) {
		
		var deferred = $q.defer();
		
		$http.post('/set-password', data).then(function(res, status) {
			deferred.resolve(res.data);
		}, function(res, status) {
			deferred.reject(res.data);
		});
		
		return deferred.promise;
		
	}
		
}]);