'use strict';

appServices.service('ValidationService', [
  'DATE_FORMAT',
  'SinValidateService',
  'KYCService',
  'FormAnswers',
  'locale',
  '$filter',
  function(DATE_FORMAT, SinValidateService, KYCService, FormAnswers, locale, $filter) {
    var Error = function(message, data) {
      this.message = message || 'Something went wrong';
      Object.assign(this, data);
    };
    var self = this;

    self.FormAnswers = FormAnswers.get();

    var regex = {
      email: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
      email_with_plus: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      proper_name: /^[a-zA-Z áàâäèéêëîïíìóôœúùûüÿçÁÀÂÄÈÉÊËÎÏÍÌÓÔŒÚÙÛÜŸÇ '-]*$/i,
      alphabetic: /^[a-z���������������� '-]*$/i,
      alphanumeric: /^[a-z0-9]+$/i,
      numeric: /^[0-9]*$/,
      postal: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
      zipcode: /^\d{5}$|^\d{5}-\d{4}$/,
      no_special_char: /^[a-zA-ZÀ-Ÿ0-9- ]*$/i
    };

    var is_blank = function(input) {
      return !!(input === undefined || input.length === 0);
    };

    var comparison_op = function(val1, op, val2) {
      switch (op) {
        case '<':
          return val1 < val2;
        case '<=':
          return val1 <= val2;
        case '>=':
          return val1 >= val2;
        case '>':
          return val1 > val2;
        case '==':
          return val1 === val2;
        case '!=':
          return val1 !== val2;
      }
    };

    this['3_digit'] = function(input, params, models) {
      // non numeric
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');

      if (input.length !== 3) return new Error('nwui.common.error.numericLength', {
        length: 3
      });

      return true;
    };

    this['7_digit'] = function(input, params, models) {
      // non numeric
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');

      if (input.length !== 7) return new Error('nwui.common.error.numericLength', {
        length: 7
      });

      return true;
    };

    this['9_digit'] = function(input, params, models) {
      // non numeric
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');

      if (input.length !== 9) return new Error('nwui.common.error.numericLength', {
        length: 9
      });

      return true;
    };

    this.hundred_percent = function(input, params, models) {
      input = input + '';
      if (input.substring(input.length - 1) === '.' || Number(input) > 100) {
        return new Error('nwui.common.error.over_hundred_percent');
      } else {
        return true;
      }
    };

    this.proper_name = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      return !input || regex.proper_name.test(input) ? true : new Error('nwui.common.error.proper_name');
    };

    this.blank = function(input, params, models) {
      // ((input === 0) || (input && (String(input).length != 0)))

      var trimmedInput = input;

      if (typeof trimmedInput === 'string') trimmedInput = trimmedInput.trim();

      return trimmedInput != null && trimmedInput !== '' ? true : new Error('nwui.common.error.blank');
    };

    // Depreicated KYC validator
    this.select = function(input, params, models) {
      return this.blank(input, params, models);
    };

    this.alphabetic = function(input, params, models) {
      return !input || regex.alphabetic.test(input) ? true : new Error('nwui.common.error.alphabetic');
    };

    this.alphanumeric = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      return !input || regex.alphanumeric.test(input) ? true : new Error('nwui.common.error.alphanumeric');
    };

    this.no_special_char = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      return !input || regex.no_special_char.test(input) ? true : new Error('nwui.common.error.no_special_char');
    };

    this.age = function(input, params, models) {
      // is blank
      if (input === undefined || input === '') return true;

      // non numeric
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');

      // under 18
      if (parseInt(input, 10) < 18) return new Error('nwui.common.error.age_restricted');

      return true;
    };

    this.numeric = function(input, params, models) {
      return !input || regex.numeric.test(input) ? true : new Error('nwui.common.error.numeric');
    };

    this.horizon = function(input, params, models) {
      // is blank
      if (input === undefined || input === '') return true;

      // non numeric
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');

      var minHorizon = organization_data.join_data.questionnaire.horizon.min;
      var maxHorizon = organization_data.join_data.questionnaire.horizon.max;

      if (parseInt(input, 10) < minHorizon || parseInt(input, 10) > maxHorizon) {
        return new Error('nwui.common.error.horizon', {
          horizon_max: maxHorizon,
          horizon_min: minHorizon
        });
      };
      return true;
    };

    this.transit = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      // IS TOO SHORT
      if (input.length < 5) return new Error('nwui.common.error.format');

      // IS NOT NUMERIC
      if (regex.numeric.test(input) === false) return new Error('nwui.common.error.format');

      if (this.non_zero(input) !== true) return new Error('nwui.common.error.non_zero');

      return true;
    };

    this.institution = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      // IS TOO SHORT
      if (input.length < 3) return new Error('nwui.common.error.format');

      // IS NOT NUMERIC
      if (regex.numeric.test(input) === false) return new Error('nwui.common.error.format');

      if (this.non_zero(input) !== true) return new Error('nwui.common.error.non_zero');

      return true;
    };

    this.accountno = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      // IS NOT NUMERIC
      if (regex.numeric.test(input) === false) return new Error('nwui.common.error.format');

      // if the whole string is underscores and non-word characters (excludes french characters)
      if (typeof input !== 'string' || !regex.alphanumeric.test(input))
        return new Error('nwui.common.error.alphanumeric');

      var numbers_only_input = input.replace(/[^0-9]/gi, '');
      if (
        typeof input === 'string' && // valid
        numbers_only_input == input && // if you only have numbers is it the same?
        this.non_zero(numbers_only_input) !== true // check only acccounts that are all numbers
      )
        return new Error('nwui.common.error.non_zero');

      return true;
    };

    this.liquidnetworthportfolio = function(input, params, models) {
      // Check if liquid net worth is above the portfolio threshold
      var kyc = KYCService.get();
      if (kyc !== undefined && kyc.pie_data !== undefined && kyc.pie_data.name !== undefined) {
        var portfolio_threshold = parseInt(params[0].portfolio_options[kyc.pie_data.name], 10);
        if (!portfolio_threshold.isNaN && portfolio_threshold > parseInt(input, 10))
          return new Error('nwui.common.error.liquidnetworth.portfolio', {
            threshold: $filter('currency')(portfolio_threshold)
          });
      }

      return true;
    };

    this.liquidnetworthkyc = function(input, params, models) {
      // Check if liquid net worth is within the selected answer in kyc
      var numbers_only_input = input;
      var kyc = KYCService.get();
      if (kyc !== undefined && kyc.kyc_answers !== undefined && kyc.kyc_answers[params[0].kyc_answer] !== undefined) {
        // var kycquestion = kyc.kyc
        var kyc_answer = parseInt(kyc.kyc_answers[params[0].kyc_answer], 10);
        switch (kyc_answer) {
          case 1:
            if (parseInt(numbers_only_input, 10) > parseInt(params[0].kyc_options['1'], 10))
              return new Error('nwui.common.error.liquidnetworth.kyc');
            break;
          case 2:
            if (parseInt(numbers_only_input, 10) > parseInt(params[0].kyc_options['2'], 10)
            || parseInt(numbers_only_input, 10) <= parseInt(params[0].kyc_options['1'], 10))
              return new Error('nwui.common.error.liquidnetworth.kyc');
            break;
          case 3:
            if (parseInt(numbers_only_input, 10) > parseInt(params[0].kyc_options['3'], 10)
            || parseInt(numbers_only_input, 10) <= parseInt(params[0].kyc_options['2'], 10))
              return new Error('nwui.common.error.liquidnetworth.kyc');
            break;
          case 4:
            if (parseInt(numbers_only_input, 10) > parseInt(params[0].kyc_options['4'], 10)
            || parseInt(numbers_only_input, 10) <= parseInt(params[0].kyc_options['3'], 10))
              return new Error('nwui.common.error.liquidnetworth.kyc');
            break;
          case 5:
            if (parseInt(numbers_only_input, 10) <= parseInt(params[0].kyc_options['4'], 10))
              return new Error('nwui.common.error.liquidnetworth.kyc');
        }
      }

      return true;
    };

    this.incomesources = function(input, params, models) {
      var hasIncomeSource = false;
      // Check for employment
      if (
        (self.FormAnswers['ownershipType'] === 'individual' ||
          self.FormAnswers['ownershipType'] === 'joint') &&
        (self.FormAnswers['AHEmploymentStatusValue'] === 'Employed' ||
          self.FormAnswers['AHEmploymentStatusValue'] === 'SelfEmployed')
      ) return true;

      angular.forEach(models, function(model) {
        hasIncomeSource = hasIncomeSource || model.answer;
      });
      hasIncomeSource = hasIncomeSource || !!input;

      if (!hasIncomeSource) return new Error('nwui.common.error.incomesources.nonzero');
      return true;
    };

    this.compare_int_values = function(input, params, models) {
      // Compare the input value to a model given a comparison operator
      // ex: models = ['AHSomeModel'], params = [{'comparison_op': '<='}]
      var numberOnlyInput = input;
      var modelToCompare = models[0].answer;
      if (modelToCompare === undefined || modelToCompare === '') return true;
      if (typeof modelToCompare !== 'string' || !regex.alphanumeric.test(modelToCompare)) return true;
      var numberOnlyModelToCompare = modelToCompare.replace(/[^0-9]/gi, '');
      if (
        typeof modelToCompare === 'string' && // valid
        numberOnlyModelToCompare === modelToCompare && // if you only have numbers is it the same?
        this.non_zero(numberOnlyModelToCompare) !== true // check only acccounts that are all numbers
      ) return true;

      if (params !== undefined && params.length > 0 && params[0].comparison_op !== undefined) {
        var inputInt = parseInt(numberOnlyInput, 10);
        var modelToCompareInt = parseInt(modelToCompare, 10);
        var comparedTo = comparison_op(inputInt, params[0].comparison_op, modelToCompareInt);
        if (!comparedTo) return new Error('nwui.common.error.liquidnetworth.comparison_op', {
          model: locale.getString('nwui.forms.fcc.questions.' + models[0].model.model + '.input.label'),
          comparison_op: locale.getString('nwui.common.error.comparison_op.' + params[0].comparison_op)
        });
      }

      return true;
    };

    /**
     * Compares an input number of a model to a
     * pre-defined set value.
     */
    this.compare_numbers = function(input, params) {
      var numberOnlyInput = input;

      if (params !== undefined && params.length > 0
        && params[0].comparison_op !== undefined && params[1].comparison_value !== undefined) {
        var inputInt = parseInt(numberOnlyInput, 10);
        var compareValue = parseInt(params[1].comparison_value, 10);
        var comparedTo = comparison_op(inputInt, params[0].comparison_op, compareValue);
        if (!comparedTo) return new Error('nwui.common.error.compare_number', {
          comparison_op: locale.getString('nwui.common.error.comparison_op.' + params[0].comparison_op),
          compare_val: compareValue
        });
      }

      return true;
    };

    this.non_zero = function(input, params, models) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      var inputNum = parseInt(input);
      if (typeof input === 'string' && (inputNum === 0 || isNaN(inputNum)))
        return new Error('nwui.common.error.non_zero');

      return true;
    };

    this.email = function(input, params, models) {
      if (is_blank(input)) return true;

      return !input || regex.email_with_plus.test(input) ? true : new Error('nwui.common.error.format');
    };

    this.upload_format = function(input, params, models) {
      return input !== 'invalid' ? true : new Error('nwui.common.error.format');
    };

    this.image = function(input, params, models) {
      var types = ['image/tiff', 'image/png', 'image/jpeg', 'image/gif', 'application/pdf'];
      var flag = false;

      for (var i = 0; i < types.length; i++) {
        if (types[i] == input) {
          var flag = true;
          break;
        }
      }

      return flag ? true : new Error('nwui.common.error.upload_type');
    };

    this.check = function(inputs) {
      var flag = false;

      angular.forEach(inputs, function(input, params, models) {
        if (input) flag = true;
      });

      return flag ? true : new Error('nwui.common.error.checkbox_one_or_more');
    };

    this.confirmation_check = function(inputs) {
      return inputs[Object.keys(inputs)[0]]
        ? true
        : new Error('nwui.common.error.please_confirm');
    };

    this.all_check = function(inputs) {
      var keys = Object.keys(inputs);
      for (var i = 0; i < keys.length; i++) {
        if (!inputs[keys[i]]) {
          return new Error('nwui.common.error.please_confirm');
        }
      }

      return true;
    };

    this.all_check_agree = function(inputs) {
      var keys = Object.keys(inputs);
      for (var i = 0; i < keys.length; i++) {
        if (!inputs[keys[i]]) {
          return new Error('nwui.common.error.all_check_agree');
        }
      }

      return true;
    };

    this.sin = function(input, params, models) {
      if (is_blank(input)) return true;

      if (
        input !== undefined && // exists
        input.length == 9 && // correct length
        SinValidateService.isLuhnId(input)
      )
        return true;
      else return new Error('nwui.common.error.format');
    };

    this.ssn = function(input, params, models) {
      if (is_blank(input)) return true;

      if (
        input !== undefined && // exists
        input.length == 9 // correct length
      )
        return true;
      else return new Error('nwui.common.error.format');
    };

    this.percentage = function(input, params, models) {
      return true;
    };

    this.upload = function(input, params, models) {
      return true;
    };

    this.postal = function(input, params, models) {
      return !input || regex.postal.test(input) ? true : new Error('nwui.common.error.format');
    };

    this.zipcode = function(input, params, models) {
      return !input || regex.zipcode.test(input) ? true : new Error('nwui.common.error.format');
    };

    this.date = function(input, params, models) {
      if (is_blank(input)) return true;

      if (
        input !== undefined && // exists
        input.length == 8 && // correct length
        moment(input, DATE_FORMAT.digit).format(DATE_FORMAT.digit) !== 'Invalid date' // YYYY-MM-DD
      )
        return true;
      else return new Error('nwui.common.error.format');
    };

    this.date_future = function(input, params, models) {
      if (input === undefined || input === '') return true;

      var is_proper_date_format = self.date(input);
      if (is_proper_date_format !== true) return is_proper_date_format;

      var current_date = moment(new Date()).format(DATE_FORMAT.digit);

      if (input >= current_date) return true;
      else return new Error('nwui.common.error.future');
    };

    this.date_past = function(input, params, models) {
      if (input === undefined || input === '') return true;

      var is_proper_date_format = self.date(input);
      if (is_proper_date_format !== true) return is_proper_date_format;

      var current_date = moment(new Date()).format(DATE_FORMAT.digit);

      if (input < current_date) return true;
      else return new Error('nwui.common.error.past');
    };

    this.date_current_or_past = function(input) {
      if (input === undefined || input === '') return true;
      var is_proper_date_format = self.date(input);
      if (is_proper_date_format !== true) return is_proper_date_format;
      var current_date = moment(new Date()).format(DATE_FORMAT.digit);
      if (input <= current_date) return true;
      else return new Error('nwui.common.error.date_current_or_past');
    };

    this.year_future = function(input, params, models) {
      var current_date = moment(new Date()).format(DATE_FORMAT.year);

      if (input === undefined || input === '') return true;

      if (input.length < 4) return new Error('nwui.common.error.format');
      else if (input > current_date) return true;
      else return new Error('nwui.common.error.future');
    };

    this.yearandmonth = function(input, params, models) {
      if (!input) return true;

      var isCorrectLength = input.length === 6;
      var isValidDate = moment(input, 'YYYYMM').format('YYYYMM') !== 'Invalid date';

      if (isCorrectLength && isValidDate) return true;

      return new Error('nwui.common.error.format');
    };

    this.yearandmonth_future = function(input, params, models) {
      var validYearAndMonth = self.yearandmonth(input);
      if (!validYearAndMonth) return validYearAndMonth;

      if (input > moment().format('YYYYMM')) return true;
      return new Error('nwui.common.error.future');
    };

    this.phone = function(input, params, models) {
      if (input == null || input === '') return true;

      var PHONE_REGEX = /[1-9]\d{2}\d{3}\d{4}/;

      if (PHONE_REGEX.test(input)) {
        return true;
      }

      return new Error('nwui.common.error.format');
    };

    this.age_with_limits = function(input, params, models) {
      // is blank
      if (input === undefined || input === '') return true;

      // non numeric
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');

      var ageLimit = organization_data.ageLimit;

      if (ageLimit && (+input < ageLimit.minimum || +input > ageLimit.maximum)) {
        return new Error('nestkit.errors.4506');
      }

      return true;
    };

    this.birthdate_with_limits = function(input, params, models) {
      // is blank
      if (is_blank(input)) return true;

      var is_proper_date_format = self.date(input);
      if (is_proper_date_format !== true) return is_proper_date_format;

      var ageLimit = organization_data.ageLimit;

      var ageMaximumDate = moment().subtract(ageLimit.maximum, 'years');
      var ageMinimumDate = moment().subtract(ageLimit.minimum, 'years');
      var inputBirthDate = moment(input, DATE_FORMAT.digit);

      if (ageLimit && (inputBirthDate.isAfter(ageMinimumDate) || inputBirthDate.isBefore(ageMaximumDate))) {
        return new Error('nestkit.errors.4506');
      }

      return true;
    };

    // Use this validator instead of the previous one
    this.birthdate_with_limits_v2 = function(input, params, models) {
      // is blank
      if (is_blank(input)) return true;

      var is_proper_date_format = self.date(input);
      if (is_proper_date_format !== true) return is_proper_date_format;

      // error on dates in the future
      var isInPast = this.date_past(input, params, models);
      if (isInPast !== true) return isInPast;

      var today = moment();
      var birthday = moment(input, DATE_FORMAT.digit);
      var age = today.diff(birthday, 'year');

      if (params && params[0] && params[0].maximum) {
        var maxAge = parseInt(params[0].maximum, 10);
        if (age > maxAge) return new Error('nwui.common.error.birthdate_with_limits_v2.max_age', { max_age: maxAge });
      }
      if (params && params[0] && params[0].minimum) {
        var minAge = parseInt(params[0].minimum, 10);
        if (age < minAge) return new Error('nwui.common.error.birthdate_with_limits_v2.min_age', { min_age: minAge });
      }

      return true;
    };

    this.currencyLimit = function(input, params, models) {
      if (is_blank(input)) return true;
      if (!regex.numeric.test(input)) return new Error('nwui.common.error.numeric');
      var amount = input;
      if (params && params[0] && params[0].maximum) {
        var maxAmount = parseInt(params[0].maximum, 10);
        if (amount > maxAmount) return new Error('nwui.common.error.currencyLimit.maxAmount', { max_amount: maxAmount });
      }
      if (params && params[0] && params[0].minimum) {
        var minAmount = parseInt(params[0].minimum, 10);
        if (amount < minAmount) return new Error('nwui.common.error.currencyLimit.minAmount', { min_amount: minAmount });
      }
      return true;
    };

    this.date_future_of = function(input, params, models) {
      if (input === undefined || input === '') return true;
      var past_date = self.FormAnswers[params[0].date_model];
      if (past_date === undefined || past_date === '') {
        return new Error('nwui.common.error.startEndDate');
      }
      if (input > past_date) return true;
      else return new Error('nwui.common.error.startEndDate');
    };

    this.date_future_in_businessdays = function(input, params, models) {
      var expected_days_apart = params[0].day_count;
      var current_date = moment(new Date());
      var days_apart = moment(input, 'YYYYMMDD').businessDiff(moment(current_date, 'YYYYMMDD'));
      if (days_apart <= parseInt(expected_days_apart)) {
        return new Error('nwui.common.error.futurebusinessdays', { days: expected_days_apart });
      } else return true;
    };

    this.block_no_values = function(input, params) {
      if (input === 'No') {
        return new Error(`nwui.common.error.blockNoProgress.${params[0].error_slug}`, {
          error_msg: params[0].error_msg
        });
      } else return true;
    };

    this.business_number = function(input, params) {
      // IS BLANK
      if (input === undefined || input === '') return true;

      // IS TOO SHORT
      if (input.length < 15) return new Error('nwui.common.error.format');

      // IS TOO LONG
      if (input.length > 15) return new Error('nwui.common.error.format');

      return true;
    };
  }
]);

