appServices.service('GeneralService', [
	'$http',
	'$q',
	'PORTFOLIO_WEBSERVER',
	function(
		$http,
		$q,
		PORTFOLIO_WEBSERVER
	) {
	
		this.setLanguage = function(to_lang) {

			return $http.get('/api/language?language=' + to_lang)
			
		}
    
	}
]);