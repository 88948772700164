appDirectives.directive('nwaddress', [
	'$timeout',
	'FormService',
	'MaskService',
	'ValidationService',
	'NwuiInputService',
	function(
		$timeout,
		FormService,
		MaskService,
		ValidationService,
		NwuiInputService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			scope: {
				field: '<field',
				step_index: '<stepIndex',
				current_step_index: '<currentStepIndex',
				locale_prefix: '<localePrefix'
			},
			templateUrl: 'join_webserver/partials/common/form/address.html',
			link: function(scope, element, attrs) {


				var locks = {
					model: false
				}

				var timeout = {
					validate: undefined
				}

				var skip_first_validate = true;

				// scope.row_showing = true;

				var mask_map = {
					"age": "age",
					"date": "date_dashed",
					"postal": "postal",
					"3_digit": "bank_institution",
					"numeric": "numeric",
					"transit": "transit",
					"currency": "currency"
				}

				if(scope.field.input.mask === 'transit')
					scope.field.input.validate = 'transit';
				if(scope.field.input.mask === '3_digit')
					scope.field.input.validate = 'institution';

				var getFieldFromResults = function(results, fieldName) {
					if (Array.isArray(fieldName)) {
						var fields = [];
						for (var i in fieldName) {
							var field = getSingleFieldFromResults(results, fieldName[i]);
							if (field) {
								fields.push(field);
							}
						}
						return fields.join(', ');
					}
					return getSingleFieldFromResults(results, fieldName);
				};

				var getSingleFieldFromResults = function(results, fieldName) {
					var value = '';
					for(var i in results.retrieve) {
						var result = results.retrieve[i];
						if(
							result.FieldName === fieldName &&
							result.FieldGroup !== 'CommonAlternative' &&
							result.FieldGroup !== 'CountryAlternative'
						){
							value = result.FormattedValue;
							break;
						}
					}
					return value;
				}

				var updateModel = function(model, value) {
					if(NwuiInputService.inputs['join-forms-' + model]) {
						NwuiInputService.inputs['join-forms-' + model].model = value;
						FormService.answers[model] = value;
					}else if(NwuiInputService.inputs['join-questionnaire-' + model]){
						NwuiInputService.inputs['join-questionnaire-' + model].model = value;
						FormService.answers[model] = value;
					}
				};

				var getCountry = function(address_autocomplete) {
					var country;
					if (address_autocomplete.country) {
						country = address_autocomplete.country;
					} else if (address_autocomplete.country_model
						&& FormService.answers[address_autocomplete.country_model]) {
						country = FormService.answers[address_autocomplete.country_model];
					}
					return country;
				};

				var validate = function(type) {
					if (!type) type = 'change';
					return FormService.validate(type, scope.field, [scope.field.input.validate]);
				};
				FormService.register_input_validate_observer_cb(validate, scope.field.model);
				

				scope.data = {
					input: {
						directive: 'address',
						data: {
							label: '',
							label_i18n: 'nwui.' + scope.locale_prefix + '.questions.' +
								scope.field.input.i18n + '.input.label',
						  	sr_only_label: true,
							model: FormService.answers[scope.field.input.model] || '',
							id: 'join-forms-' + scope.field.input.model,
							stretch: true,
							required: true,
							maxlength: scope.field.input.maxlength,
							size: '',
							mask: mask_map[scope.field.input.mask] || mask_map[scope.field.input.validate] || scope.field.input.mask,
							placeholder_i18n: 'nwui.' + scope.locale_prefix + '.questions.' + scope.field.input.i18n + '.input.placeholder',
							defaultField: scope.field.address_autocomplete.default_field,
							country: getCountry(scope.field.address_autocomplete),
							onBlur: validate,
							onChange: function(model) {
								scope.data.input.data.country = getCountry(scope.field.address_autocomplete);
								FormService.answers[scope.field.input.model] = scope.field.input.answer = model;
								validate();
							},
							address_selected_cb: function(results) {
								if (scope.field.address_autocomplete) {
									var mappings = scope.field.address_autocomplete.mapping;
									mappings.forEach(function(mapping) {
										var value = getFieldFromResults(results, mapping.field);
										updateModel(mapping.model, value);
									});
								}
							}
						}
					}
				}

				var clear = function() {
					$timeout.cancel(timeout.validate);
					NwuiInputService.inputs['join-forms-' + scope.field.input.model].model = '';
					FormService.answers[scope.field.input.model] = '';
				}

				var error_change = function() {

					if(NwuiInputService.inputs['join-forms-' + scope.field.input.model])
						NwuiInputService.inputs['join-forms-' + scope.field.input.model].error = FormService.errors[scope.field.input.model];

					if(!FormService.errors[scope.field.input.model]) {
						scope.field.input.valid = true;
					}
				}

				scope.field.input.valid = true;

				scope.field.input.answer = FormService.answers[scope.field.input.model];

				if(scope.field.input.answer === -1)
					scope.field.input.answer = '';

				FormService.register_input_error_observer_cb(error_change, scope.field.input.model);
				FormService.register_input_clear_observer_cb(clear, scope.field.input.model);

			}
		}
	}
]);