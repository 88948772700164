/* eslint-disable */

appDirectives.directive('nwstep', [
	'$timeout', 
	'$state', 
	'$rootScope', 
	'$sce',
	'FormService', 
	'PieData', 
	'NwuiAnalyticsService',
	'NwuiButtonService', 
	'NwuiButtonV3Factory',
	'NwButtonService',
	'locale',
	'NwuiSession',
	'NwuiCardHeaderV2Factory',
	function(
		$timeout, 
		$state, 
		$rootScope, 
		$sce,
		FormService, 
		PieData, 
		NwuiAnalyticsService,
		NwuiButtonService,
		NwuiButtonV3Factory,
		NwButtonService,
		locale,
		NwuiSession,
		NwuiCardHeaderV2Factory
	) {
	
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		scope: {
			index: '<index',
			totalsteps: '<totalsteps',
			currentstepindex: '<currentstepindex',
			step: '<step',
			locale_prefix: '<localePrefix'
		},
		templateUrl: 'join_webserver/partials/common/form/step.html',
		link: function(scope, element, attrs) {
			
      scope.data = {
		  answers: FormService.answers,
		  showErrorWhileImpersonating: (
			organization_data &&
			organization_data.join_data && 
			organization_data.join_data.showErrorWhileImpersonating),
		access_code: FormService.access_code,
      	workflow_stages: (
					organization_data &&
					organization_data.join_data &&
					organization_data.join_data.workflow &&
					organization_data.join_data.workflow.stages
				) ? organization_data.join_data.workflow.stages : [{ state: 'questionnaire' }, { state: 'forms' }],
				joint_code_required: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.joint_code_required
				)
			}
			
			var isRoleExcludedFromStage = function(stage) {
				return (
					stage &&
					stage.excludeRoles &&
					stage.excludeRoles.indexOf(NwuiSession.user.Role.name_slugged) !== -1
				);
			}
			var showErrorWhileImpersonating = scope.data.showErrorWhileImpersonating
      var getPreviousStageIndex = function(state) {

      	var parsed_state = state.split('.')[1];

      	var active_index = -1;
      	for(var i = 0; i < scope.data.workflow_stages.length; i++) {
      		if(
						parsed_state === scope.data.workflow_stages[i].state || 
						(scope.data.workflow_stages[i].state === 'questionnaire' && parsed_state === 'question') ||
						(scope.data.workflow_stages[i].state === 'forms' && parsed_state === 'form')
      		) {
      			active_index = i;
      			break;
      		}
      	}

				if(isRoleExcludedFromStage(scope.data.workflow_stages[active_index-1])) {
					return -1;
				}

      	return active_index-1;

	  }
	  
      scope.showPrevButton = !FormService.isStepTheFirstVisibleStep(scope.index);
      scope.showBackButton = FormService.isStepTheFirstVisibleStep(scope.index) && (getPreviousStageIndex($state.current.name) >= 0);
			scope.feeData = FormService.feeData;

      scope.modules = {
      	access_code: {
					card: {
						header: new NwuiCardHeaderV2Factory({
							name_i18n: "forms.joint_code.header.name",
							level: 2,
							icon: {
								name: 'info'
							}
						})
					}
				}
      }

		if(PieData.data && organization_data.join_data.questionnaire.pie.allocation_only) {
			PieData.data = [PieData.data[0]];
		}

      if(scope.data.answers.ownershipType === 'joint' && !FormService.access_code && scope.data.joint_code_required) {
      	FormService
      		.getJointCode()
      		.then(function(obj) {
      			scope.data.access_code = obj.data.value;
      		})
      }
      
			var update_pie_data = function(reverse) {
			
				if(!PieData.data) return;
			
				
				scope.pieColorArray = PieData.data.map(function(datum){
					return datum.color;
				})
				
				scope.pie = {
					data: angular.copy(PieData.data),
					legend: angular.copy(PieData.legend)
				}
				
				var temp = angular.copy(scope.pie.data);

				if (organization_data.join_data.questionnaire.pie.allocation_only) scope.pie.data = [scope.pie.data[0]];
				//temp = scope.pie.data;
				
				if(reverse) {
					temp.reverse();
					scope.pie.legend.reverse();
				}
				
				temp = temp.map(function(d,i) {
					return {
						name: i,
						percent: d.y,
						type: d.type,
						description: d.key
					}
				});
				var temp_trimmed = [];
				temp.forEach(function(o,i) {
					if(o.percent > 0)
						temp_trimmed.push(o);
				});
				scope.pie_data = temp_trimmed;
				scope.pie_legend = scope.pie.legend;
				scope.active_pie_slice = scope.pie_data[0];
				
			}

			var province_change = function(field) {
			}
			
			var tax_benefits_change = function(field) {
			}
			
			var init = function() {
				scope.errors = FormService.errors;
				scope.has_errors = false;
				scope.step.index = scope.index;
				scope.loading = false;
				
				if(scope.totalsteps == scope.index+1) {
					FormService.register_province_observer_cb(province_change);
				}
				FormService.register_tax_benefits_observer_cb(tax_benefits_change);
				scope.step.element = angular.element(element).find('.size');
			
			}
			
			var validate = function() {
			
				angular.forEach(scope.step.rows, function(row) {
					
					if((row.ng_if && Object.keys(row.ng_if).length > 0) && !FormService.showing_row_ifs[row._id]) return;
					
					angular.forEach(row.fields, function(field, i) {	
						var validators = ['blank'];
						if (field.input.validate) {
							validators = [field.input.validate];
						} else if (field.input.input_type == 'check') {
							validators = ['check'];
						}
						if(field.input.readonly) {
							validators = [];
						}
						return field.input.valid = FormService.validate('change', field, validators, showErrorWhileImpersonating );
						
					});
				});
				
			}
			
			scope.go_to = function(state) {
				/*
				if("province" in FormService.answers){
					if(FormService.answers.province != 'ON') {
						state = 'onboarding.thank_you'
					}
				}*/

				FormService.form_complete();
				$timeout(function() {
					$state.go(state);
				}, 600);
			}
			
			scope.prev_step = function() {
				//FormService.set_step_by_index(scope.index-1);
				$timeout(function() {
					FormService.go_to_prev_step();
				}, 500)
				
				/*$timeout(function() {
					var e_offset = $('.steps-block').offset().top-120;
					
					if($(window).scrollTop() > e_offset)
						$('html, body').animate({ scrollTop: e_offset }, 650, 'swing');
				}, 500);*/
			}
			
			scope.next_step = function() {
				validate();
				
				$timeout(function() {
					if(!scope.has_errors) {
						if(NwButtonService.buttons[scope.current_stage + '-button-next-step-' + scope.index].processing) return;
						scope.loading = true;
						
						FormService.save_step(scope.step, function(e) {
							
							$timeout(function() {

								if(e) {
									var set_error = false;
									NwButtonService.buttons[scope.current_stage + '-button-next-step-' + scope.index].disabled = true;
									scope.loading = false;

									scope.step.rows.forEach(function(row) {
					
										row.fields.forEach(function(field, i) {
											if (e.data.model == field.input.model) {
												if (e.data.error_slug === 'genericError') {
													e.data.i18n_data = {
														inputName : locale.getString('nwui.forms.questions.' + e.data.model + '.input.label')
													};
												}

												FormService.set_error(field, e.data);
												set_error = true;
											}					
										});

									});

									if(set_error === false) {
										FormService.set_error(scope.step.rows[0].fields[0], e.data);
									}

									return;
								}

								NwButtonService.buttons[scope.current_stage + '-button-next-step-' + scope.index].disabled = false;
								scope.loading = false;
								FormService.go_to_next_step();
							}, 500);
							
						}, function() {

							scope.loading = false;
							
						});
					}
				}, 0);
			}
			
			scope.next_button = {
				id: 'next-step-' + scope.index + '-button'
			}
			scope.current_stage = scope.data.workflow_stages[getPreviousStageIndex($state.current.name) + 1].state;

			scope.buttons = {
				back: new NwuiButtonV3Factory({
					id: 'button-back-step-' + scope.index,
					text_i18n: 'forms.steps.buttons.previous',
					color: 'alternate',
					icon: {
						name: 'arrow_back'
					},
					icon_only_mobile: true,
					onClick: function() {
						var state = scope.data.workflow_stages[getPreviousStageIndex($state.current.name)].state;
						state = (state === 'forms') ? 'form' : state;
						state = (state === 'questionnaire') ? 'question' : state;
						$state.go('onboarding.' + state);
					}
				}),
				prev: new NwuiButtonV3Factory({
					id: scope.current_stage + '-button-prev-step-' + scope.index,
					text_i18n: 'forms.steps.buttons.previous',
					color: 'alternate',
					icon: {
						name: 'arrow_back'
					},
					icon_only_mobile: true,
					onClick: function() {
						scope.prev_step();
					}
				}),
				next: new NwuiButtonV3Factory({
					id: scope.current_stage + '-button-next-step-' + scope.index,
					text_i18n: 'forms.steps.buttons.next',
					onClick: function() {
						scope.next_step();
					}
				}),
				continue: new NwuiButtonV3Factory({
					id: scope.current_stage + '-button-continue-step-' + scope.index,
					text_i18n: scope.locale_prefix + '.steps.buttons.continue',
					icon: {
						name: 'flag'
					},
					onClick: (organization_data.join_data.invite_after_impersonation && NwuiSession.isAdvisorImpersonating()
					&& ((scope.locale_prefix === 'forms' && organization_data.join_data.invite_after_impersonation.after_form_questions)
						|| (scope.locale_prefix === 'questionnaire' && organization_data.join_data.invite_after_impersonation.after_KYC)))
					? function() {
						scope.go_to("onboarding.status");
					}
					: function() {
						scope.go_to(scope.step.content.button.state);
					}
				})
			}

			scope.hasIf = function(step_if) {

				return step_if !== undefined && Object.keys(step_if).length > 0;

			}

			scope.$watch('errors', function() {
				scope.has_errors = !(Object.keys(scope.errors).length == 0);
			}, true);
			
			init();
			
			if(scope.totalsteps == (scope.index+1)) {
				$rootScope.$on('new_pie_data', function() {
					update_pie_data(true);
				});
				
				update_pie_data(false);
			}

			scope.$watchGroup(['step.key', 'step.indicator', 'step.title'], function(new_val) {

				var key = new_val[0];
				var indicator = new_val[1];
				var title = new_val[2];

				if(key !== undefined) {
					
					scope.title_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + key + '.title'
					scope.subtitle_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + key + '.subtitle'
					scope.disclaimer_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + key + '.disclaimer'
				}

				else if(indicator !== undefined) {

					scope.title_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + indicator + '.title'
					scope.subtitle_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + indicator + '.subtitle'
					scope.disclaimer_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + indicator + '.disclaimer'
				}

				else if(title !== undefined) {

					scope.title_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + title.toLowerCase().replace(/\(|\)|\?|\,/g, '').replace(/ /g, '_') + '.title'
					scope.subtitle_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + title.toLowerCase().replace(/\(|\)|\?|\,/g, '').replace(/ /g, '_') + '.subtitle'
					scope.disclaimer_i18n = 'nwui.' + scope.locale_prefix + '.steps.' + title.toLowerCase().replace(/\(|\)|\?|\,/g, '').replace(/ /g, '_') + '.disclaimer'
				}

				scope.show_disclaimer = locale.isTranslated(scope.disclaimer_i18n);

			});

			/*
			 * Custom Modules
			 */

			scope.show_custom_module = null;
			scope.can_override_portfolio = (
				organization_data.join_data &&
				organization_data.join_data.questionnaire &&
				organization_data.join_data.questionnaire.override_portfolio
			);

			if (scope.can_override_portfolio) {
				scope.content_link_post = locale.getString(scope.locale_prefix + '.complete.content_link_post');
				scope.trusted_content_link_post = $sce.trustAsHtml(scope.content_link_post);
				scope.has_content_link_post = scope.content_link_post !== '%%KEY_EMPTY%%';
			}

			scope.content_link = locale.getString(scope.locale_prefix + '.complete.content_link');
			scope.has_content_link = scope.content_link !== '%%KEY_EMPTY%%';

			/*
			 * Custom Portfolio Allocation
			 */

			scope.overrideClicked = function() {
				scope.show_custom_module = 'custom_portfolio_allocation';
			};

			scope.closeModule = function() {
				scope.show_custom_module = null;
			}

			scope.openChat = function(e) {

				if(window.Intercom) {
					window.Intercom('show')
				}
				
			}

			scope.suitabilityIsBlocked = FormService.blockKYCCondition() &&
				scope.index != 0 &&
				scope.currentstepindex == scope.index &&
				scope.totalsteps == scope.index+1;

			scope.IsVpicOA = NwuiSession.user.Role.name === 'Operations Administrator' && organization_data.slug === 'vpic';
			
		}
	}

}]);