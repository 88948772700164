appDirectives.directive('introstep', ['$timeout', '$window', function($timeout, $window) {
		return {
				restrict: "E",
				transclude: true,
				scope: {
					'step_id': '=stepId',
			'shown': '=',
			'onselect': '=',
			'onerror': '=',
			'style': '=stepstyle'
				},
				template: "<div class = 'size' ng-transclude></div>",
				link: function(scope, element, attrs) {
					
					var size = element.find('.size');
					var input = element.find('input');
					var loop = false;
					var width = $window.innerWidth;
					var interval = {
						resize: undefined
					}

					scope.style = {};

					scope.$watch('shown', function(newVal) {  

						$timeout(function() {  
							scope.calibrate();
						}, 50);
					
						$timeout(function() {  
							if(scope.step_id === 2) {
								// DAVE HATES THE AUTOFOCUS SELECT :(
								// $('#step-3-select-focus').focus();
							} else
								input.focus();
						}, 450);
					});

					scope.$watch('onselect', function(newVal) {

						if(scope.onselect) {
							$timeout(function() {
								scope.calibrate();
							}, 200);
						}
							
					});
					
					scope.calibrate = function() {


						width = size.width();
						
						if(scope.shown) {
							scope.style.width = size.width() + 'px'; 
							scope.style.height = size.height() + 'px';
						} else {
							scope.style.width = 0 + 'px'
							scope.style.height = 0 + 'px';
						}

						// scope.$apply();

						if(loop !== false) return;
						var loop = true;
						
						$timeout(function() {
							if(size.width() + 'px' !== scope.style.width) {
								scope.calibrate();
							}
						}, 2500);
				
					}
					
					scope.$watch('onerror', function(newVal) {

						if(scope.onerror) {
							$timeout(function() {
								scope.calibrate();
							}, 50);
						}
							
					});
					
					// angular.element($window).bind('resize', function() {
					// 	//scope.$apply(function() {
					// 		scope.calibrate();
					// 	//});
					// });

					// scope.$watch(function() {
					// 	return angular.element($window)[0].innerWidth;
					// }, function(new_val) {
					// 	$timeout(function() {
					// 		scope.calibrate();
					// 	}, 50);
					// });

					interval.resize = setInterval(function() {
						// if(width === size.width()) return;
						scope.calibrate();
						scope.$apply();
					}, 1000);

					scope.$on('$destroy', function() {
						if(interval.resize)
							clearInterval(interval.resize);
					});
					
			}
		}
}]);