angular.module('app.signup.controllers', [])

.controller('SignupController', [
	"$scope",
	"$rootScope",
	"$timeout",
	"$location",
	"NWUI_AUTH_EVENTS",
	"NwuiInputService",
	function(
		$scope,
		$rootScope,
		$timeout,
		$location,
		NWUI_AUTH_EVENTS,
		NwuiInputService
	) {
		
		$scope.show_welcome_text = organization_data.join_data.show_welcome_text;
		$scope.show_welcome_image = organization_data.join_data.show_welcome_image;

		var query_params = $location.search();

		$timeout(function() {
			NwuiInputService.inputs['signup-firstname'].model = query_params.first_name;
			NwuiInputService.inputs['signup-lastname'].model = query_params.last_name;
			NwuiInputService.inputs['signup-email'].model = query_params.email;
		}, 1000);

	}
]);