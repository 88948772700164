appDirectives.directive('nwrow', ['FormService', function(FormService) {
	
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		scope: {
			index: '<index',
			row: '<row',
		},
		templateUrl: 'join_webserver/partials/common/form/row.html',
		link: function(scope, element, attrs) {
			
			scope.show_if = true;
			if(scope.row.ng_if === undefined || Object.keys(scope.row.ng_if).length == 0) return;

			scope.show_if = false;
			if (scope.row.fields.length === 1) {
				scope.sectionId = scope.row.fields[0].model + 'Section';
			}

			var row_if_change = function(value) {


				var flag = FormService.checkShow(scope.row.ng_if);

				if(flag) {

					FormService.add_showing_row_if(scope.row._id);

				} else {

					FormService.remove_showing_row_if(scope.row._id, scope.row.fields);

				}

				scope.show_if = flag;
				
			}

			var recurse = function(ng_if) {
				
				//is and
				if(ng_if.and) {
					recurse(ng_if.and);
				} 

				//is or
				else if(ng_if.or) {
					recurse(ng_if.or);
				} 

				//is model
				else if(ng_if.ng_if_model) {

					if(ng_if.ng_if_value_model)
						FormService.register_row_observer_cb(row_if_change, ng_if.ng_if_value_model);
						
					FormService.register_row_observer_cb(row_if_change, ng_if.ng_if_model);
					row_if_change();
				}

				else if(ng_if) {
					angular.forEach(ng_if, function(obj, i) {
						recurse(obj);
					});
				}

			}

			recurse(scope.row.ng_if)

			scope.hasIf = function(row_if) {
				return row_if !== undefined && Object.keys(row_if).length > 0;
			}
			  
		}
	}

}]);