appDirectives.directive('fixedslider', ['$timeout', '$window', 'FixedsliderService', function($timeout, $window, FixedsliderService) {
    return {
        restrict: "E",
        transclude: true,
        replace: true,
        scope: {
	        'width': '=width'
        },
        templateUrl: 'join_webserver/partials/common/fixed-slider.html',
        link: function(scope, element, attrs) {
	        
	        scope.width = 50;
	        
	        var slide_number = 0;
	        
	        var window = {
		        e: angular.element($window),
		        h: 0
		    }
		    
		    var slider = {
			    e: angular.element(element),
			    h: 0
		    }
		    
		    var form = {
			    e: angular.element('.question-layout > .inner .left'),
			    h: 0
		    }
	        
	        scope.calibrate = function() {
		    	
		    	
		    	var left = $('header#global').height() - 20 + form.e.children('.intro').outerHeight(true) + form.e.find('.new-step').eq(slide_number).outerHeight(true)
		    	
		    	return {
			    	window: window.e.height(),
			    	width: window.e.width(),
			    	document: angular.element(document).height(),
			    	scroll: window.e.scrollTop(),
			    	left: left,
			    	slider: slider.e.height()
			    }
		    	
	        }
		    
		    scope.$watch(scope.calibrate, function(newVal, oldVal) {
			    
			    if(newVal.width <= 1023) {
				    form.e.stop(true,true).css({});
				    scope.style = {};
				    return;
			    }
			    
			    $timeout(function() {
					    
				    //left is longer
				    //right is longer
				    
				    if(newVal.left > newVal.slider) {
					    
					    form.e.stop(true,true).css({
							position: '',
							top: 0,
							left: '',
							//width: scope.width + '%'
					    })
						    
					    if(newVal.window > newVal.slider) {
							
							scope.style = {
								position: 'fixed',
								top: 0,
								right: 0,
								width: scope.width + '%'
						    }
						    
						    //window.e.unbind('scroll.fixed-slider');
					      
					    } else {
						    
						    var top = (newVal.scroll > (newVal.slider - newVal.window)) 
						    	? (newVal.window - newVal.slider)
						    	: newVal.scroll * -1;
						    
						    scope.style = {
							    position: 'fixed',
							    top: top + 'px',
							    right: 0,
							    width: scope.width + '%'
							}
							
							/*window.e.unbind('scroll.fixed-slider').bind('scroll.fixed-slider', function() {
								scope.calibrate();
								scope.$apply();
					        });*/
						    
					    }
					
					} else {
						
						if(newVal.window > newVal.left) {
							
							form.e.css({
								position: 'fixed',
								top: 0,
								left: 0,
								//width: scope.width + '%'
						    })
						    
						    //window.e.unbind('scroll.fixed-slider');
					      
					    } else {
							
							form.e.stop(true,true).css({
								position: 'fixed',
								left: 0
							});
							
						    var top = (newVal.scroll > (newVal.left - newVal.window)) 
						    	? (newVal.window - newVal.left)
						    	: newVal.scroll * -1;
						    
						    
						    form.e.animate({
								//position: 'fixed',
								top: top + 'px',
								//left: 0,
								//width: scope.width + '%'
						    }, 250)
							
							/*window.e.unbind('scroll.fixed-slider').bind('scroll.fixed-slider', function() {
								scope.calibrate();
								scope.$apply();
					        });*/
						    
					    }
					    
					    scope.style = {
							position: 'absolute',
							top: 0,
							right: 0,
							width: '100%'
					    }
						
					}
					
				}, 0);
			    
		    }, true);
	        
	        window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function(new_val, old_val) {
				if(new_val === old_val) return;
				scope.calibrate();
			});
	        
	        $timeout(function() {
		        window.e.unbind('scroll.fixed-slider').bind('scroll.fixed-slider', function() {
					scope.calibrate();
					scope.$apply();
		        });
	        },0);
	        
	        FixedsliderService.register_observer_cb(function(i) {
		        $timeout(function() {
			        slide_number = i;
			        scope.calibrate();
			    }, 0);
	        });
	        
	    }
	}
}]);