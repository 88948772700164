appFactories.factory('KYCFactory', ["KYCResource", function(KYCResource){
    function KYC(kyc_instance_id){
        var kyc = this
        kyc.questions = []
        kyc.getQuestions(kyc, kyc_instance_id)
    }
    KYC.prototype = {
        getQuestions: function(kyc, kyc_instance_id){
            KYCResource.query({params: {kyc_instance_id: kyc_instance_id}}).$promise.then(function(data){
                angular.extend(kyc.questions, data.map(function(datum){ return datum }))
            })
        }
    }
    return KYC
}])