angular.module('app.iframe-loading.controllers', [])

.controller('IframeLoadingController',[
	"$scope", 
	"$rootScope", 
	"$timeout", 
	"$state",
	"$cookies",
	function(
		$scope, 
		$rootScope, 
		$timeout, 
		$state, 
		$cookies
	) {
	

		$scope.modules = {
			loading: {
				color: '',
				active: true,
				determinate: false
			}
		}

	}
]);