appDirectives.directive("nwcheck", [
  "$timeout",
  "FormService",
  "KYCService",
  "NwuiInputService",
  function ($timeout, FormService, KYCService, NwuiInputService) {
    return {
      restrict: "E",
      replace: true,
      transclude: true,
      scope: {
        field: "<field",
        step_index: "<stepIndex",
        locale_prefix: "<localePrefix",
      },
      templateUrl: "join_webserver/partials/common/form/check.html",
      compile: function (element, attrs) {
        return {
          pre: function (scope, element, attrs) {
            scope.show_rec =
              window.organization_data.join_data.forms.show_account_rec;
            scope.field.input.answer = {};
            scope.row_showing = true;
            scope.show_more = false;
            scope.show_more_text = "forms.accounts.show_more";
            scope.field.input.valid = true;
            scope.row_observer_cbs = {};
            scope.step_observer_cbs = {};
            /* ngIfCollectionPerModel is keeping a unique reference to ng_if models
							as collected from all the checkbox options
						*/
            var ngIfCollectionPerModel = {};
            scope.select = function (choice) {
              var showErrorWhileImpersonating =
                window &&
                window.organization_data &&
                window.organization_data.join_data &&
                window.organization_data.join_data.showErrorWhileImpersonating;

              var validationType = scope.field.input.validate
                ? [scope.field.input.validate]
                : ["blank"];
              // this line is updating the FormService.answer object with the new selected answer
              FormService.answers[choice.model] =
                scope.field.input.answer[choice.model];

              scope.field.input.valid = FormService.validate(
                "change",
                scope.field,
                validationType,
                showErrorWhileImpersonating
              );

              // this forEach will find the observer associated with the model and trigger the cb functions (update_options in our case);
              angular.forEach(
                scope.row_observer_cbs[choice.model],
                function (cb) {
                  cb(choice.value);
                }
              );

              angular.forEach(
                scope.step_observer_cbs[choice.model],
                function (cb) {
                  cb(choice.value);
                }
              );
            };

            /*  this function was taken from recurse() in private/js/eu/appDirectives/directives/form/radio.js.
            it will create an observer with a cb for every ng_if model. This allows the checkbox options for in vivo ng-if updates
             */
            var createObserverForNgIfModel = function (ng_if) {
              if (ng_if.and) {
                createObserverForNgIfModel(ng_if.and);
              } else if (ng_if.or) {
                createObserverForNgIfModel(ng_if.or);
              } else if (ng_if.ng_if_model) {
                // only one observer per model should be registered for the update call
                if (!ngIfCollectionPerModel[ng_if.ng_if_model]) {
                  FormService.register_row_observer_cb(
                    update_options,
                    ng_if.ng_if_model
                  );
                  ngIfCollectionPerModel[ng_if.ng_if_model] = ng_if.ng_if_model;
                }
              } else if (ng_if) {
                angular.forEach(ng_if, function (obj) {
                  createObserverForNgIfModel(obj);
                });
              }
            };

            var update_options = function () {
              scope.data.input.data.options = build_options(false);
            };

            var build_options = function (isInit) {
              var triggerClear = true;
              var newCheckboxOptions = [];
              // this will go through each option found inside the input object (which is inside the question object)
              scope.field.input.options.forEach(function (option) {
                var optionNgIf = option.ng_if;
                var optionModel = option.model;
                var fieldInputModel = scope.field.input.model;
                var value = FormService.answers[optionModel] || false;

                if (
                  scope.locale_prefix === "questionnaire" &&
                  FormService.answers[fieldInputModel]
                ) {
                  var index = 0;
                  FormService.answers[fieldInputModel].forEach(function (
                    checkbox,
                    i
                  ) {
                    if (checkbox.model === optionModel) index = i;
                  });

                  option.value = value =
                    FormService.answers[fieldInputModel][index].value;

                  scope.field.input.answer[optionModel] = value;
                  scope.select(option);
                }

                if (isInit) {
                  if (optionNgIf) {
                    /* the way questions are displayed are based on the observer callback functions running whenever the
                    ng_if_model changes in value. This recursion will add an observer callback function to each checkbox option ng_if_model */
                    createObserverForNgIfModel(optionNgIf);
                  }
                }

                if (
                  !optionNgIf ||
                  (optionNgIf && FormService.checkShow(option.ng_if))
                ) {
                  newCheckboxOptions.push({
                    label: option.localize === false ? option.text : undefined,
                    label_i18n:
                      option.localize || option.localize == null
                        ? "nwui." +
                          scope.locale_prefix +
                          ".questions." +
                          scope.field.input.i18n +
                          ".options." +
                          optionModel
                        : undefined,
                    model: optionModel,
                    value: value,
                  });
                }
                // will determine if the option value needs to be cleared
                if (
                  isInit ||
                  (triggerClear &&
                    FormService.answers[optionModel] === option.value)
                ) {
                  triggerClear = false;
                }

                if (triggerClear) {
                  /* unlike other input types, checkbox needs to be turned to false instead of ''.
                  The model is an array for checkbox, so you need to find the correct obj inside the model array
                  before you can 'clear' it */
                  var index = 0;
                  if (
                    NwuiInputService.inputs[
                      "join-forms-" + scope.field.input.model
                    ]
                  ) {
                    NwuiInputService.inputs[
                      "join-forms-" + scope.field.input.model
                    ].model.forEach((checkbox, i) => {
                      if (checkbox.model === optionModel) {
                        index = i;
                      }
                      return;
                    });

                    NwuiInputService.inputs[
                      "join-forms-" + scope.field.input.model
                    ].model[index].value = false;
                    FormService.answers[optionModel] = false;
                    scope.field.input.answer[optionModel] = false;
                  }
                }
                return;
              });

              return newCheckboxOptions;
            };

            scope.data = {
              accounts_checkbox: {
                label: "",
                label_i18n:
                  "nwui." +
                    scope.locale_prefix +
                    ".questions." +
                    scope.field.input.i18n +
                    ".input.label" || "",
                sr_only_label: true,
                model: [],
                id: "join-forms-recommended-" + scope.field.input.model,
                id_p2: "join-forms-" + scope.field.input.model,
                stretch: true,
                required: true,
                size: "",
                type:
                  scope.field.input.layout === "inline"
                    ? "inline"
                    : scope.field.input.layout === "two-col"
                    ? ""
                    : "stacked",
                options: [], //req for factory
                p1_options: [],
                p2_options: [],
                onChange: function (model) {
                  if (FormService.current_step_index !== scope.step_index)
                    return;

                  scope.field.input.options.forEach(function (option) {
                    model.forEach(function (item) {
                      if (
                        option.model === item.model &&
                        scope.field.input.answer[option.model] !== item.value
                      ) {
                        // storing the selected answer to the input answer object
                        scope.field.input.answer[option.model] = item.value;
                        scope.select(option);
                      }
                    });
                  });
                },
              },
              input: {
                directive: "checkbox",
                data: {
                  label: "",
                  label_i18n:
                    "nwui." +
                      scope.locale_prefix +
                      ".questions." +
                      scope.field.input.i18n +
                      ".input.label" || "",
                  sr_only_label: true,
                  model: "",
                  id: "join-forms-" + scope.field.input.model,
                  stretch: true,
                  required: true,
                  disabled: scope.field.input.readonly,
                  size: "",
                  type:
                    scope.field.input.layout &&
                    scope.field.input.layout.indexOf("inline") !== -1
                      ? "inline"
                      : scope.field.input.layout === "two-col"
                      ? ""
                      : "stacked",
                  options: build_options(true),
                  onChange: function (model) {
                    if (FormService.current_step_index !== scope.step_index)
                      return;

                    scope.field.input.options.forEach(function (option) {
                      model.forEach(function (item) {
                        if (
                          option.model === item.model &&
                          scope.field.input.answer[option.model] !== item.value
                        ) {
                          scope.field.input.answer[option.model] = item.value;
                          scope.select(option);
                        }
                      });
                    });
                  },
                },
              },
            };
          },
          post: function (scope, element, attrs) {
            var ageMap = [
              {
                max: 30,
                types: ["CashCad", "TFSA", "RspCad"],
              },
              {
                max: 70,
                types: ["CashCad", "TFSA", "RspCad", "RESP"],
              },
              {
                max: 150,
                types: ["CashCad", "TFSA", "Rif"],
              },
            ];

            var error_change = function () {
              if (!FormService.errors[scope.field.input.model]) {
                scope.field.input.valid = true;
              }
            };

            scope.showMore = function (e) {
              scope.show_more = scope.show_more === true ? false : true;
              scope.show_more_text = scope.show_more
                ? "forms.accounts.show_less"
                : "forms.accounts.show_more";
              e.preventDefault();
              e.stopPropagation();

              $timeout(function () {
                FormService.clear_all_errors();
              }, 100);
            };

            $timeout(function () {
              angular.forEach(scope.field.input.options, function (option) {
                var optionNgIf = option.ng_if;

                if (FormService.answers[option.model] === undefined)
                  FormService.answers[option.model] = false;
                if (optionNgIf) {
                }
                scope.field.input.answer[option.model] =
                  FormService.answers[option.model];
                scope.row_observer_cbs[option.model] =
                  FormService.public_observer_cbs.row_ifs[option.model] || [];
                scope.step_observer_cbs[option.model] =
                  FormService.public_observer_cbs.step_ifs[option.model] || [];

                angular.forEach(
                  scope.row_observer_cbs[option.model],
                  function (cb) {
                    cb(scope.field.input.answer[option.model]);
                  }
                );

                angular.forEach(
                  scope.step_observer_cbs[option.model],
                  function (cb) {
                    cb(scope.field.input.answer[option.model]);
                  }
                );
              });
            }, 0);

            FormService.register_input_error_observer_cb(
              error_change,
              scope.field.input.model
            );

            scope.$watch("field", function (new_val) {
              if (new_val === undefined || KYCService.get() === undefined)
                return;

              if (new_val.input && new_val.input.input_type === "check") {
                new_val.input.options.forEach(function (option) {
                  if (option.tooltip) {
                    option.tooltip = {
                      title_i18n:
                        "nwui." +
                        scope.locale_prefix +
                        ".questions." +
                        scope.field.input.i18n +
                        ".tooltips." +
                        option.model +
                        ".title",
                      content_i18n:
                        "nwui." +
                        scope.locale_prefix +
                        ".questions." +
                        scope.field.input.i18n +
                        ".tooltips." +
                        option.model +
                        ".content",
                      direction: option.tooltip.direction,
                    };
                  }
                });
              }

              if (new_val.input.model === "accounts") {
                //GET AGE
                var age = parseInt(KYCService.get().kyc_answers.age || 42, 10);
                var current_bracket = undefined;

                //reset P1/P2
                scope.field.input.p1_options = [];
                scope.field.input.p2_options = [];
                scope.field.label = "Accounts";

                ageMap.forEach(function (bracket) {
                  if (age <= bracket.max && current_bracket === undefined) {
                    current_bracket = bracket;
                  }
                });

                if (current_bracket === undefined)
                  current_bracket = ageMap[ageMap.length - 1];

                new_val.input.options.forEach(function (option, i) {
                  var flag = false;
                  current_bracket.types.forEach(function (type, j) {
                    //certain tenants do not want the recommendation feature so in those cases set the flag to true for all,
                    //otherwise follow the ageMap defined above
                    if (!scope.show_rec || option.model === type) {
                      flag = true;
                    }
                  });

                  if (flag === true) {
                    scope.field.input.p1_options.push(option);
                  } else {
                    scope.field.input.p2_options.push(option);
                  }
                });

                scope.data.accounts_checkbox.p1_options =
                  scope.field.input.p1_options.map(function (option) {
                    return {
                      // label: option.text,
                      label_i18n:
                        "nwui." +
                        scope.locale_prefix +
                        ".questions." +
                        scope.field.input.i18n +
                        ".options." +
                        option.model,
                      model: option.model,
                      value: FormService.answers[option.model],
                      tooltip: option.tooltip,
                    };
                  });

                scope.data.accounts_checkbox.p2_options =
                  scope.field.input.p2_options.map(function (option) {
                    return {
                      // label: option.text,
                      label_i18n:
                        "nwui." +
                        scope.locale_prefix +
                        ".questions." +
                        scope.field.input.i18n +
                        ".options." +
                        option.model,
                      model: option.model,
                      value: FormService.answers[option.model],
                      tooltip: option.tooltip,
                    };
                  });
              }
            });

            scope.$watch("field.input.valid", function (new_val) {
              if (new_val === undefined) return;

              if (
                NwuiInputService.inputs[
                  "join-forms-recommended-" + scope.field.input.model
                ]
              )
                NwuiInputService.inputs[
                  "join-forms-recommended-" + scope.field.input.model
                ].error = !new_val;

              if (
                NwuiInputService.inputs["join-forms-" + scope.field.input.model]
              )
                NwuiInputService.inputs[
                  "join-forms-" + scope.field.input.model
                ].error = !new_val;
            });
          },
        };
      },
    };
  },
]);
