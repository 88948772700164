appControllers.controller('ApplicationController', [
	'$rootScope',
	'$scope',
	'$timeout',
	'locale',
	'NwuiLocalizeService',
	//'$intercom',
	'USER_ROLES',
	'AuthService',
	'PageTitleService',
	'$state',
	'Session',
	'$cookies',
	'$http',
	'$q',
	'$sce',
	'$location',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'INTERCOM_APPID',
	'NwuiButtonV3Factory',
	'UserDataService',
	'tmhDynamicLocale',
	'NwuiAnalyticsService',
	function (
		$rootScope,
		$scope,
		$timeout,
		locale,
		NwuiLocalizeService,
		//$intercom,
		USER_ROLES,
		AuthService,
		PageTitleService,
		$state,
		Session,
		$cookies,
		$http,
		$q,
		$sce,
		$location,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		INTERCOM_APPID,
		NwuiButtonV3Factory,
		UserDataService,
		tmhDynamicLocale,
		NwuiAnalyticsService
	) {
		//console.log('ApplicationController', 'Loaded');

		var data = {
			processStepsHeader: [],
			workflow_stages: (
				organization_data &&
				organization_data.join_data &&
				organization_data.join_data.workflow &&
				organization_data.join_data.workflow.stages
			) ? organization_data.join_data.workflow.stages : [{ state: 'questionnaire' }, { state: 'forms' }]
		}

		var bootIntercom = function (user) {

			if (window.Intercom) {

				if (user === undefined || user.email === undefined || user.first_name === undefined || user.last_name === undefined) {
					window.Intercom('shutdown');
				} else {
					window.Intercom('shutdown');
					setTimeout(() => {
						window.Intercom('boot', {
							app_id: INTERCOM_APPID,
							language_override: $cookies.get('nw_i18n_lang'),
							user_id: NwuiSession.user.uuid,
							user_hash: NwuiSession.user.intercom_hash || undefined,
							email: user.email,
							name: user.first_name + " " + user.last_name
						});
					})
					
				}

				window.Intercom('onShow', function () {
					$scope.data.intercom_open = true;
					$scope.$apply();
				})

				window.Intercom('onHide', function () {
					$scope.data.intercom_open = false;
					$scope.$apply();
				})
			}

		}

		var pageTitleCb = function () {

			$scope.page_title = PageTitleService.title;
		}

		$scope.toggleIntercom = function (e) {

			if (window.Intercom) {
				window.Intercom('show')
			}

		}

		$scope.closeIntercom = function () {

			if (window.Intercom) {
				window.Intercom('hide')
			}

		}

		PageTitleService.registerObserverCallback(pageTitleCb);

		$scope.customHeader = !!(organization_data.join_data && organization_data.join_data.custom_header);

		$scope.loaded = true
		$scope.currentUser = null
		$scope.userRoles = USER_ROLES
		$scope.isAuthorized = AuthService.isAuthorized
		$scope.has_chat = INTERCOM_APPID !== undefined ? true : false;

		if (organization_data.join_data.calculator.show) {

		}

		for (var i = 0; i < data.workflow_stages.length; i++) {

			switch (data.workflow_stages[i].state) {
				case 'calculator':
					data.processStepsHeader.push(
						{
							state: 'onboarding.calc',
							url: '/',
							slug: 'header.nav.step_1',
							showing: true
						}
					);
					break;
				case 'questionnaire':
					data.processStepsHeader.push(
						{
							state: "onboarding.question.step({ 'step_index': 1 })",
							url: '/questionnaire',
							slug: 'header.nav.step_2',
							showing: true
						}
					);
					break;
				case 'forms':
					if (
						!(
							organization_data.join_data.forms &&
							organization_data.join_data.forms.show === false
						)
					) {
						data.processStepsHeader.push(
							{
								state: "onboarding.form.step({ 'step_index': 1 })",
								url: '/forms',
								slug: 'header.nav.step_3',
								showing: true
							}
						)
					}
					break;
			}

		}

		$scope.processStepsHeader = data.processStepsHeader;
		$scope.organization_data = organization_data;
		$scope.organization_data.funds = [];

		if ($cookies.get('brand')) {
			$scope.brand = $cookies.get('brand');
		}

		$scope.setCurrentUser = function (user) {
			$scope.currentUser = user
			// $scope.loaded = true
		}

		var setOrganizationData = function setOrganizationData(org_data) {
			var brand = '';
			$scope.organization_data.name = org_data.name || $scope.organization_data.name;
			$scope.organization_data.name_short = org_data.name_short || $scope.organization_data.name_short;
			$scope.organization_data.disclaimer = org_data.disclaimer || $scope.organization_data.disclaimer;

			// $scope.organization_data.email = org_data.email || $scope.organization_data.email;
			switch ($scope.organization_data.slug) {
				case 'md-financial':
				case 'md':
					$scope.organization_data.funds = [
						'MD Equity',
						'MD Select',
						'MD American Growth',
						'MD American Value',
						'MD Growth',
						'MD International Growth',
						'MD International Value',
						'Mackenzie Emerging Markets',
						'MD Short-Term Bond',
						'MD Bond',
						'MD Strategic Yield',
						'MD Strategic Opportunities',
					];
					brand = 'brand-md';
					break;
				case 'cibc':
					brand = 'brand-cibc';
					break;
				case 'nbcn':
					brand = 'brand-nbcn';
					break;
				case 'abc':
					brand = 'brand-abc';
					break;
				default:
					break;
			}
		}

		//if the token doesn't exist - load the login page
		//var token = $cookies.get('token')
		/*if(typeof(token) == 'undefined') {
				$scope.loaded = true
		}*/

		//this is a helper to get us past a screen refresh and loosing user persistence
		//we're not storing user data in cookies or on the web server in a session
		$scope.getCurrentUser = function () {
			if (!$scope.currentUser) {
				//var token = $cookies.get('token')
				//if(token) {
				$http.post('/api/user/check_auth').then(function (res) {
					Session.destroy()
					res.data.user.userRole = 'user'
					Session.create(res.data.user)
					$scope.setCurrentUser(res.data.user)
					//$scope.deferredResource.promise
				})
				//}
			}
		}

		//$scope.getCurrentUser()

		//$state.go('onboarding.calc');

		$scope.data = {
			override_intercom_css: ((organization_data || {}).intercom || {}).override_css,
			intercom_open: false
		}

		//app module data
		$scope.modules = {
			modals: {
				auth: {
					open: false,
					locked: false,
					default_form: 1,
					show_signup: organization_data.join_data.auth.sign_up,
					show_referral_source: organization_data.join_data.auth.referral_source,
					show_company_signup: organization_data.join_data.auth.company,
					show_language_preference: organization_data.join_data.auth.language_preference
				}
			}
		}

		$rootScope.$on('AuthModalUpdate', function (e, data) {

			if (data.open)
				$scope.modules.modals.auth.open = data.open;
			if (data.locked)
				$scope.modules.modals.auth.locked = data.locked;
			if (data.default_form)
				$scope.modules.modals.auth.default_form = data.default_form;

		});

		$rootScope.$on(NWUI_AUTH_EVENTS.authDone, function () {

			$scope.user = {
				id: NwuiSession.user.id,
				first_name: NwuiSession.user.first_name,
				last_name: NwuiSession.user.last_name,
				email: NwuiSession.user.email,
				role_id: NwuiSession.user.role_id,
				client_id: NwuiSession.user.client_id,
				organization_id: NwuiSession.user.organization_id || 1,
				sub_org_slug: (NwuiSession.user.org) ? NwuiSession.user.org.slug : undefined
			}

			if ($scope.user.sub_org_slug && $scope.user.sub_org_slug !== org_slug)
				$scope.brand = 'brand-' + $scope.user.sub_org_slug;
			else
				$scope.brand = '';

			UserDataService.setUserData($scope.user);

			bootIntercom($scope.user);

			if (NwuiSession.user.org) {
				setOrganizationData(NwuiSession.user.org);
			} else if (NwuiSession.user && NwuiSession.user.id) {
				$http.get('/api/user/' + NwuiSession.user.id + '/organization').then(function (res) {
					setOrganizationData(res.data);
				});
			}
		});

		$rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, function () {
			$state.go('login');
			$scope.modules.modals.auth.default_form = 1;
			$timeout(function () {
				bootIntercom();
				$scope.modules.modals.auth.open = true;
				$scope.modules.modals.auth.locked = true;
				$scope.brand = '';
			}, 250);
		});

		$rootScope.$on(NWUI_AUTH_EVENTS.logoutSuccess, function () {
			$rootScope.returnToState = null;
			$rootScope.returnToParams = null;
			$scope.modules.modals.auth.default_form = 1;
			$timeout(function () {
				bootIntercom();
				$scope.modules.modals.auth.open = true;
				$scope.modules.modals.auth.locked = true;
				$scope.brand = '';
			}, 250);
		});

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function (event, opts) {
			NwuiLocalizeService.getAllLangStrings().forEach(lang => locale.loadBundle(lang, true));
			NwuiLocalizeService.updateAllLangsFromAPI().take(1).subscribe(() => {
				if (window.location.pathname === '/signature_complete') {
					return;
				}

				var to_state = '';

				var redirect_url = (
					NwuiSession.userData &&
					NwuiSession.userData.user &&
					NwuiSession.userData.user.redirect_url
				) ? NwuiSession.userData.user.redirect_url : NwuiSession.user.redirect_url

				switch (redirect_url) {
					case "/status":
						to_state = 'onboarding.status';
						break
					case "/questionnaire":
						to_state = 'onboarding.question';
						break
					case "/forms":
						to_state = 'onboarding.form';
						break
					case "/signature":
						to_state = 'onboarding.signature';
						break
					case '/maintenance':
						to_state = 'onboarding.maintenance';
						break
					default:
						to_state = 'onboarding.intro';
				}

				if (to_state === 'onboarding.intro' || !opts || !opts.query_params || opts.query_params.length === 0) {
					$state.go(to_state);
					$timeout(function () {
						$scope.modules.modals.auth.open = false;
					}, 750);
				} else {
					window.location.href = redirect_url + (opts.query_params || '')
				}
			});
		});
	}
]);
