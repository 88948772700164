appControllers.controller('CalcEditController', ['$scope', '$timeout', function($scope, $timeout) {
	
	//select box
	
		$scope.edit_choices = angular.copy($scope.choices);
		$scope.edit_choices.splice(1,1);
	
		$scope.selectVal = $scope.user.reason.val;
		$scope.editSelectOpen = false;
		
		var openEditSelect = function(e) {
			$scope.editSelectOpen = true;
			e.stopPropagation();
		}
		
		$scope.openEditSelect = openEditSelect;
		
		var closeEditSelect = function() {
			$scope.editSelectOpen = false;
		}
		
		$scope.closeEditSelect = closeEditSelect;
		
		var editSelectChoose = function(choice) {
						
		    $scope.user.reason = choice;

		    $scope.closeEditSelect();

		    $timeout(function() {
			    $scope.selectVal = $scope.user.reason.val;
		    }, 400);
			
		}
		
		$scope.editSelectChoose = editSelectChoose;
		
}])