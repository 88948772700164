appDirectives.directive('modal', ['$timeout', 'ModalService', function($timeout, ModalService) {
    return {
        restrict: "E",
        transclude: true,
        replace: true,
        scope: {
	        'id': '=modalId'
        },
        templateUrl: 'join_webserver/partials/common/modal.html',
		link: function(scope, element, attrs) {
			
			$timeout(function() {
				scope.modal = ModalService.create(scope.id);
			}, 0);
			
			scope.closeModal = function() {
				scope.modal.close();
			}
			
			scope.$watch('modal.isOpen', function(newVal) {
				
				
				$timeout(function() {
					if(newVal)
						element.find('input[name=firstName]').focus();
				}, 500);
				
			});
			
	    },
	    controller: ['$scope', function($scope) {
		    
		    //$scope.modal = ModalService.create($scope.id);
		    
	    }]
    }
}]);