appDirectives.directive('field', ['$compile', '$timeout', '$http', '$parse', 'ValidationService', 'MaskService', 'KYCAnswers', function($compile, $timeout, $http, $parse, ValidationService, MaskService, KYCAnswers) {
    
    return {
        restrict: 'E',
        scope: {
            field: '=field',
            utils: '=utils'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
			
				scope.answers = KYCAnswers
			var templates = [];
			var loaded = function() {}

			switch(scope.field.input.input_type) {
				case 'check':
					template = 'join_webserver/partials/common/inputs/check.html';
					break;
				case 'radio':
					template = 'join_webserver/partials/common/inputs/radio.html';
					break;
				case 'select':
					template = 'join_webserver/partials/common/inputs/select.html';
					break;
				case 'input':
				default:
					template = 'join_webserver/partials/common/inputs/input.html';
					break;
			}
			
			var validate = function(e_type, field) {
				
				if(field.input.mask)
					field.input.model = MaskService[field.input.mask](field.input.model, false)
					
				var res = true;
				if(e_type == 'blur')
					res = ValidationService['blank'](scope.answers[field.input.model]);
				if(res === true)
					res = ValidationService[field.input.validate](scope.answers[field.input.model]);
				
				if(res === true) { 
					field.input.valid = true;
					scope.utils.clear_error(field);
				} else {
					res.message = scope.utils.populate_message(field, res.message);
					
					field.input.valid = false;
					scope.utils.throw_error(field, res);
				}
				
				scope.utils.recalibrate();

				if(field.input.mask)
					field.input.model = MaskService[field.input.mask](field.input.model, true)
				
			}
					
			$http.get(template).then(function(res) {
				element.html($compile(res.data)(scope));
				loaded();
			});
			
			scope.is_open = false;
			scope.model_temp = (scope.field.input.model) 
				? scope.field.input.model.val || scope.field.input.placeholder 
				: scope.field.input.placeholder
			
			scope.onChange = function(field) {
				validate('change', field);
			}
			
			scope.onBlur = function(field) {
				validate('blur', field);
			}
			
			scope.open = function(e) {
				scope.is_open = true;
				e.stopPropagation();
			}
			
			scope.close = function() {
				scope.is_open = false;
			}
			
			scope.select = function(choice) {
				
				switch(scope.field.input.input_type) {
					case 'radio':
						
						scope.field.input.valid = true;
						scope.utils.clear_error(scope.field);
								
						//scope.field.input.model = choice;
						scope.answers[scope.field.input.model] = choice.id
						
						break;
					case 'check':
						
						scope.field.input.valid = true;
						scope.utils.clear_error(scope.field);
						
						scope.field.input.model = choice;
						
						break;
					case 'select':
					
						scope.field.input.valid = true;
						scope.utils.clear_error(scope.field);
					
						scope.answers[scope.field.input.model] = choice.id
						//scope.field.input.model = choice;
						scope.close();
						
						/*$timeout(function() {
							scope.model_temp = (scope.field.input.model)
								? scope.field.input.model.val
								: '';
						}, 400);*/
						
						break;
				}
				
			}
			
	    },
	    controller: ['$scope', function($scope) {
		    
	    }]
    };
}] )