appServices.service('MaskService', ['$filter', function($filter) {
	
	var self = this;
	
	var Error = function(message) {
		this.message = message || 'Something went wrong';
	}
	
	var r = {
		numeric: /[^0-9]+/g,
		alphabetic: /[^A-Z]+/g,
		alphabetic_no_case: /[^a-zA-Z]+/g,
		alphanumeric: /[^0-9a-zA-Z]+/g,
		millennium: /[^1-2]+/g,
		numeric_decimal: /[^0-9.]+/g
	}
	
	var validators = {
		lengthMatch: function(type, input) {
			return (input.length == format.mask[type].pre.length)
				? true
				: new Error('nwui.common.error.exact_match_length');
		}
	}
	
	var format = {
		mask: {
			numeric: {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			'3_digit': {
				pre: [r.numeric, r.numeric, r.numeric],
				validate: validators.lengthMatch
			},
      '7_digit': {
        pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
        validate: validators.lengthMatch
      },
			transit: {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				validate: validators.lengthMatch
			},
			age: {
				pre: [r.numeric, r.numeric]
			},
			phone: {
				pre: ["(", r.numeric, r.numeric, r.numeric, ")", " ", r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			sin: {
				pre: [r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric],
				post: null
			},
			ssn: {
				pre: [r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			date: {
				pre: [r.millennium, r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, "-", r.numeric, r.numeric],
				post: null
			},
			postal: {
				pre: [r.alphabetic_no_case, r.numeric, r.alphabetic_no_case, " ", r.numeric, r.alphabetic_no_case, r.numeric],
				post: function(input) {
					return input.toUpperCase();
				}
			},
			currency: {
				pre: ["$", r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				post: function(input) {
					return input.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
				}
			},
			percentage: {
				pre: [r.numeric, r.numeric, r.numeric],
				post: function(input) {
					return input + '%'
				}
			},
			yearandmonth: {
				pre: [r.millennium, r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric],
				post: null
			}
		},
		unmask: {
			numeric: r.numeric,
			'3_digit': r.numeric,
      '7_digit': r.numeric,
			transit: r.numeric,
			age: r.numeric,
			phone: r.numeric,
			sin: r.numeric,
			date: r.numeric,
			postal: r.alphanumeric,
			currency: r.numeric,
			percentage: r.numeric,
			percentage_decimal: r.numeric_decimal,
			yearandmonth: r.numeric
		}
	}
	
	this.validate = function(type, input) {
		if(!format.mask[type] || !format.mask[type].validate) return true;
		return format.mask[type].validate(type, input);
		
	}
	
	this.mask = function(type, input) {
		if(!input) return input;
		
		input = self.unmask(type, input);
		
		var output = "";
		var count = 0;
		
		for(var i = 0; i < format.mask[type].pre.length; i++) {
			
			if(count >= input.length) break;
			
			if(format.mask[type].pre[i] instanceof RegExp) {
				var test = input.substring(count,count+1);
				
				if(test.match(format.mask[type].pre[i]))
					break;
				
				output += test;
				
				count++;
			} else {	
				output += format.mask[type].pre[i];
			}
			
		}
		
		if(format.mask[type].post)
			return format.mask[type].post(output);
		
		return output;
		
	}
	
	this.unmask = function(type, input) {
		if(!input) return input;
		return String(input).replace(format.unmask[type], "");
	}

}]);