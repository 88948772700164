appServices.service('ModalService', [function() {
	
	//self
	var self = this;
	var observerCallbacks = [];
	
	//array of registered modal objects
	this.modals = [];
	
	//modal object
	function Modal(id) {
		this.id = id;
		
		//state
		this.isOpen = false;
		
		//open modal
		this.open = function() {
			openModal(this);
		}
		
		//close modal
		this.close = function() { 
			closeModal(this);
		}
	}
	
	/* public methods */
	
	//register an observer
	this.registerObserverCallback = function(cb) {
		observerCallbacks.push(cb);
	}
  
	//create modal object
	this.create = function(id) {
		var modal = new Modal(id);
		register(modal);
		return modal;
	}
	
	/* private methods */
	
	//call this when you know 'foo' has been changed
	var notifyObservers = function() {
		angular.forEach(observerCallbacks, function(cb) {
			cb();
		});
	}
	
	//register modal
	var register = function(modal) {
		self.modals.push(modal);
		notifyObservers();
	}
	
	//unregister modal
	var unregister = function(modal) {
		self.modals.forEach(function(obj, i) {
			if(obj == modal) {
				self.modal.splice(i, 1);
				return true;
			}
		});
	}
	
	//open a modal
	var openModal = function(modal) {
		
		self.modals.forEach(function(obj, i) {
			obj.close();
		});
		
		modal.isOpen = true;
		
	}
	
	//close a modal
    var closeModal = function(modal) {
	    modal.isOpen = false;
	}
	
}]);