appServices.service('FormInstance',["FormInstanceResource", "$http","$q",
					function(FormInstanceResource, $http, $q){
	
    var FormInstance = {}
    FormInstance.get = function(form_instance_id) { return FormInstanceResource.get({id: form_instance_id}).$promise }


	FormInstance.create = function() {
		return FormInstanceResource.save().$promise
	}


	FormInstance.getChartData = function(form_instance_id) {
        return FormInstanceResource.getChartData({id:form_instance_id}).$promise
    }
    return FormInstance

}]);