appControllers.controller('ImageUploadController',["$scope","$rootScope","$http","$cookies","$q",
    function($scope, $rootScope, $http, $cookies, $q) {
        $scope.uploadFile = function(event){
            var files = event.target.files;
            readFile(files[0] ).then(function(result){
                var file = dataURItoBlob(result)
                var uploadUrl = '/upload'
                uploadFileToUrl( file, uploadUrl, '37', function ( data ) {
                } )
            }, function(error){
            })
        };
        $scope.upload_image = function ( callback ) {
             var file      = dataURItoBlob( $scope.myCroppedImage )

        }

        function dataURItoBlob ( dataURI ) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if ( dataURI.split( ',' )[ 0 ].indexOf( 'base64' ) >= 0 )
                byteString = atob( dataURI.split( ',' )[ 1 ] ); else
                byteString = unescape( dataURI.split( ',' )[ 1 ] );

            // separate out the mime component
            var mimeString = dataURI.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array( byteString.length );
            for ( var i = 0; i < byteString.length; i++ )
            {
                ia[ i ] = byteString.charCodeAt( i );
            }
            return new Blob( [ ia ], {type : mimeString} );

        }

        function readFile(file) {
            var deferred = $q.defer();

            var reader = new FileReader();
            reader.onload = function(e) {
                deferred.resolve(e.target.result);
            };
            reader.onerror = function(e) {
                deferred.reject(e);
            };

            reader.readAsDataURL(file);

            return deferred.promise;
        }
        var uploadFileToUrl = function(file, uploadUrl, user_id, callback){
            var fd = new FormData();
            fd.append('file', file);
            fd.append('id', user_id)
            $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .then(function(res){
                    callback(res.data)
                }, function(res){
                });
        }
     }
   ])