angular.module('app.config', [
  'ui.router'
])

.config([
  "$stateProvider", 
  'NWUI_AUTH_ROLES', 
  "$locationProvider", 
  "$urlRouterProvider",
  function(
    $stateProvider, 
    NWUI_AUTH_ROLES, 
    $locationProvider, 
    $urlRouterProvider
  ) {

    var set_password = {
      url: '/set-password',
      templateUrl: 'join_webserver/set_password.html',
      controller: "SetPasswordController",
      data: {
        auth_roles: []
      }
    }
  
    var onboarding = {
      views: {
        '': {
          templateUrl: 'join_webserver/onboarding.html',
          controller: 'OnboardingController'
        },
        'content@onboarding': {
          templateUrl: 'join_webserver/partials/onboarding.content.html',
          controller: ['$scope', function($scope) {}]
        }
      },
      data: {
        auth_roles: []
      }
    }

    var processing = {
      url: '/processing',
      views: {
        "processing": {
          templateUrl: 'join_webserver/processing.html',
          controller: 'ProcessingController'
        }
      },
      data: {
        auth_roles: [NWUI_AUTH_ROLES.user]
      }
    }
  
    var image_upload = {
      url: '/image_upload',
      templateUrl:'join_webserver/image_upload.html',
      controller: "ImageUploadController",
        data: {
        auth_roles: [NWUI_AUTH_ROLES.user]
      }
    }

    var docusign = {
      url : '/docusign',
      templateUrl:'join_webserver/docusign.html',
      controller:"DocusignController",
      data: {
        auth_roles: [NWUI_AUTH_ROLES.user]
      }
    }

    var signature_complete = {
      url: '/signature_complete',
      views: {
        '':{
         templateUrl: 'join_webserver/status.html'
        },
        "status@signature_complete": {
          templateUrl: 'join_webserver/status/template.html',
          controller: 'StatusController'
        }
      },
      resolve: {
        OnboardingStatusData: ["NwuiAuthService", "$http", "NwuiSession", function(NwuiAuthService, $http, NwuiSession) {
          return NwuiAuthService.isTokenValid().then(function (){
            return $http.get('api/user/onboarding-status-detail')
          }, function(error) {})
        }],
        FormInstanceData: ["FormInstance", "NwuiAuthService", function( FormInstance, NwuiAuthService) {
          return NwuiAuthService.isTokenValid().then(function(res) {
            return FormInstance.create()
          }, function(error) {
          })
        }],
        KYCInstanceData: ["KYCInstance", "KYCService", "NwuiAuthService", function(KYCInstance, KYCService, NwuiAuthService) {
          return NwuiAuthService.isTokenValid().then(function() {
            return KYCInstance.create();
          }, function(error) {
          })
        }],
        SigningPowerData: ['$http', '$q', 'NwuiSession', function($http, $q, NwuiSession) {
          var roleName = '';
          if (NwuiSession.user.Role) {
            roleName = NwuiSession.user.Role.name;
          } else if (NwuiSession.user.role) {
            roleName = NwuiSession.user.role.name;
          }
          if (
            !organization_data.join_data.signing ||
            !organization_data.join_data.signing.check_signing_power ||
            roleName !== 'HR Rep'
          ) {
            var deferred = $q.defer();
            deferred.resolve({HasSigningPower: true});
            return deferred.promise;
          }
          return $http.post('/api/form_instance').then(function(res) {
            var form_answers = res.data.form_answers;
            return {
              HasSigningPower: form_answers.HasSigningPower === 'Yes',
              SignerEmail: form_answers.SignerEmail,
              SignerFirstName: form_answers.SignerFirstName,
              SignerLastName: form_answers.SignerLastName,
              SignerJobTitle: form_answers.SignerJobTitle
            };
          });
        }]
      },
      data: {
        auth_roles: [NWUI_AUTH_ROLES.user]
      }
    }
    
    $stateProvider
      .state('set_password', set_password)
      .state('onboarding', onboarding)
      .state('signature_complete', signature_complete)

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
      rewriteLinks: false
    });

    $urlRouterProvider.otherwise('/login');

    // $urlRouterProvider.otherwise([
    // 	"$injector", 
    // 	"$location"
    // 	function(
    // 		$injector, 
    // 		$location
    // 	) {
    // 		var query_params = '';

    // 		if($location.$$search && $location.$$search.lang) {
    // 			if(query_params.length === 0) query_params = '?';

    // 			query_params += 'lang=' + $location.$$search.lang;
    // 		}

    // 		return '/login' + query_params;
    // 	}
    // ]);

  }
])

.config([
  '$provide',
  function(
    $provide
  ) {
    $provide.decorator('$uiViewScroll', [
      '$window',
      '$timeout',
      '$delegate',
      '$anchorScroll',
      function(
        $window,
        $timeout,
        $delegate,
        $anchorScroll
      ) {

        return function(uiViewElement) {
          $('html, body').animate({scrollTop:0}, 500);
        }

      }
    ]);
  }
])

.config([
  "$httpProvider", 
  function(
    $httpProvider
  ){


    function traverseObjectAndRetrieveStrings(obj, strings) {
      if (!strings) {
        strings = [];
      }
      for (var key in obj) {
        var entry = obj[key];
        if (typeof entry === 'string') {
          strings.push(entry);
        } else if (Array.isArray(entry)) {
          entry.forEach(function(e) {
            traverseObjectAndRetrieveStrings(e, strings);
          });
        } else if (typeof entry === 'object') {
          traverseObjectAndRetrieveStrings(entry, strings);
        }
      }
      return strings;
    }

    $httpProvider.xsrfWhitelistedOrigins = traverseObjectAndRetrieveStrings(organization_data.url_bases);

    $httpProvider.interceptors.push([
      '$injector',
      function($injector){
        return $injector.get('AuthInterceptor')
      }
    ])
    
  }
]);
