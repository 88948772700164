angular.module('app.forms.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		$stateProvider.state('onboarding.form', {
			url: '/forms',
			views: {
				"form": {
					templateUrl: 'join_webserver/forms/template.html',
					controller: 'FormController'
				}
			},
			resolve: {
				FormInstanceData: ["FormInstance", "NwuiAuthService", function( FormInstance, NwuiAuthService) {
					return NwuiAuthService.isTokenValid().then(function(res) {
						return FormInstance.create()
					}, function(error) {
					})
				}],
				KYCInstanceData: ["KYCInstance", "KYCService", "NwuiAuthService", function(KYCInstance, KYCService, NwuiAuthService) {
					return NwuiAuthService.isTokenValid().then(function() {
						return KYCInstance.create();
					}, function(error) {
					})
				}]
			},
			data: {
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		});

	}
]);