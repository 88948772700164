angular.module('app.forms.modals.directives', [])

.directive('joinFormsModals', [
	'$timeout',
	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	function(
		$timeout,
		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'join_webserver/forms/modals/template.html',
			scope: {
				tax_benefits_modal_open: '=taxBenefitsModalOpen'
			},
			link: function(scope, element, attrs) {

				scope.organization_data = organization_data;
				
				scope.modals = {
					alt_tax_benefits_modal: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: {
							name_i18n: 'forms.modals.tax_benefits.title',
							icon: {
								name: 'info'
							}
						},
						copy: {
							buttons: {
								yes: new NwuiButtonV3Factory({
									id: 'provicial-selection-modal-yes',
									text_i18n: 'forms.modals.tax_benefits.button_yes',
									stretch: true,
									icon: {
										name: 'check'
									},
									onClick: function() {
										scope.tax_benefits_modal_open = false;
									}
								})
							}
						}
					})	
				}

				scope.$watch('tax_benefits_modal_open', function(new_val, old_val) {
					if(new_val === undefined) return;
					scope.modals.alt_tax_benefits_modal.open = new_val;
				});

			}
		}
	}
]);