function getSteps(stepOptions) {
  var {
    $scope,
    $state,
    user_status,
    isInviteAfterImpersonation,
    NwuiSession,
    FormInstanceData,
    User,
    redirect_signed,
    isClientSelfBuilt,
    domains,
    jump_to_port,
    SigningPowerData,
    jump_to_admin,
    getSignatures,
    client
  } = stepOptions

  var validateEmail = (client || NwuiSession.user.Role.name == 'HR Rep') && !user_status.validated_email
    && !organization_data.advisor_led_onboarding;

  var workPlatform = org_slug === 'nestwork' || org_slug === 'nbfwork' || org_slug === 'demowork';
  var nwwWeb = domains.nww_web;
  var adminDashboard = domains.admin_web + '/dashboard';
  var portSummary = domains.port_web + '/summary';

  var steps = {
    account_created: {
      title: 'status.step.account_created.title',
      text: '',
      status: true
    },
    emailSentAdvisor: {
      title: 'status.step.email_send_advisor.title',
      text: 'status.step.email_send_advisor.text',
      status: true
    },
    personal: {
      title: 'status.step.personal.title',
      text: '',
      status: true
    },
    kyc: {
      title: 'status.step.questionnaire.title',
      text: 'status.step.questionnaire.text',
      button: {
        text_i18n: 'status.step.questionnaire.button',
        func: function () {
          $scope.processing = true;
          $state.go('onboarding.question');
        }
      },
      status: NwuiSession.userData.user && NwuiSession.userData.user.use_rpq === true ? true : user_status.kyc_complete,
    },
    forms: {
      title: (isInviteAfterImpersonation() && NwuiSession.isAdvisorImpersonating())
        ? 'status.step.forms.invite.title'
        : 'status.step.forms.title',
      text: 'status.step.forms.text',
      button: (isInviteAfterImpersonation() && NwuiSession.isAdvisorImpersonating())
        ? {
          text_i18n: 'status.step.forms.invite.button',
          func: function () {
            if ($scope.processing) {
              return true;
            }

            $scope.processing = true;
            $scope.complete = false;

            var credentials = {
              email: FormInstanceData.form_answers.AHEmail
            };

            User.revalidate(credentials)
              .then(function () {
                $scope.processing = false;
                $scope.complete = true;
                $scope.error = false;
                $scope.message = 'signature.validation.step_1.success';
              }, function () {
                $scope.processing = false;
                $scope.complete = true;
                $scope.error = true;
                $scope.message = 'status.step.validation.sent_error';
              });
          }
        }
        : {
          text_i18n: 'status.step.forms.button',
          func: function () {
            $scope.processing = true;
            $state.go('onboarding.form');
          }
        },
      status: (NwuiSession.user.Role.name !== 'Client' && user_status.forms_complete)
        || ((NwuiSession.userData.user && NwuiSession.userData.user.use_rpq === true ? true : user_status.kyc_complete) && (user_status.forms_complete || !user_status.signing_incomplete))
    },
    signing_incomplete: {
      title: (isInviteAfterImpersonation() && NwuiSession.isAdvisorImpersonating())
        ? 'status.step.signing.invite.title'
        : (user_status.signing_incomplete && is_docusign_callback !== true) || !redirect_signed
          ? 'status.step.signing.pending.title'
          : 'status.step.signing.complete.title',
      text: (isInviteAfterImpersonation() && NwuiSession.isAdvisorImpersonating())
        ? 'status.step.signing.invite.text'
        : (user_status.signing_incomplete && is_docusign_callback !== true) || !redirect_signed
          ? (NwuiSession.user.Role.name === 'Client')
            ? 'status.step.signing.pending.text'
            : 'status.step.signing.pending.text_admin'
          : 'status.step.signing.complete.text',
      status: !user_status.signing_incomplete && $scope.inPerson.model === 'yes',
      loading: !user_status.signing_incomplete || (is_docusign_callback === true && redirect_signed)
        ? {
          color: '',
          active: true,
          determinate: false
        }
        : undefined,
      button: isInviteAfterImpersonation()
        && NwuiSession.isAdvisorImpersonating()
        && !($scope.inPerson.model === 'yes')
        ? {
          id: 'status-invite-client',
          text_i18n: 'status.step.signing.invite.button',
          func: function () {
            if ($scope.processing) {
              return true;
            }

            $scope.processing = true;
            $scope.complete = false;

            var credentials = {
              email: FormInstanceData.form_answers.AHEmail
            };

            User.revalidate(credentials)
              .then(function () {
                $scope.processing = false;
                $scope.complete = true;
                $scope.error = false;
                $scope.message = 'signature.validation.step_1.success';
              }, function () {
                $scope.processing = false;
                $scope.complete = true;
                $scope.error = true;
                $scope.message = 'status.step.validation.sent_error';
              });
          }
        }
        : (!user_status.signing_incomplete || (is_docusign_callback === true && redirect_signed)
          ? {
            id: 'status-signature-button',
            text_i18n: 'status.step.signing.complete.button',
            color: organization_data.theme.form.submit.warning_color,
            size: 'small',
            func: function () {
              $scope.processing = true;
              $state.reload();
            }
          }
          : {
            id: 'status-signature-button',
            text_i18n: 'status.step.signing.pending.button',
            icon: {
              name: 'gesture'
            },
            func: function () {
              var paramsObject = {};
              if (!redirect_signed) {
                window.top.location.reload();
              }
              else {
                $scope.processing = true;
                if ($scope.inPerson.enabled)
                  paramsObject = { client_present: $scope.inPerson.model === 'yes' };
                $state.go('onboarding.signature', paramsObject);
              }
            }
          }
        )
    },
    pending_custodian: {
      title: (isInviteAfterImpersonation() && NwuiSession.isAdvisorImpersonating())
        ? 'status.step.custodian.invite.title'
        : isClientSelfBuilt
          ? 'status.step.custodian.title_self_built'
          : 'status.step.custodian.title',
      text: 'status.step.custodian.text',
      status: user_status.pending_custodian
    },
    admin_jump: {
      title: 'status.step.admin_jump.title',
      text: '',
      button: is_docusign_callback === true
        ? undefined
        : {
          text_i18n: 'status.step.admin_jump.button',
          func: function () {
            $scope.processing = true;
            if (workPlatform) {
              window.top.location.href = nwwWeb;
            }
            else {
              window.top.location.href = adminDashboard;
            }
          }
        },
      status: false
    },
    port_jump: {
      title: 'status.step.port_jump.title',
      text: '',
      button: is_docusign_callback === true
        ? undefined
        : {
          text_i18n: 'status.step.port_jump.button',
          func: function () {
            $scope.processing = true;
            if (workPlatform) {
              window.top.location.href = nwwWeb;
            }
            else {
              window.top.location.href = portSummary;
            }
          }
        },
      status: (jump_to_port === false)
    },
    funding: {
      title: isInviteAfterImpersonation() && NwuiSession.isAdvisorImpersonating()
        ? 'status.step.funding.invite.title'
        : 'status.step.funding.title',
      text: 'status.step.funding.text',
      status: user_status.is_funded
    },
    validate_email: {
      title: 'status.step.validation.title',
      text: 'status.step.validation.text',
      button: {
        text_i18n: 'status.step.validation.button',
        func: function () {

          if ($scope.processing) {
            return true;
          }

          $scope.processing = true;
          $scope.complete = false;

          var credentials = {
            email: NwuiSession.user.email
          };

          User.revalidate(credentials)
            .then(function () {
              $scope.processing = false;
              $scope.complete = true;
              $scope.error = false;
              $scope.message = 'status.step.validation.sent_success';
            }, function () {
              $scope.processing = false;
              $scope.complete = true;
              $scope.error = true;
              $scope.message = 'status.step.validation.sent_error';
            });
        }
      },
      success: '',
      status: user_status.validated_email
    },
    integration_errors: {
      title: 'status.step.integration_error.title',
      text: 'status.step.integration_error.text',
      status: false
    },
    no_signing_power: {
      title: user_status.validated_email
        ? 'status.step.no_signing_power.title'
        : 'status.step.no_signing_power.title_no_valid_email',
      text: 'status.step.no_signing_power.text',
      data: {
        name: SigningPowerData.SignerFirstName + ' ' + SigningPowerData.SignerLastName,
        email: SigningPowerData.SignerEmail
      },
      button: {
        text_i18n: 'status.step.no_signing_power.button',
        func: function () {
          $scope.processing = true;
          $state.go('onboarding.form.step', { step_index: 1 });
        }
      }
    },

    // fix for vpic 
    sign_button_client_is_present: {
      title: 'status.step.forms.invite.title',
      text: 'status.step.forms.invite.text',
      status: (NwuiSession.user.Role.name !== 'Client' && user_status.forms_complete)
        || (user_status.kyc_complete && (user_status.forms_complete || !user_status.signing_incomplete)),
      button: {
        id: 'status-signature-button',
        text_i18n: 'status.step.signing.pending.button',
        icon: {
          name: 'gesture'
        },
        func: function () {
          var paramsObject = {};
          if (!redirect_signed) {
            window.top.location.reload();
          }
          else {
            $scope.processing = true;
            if ($scope.inPerson.enabled)
              paramsObject = { client_present: $scope.inPerson.model === 'yes' };
            $state.go('onboarding.signature', paramsObject);
          }
        }
      }
    }
  };

  $scope.steps = [];

  //logic to add specific steps
  if (client) {
    $scope.steps.push(steps.personal);
    $scope.steps.push(steps.kyc);
  }
  else {
    $scope.steps.push(steps.account_created);
  }

  if (organization_data.hideInviteClientButton) {
    $scope.steps.push(steps.emailSentAdvisor);
  }

  if (!(organization_data.join_data.forms && organization_data.join_data.forms.show === false)) {
    if (!organization_data.hideInviteClientButton && $scope.inPerson.model === 'no') {
      $scope.steps.push(steps.forms);
    }
  }

  if ($scope.inPerson.model === 'yes' && organization_data.is_client_present === true) {
    $scope.steps.push(steps.sign_button_client_is_present);
  }

  if (validateEmail) {
    $scope.steps.push(steps.validate_email);
  }

  //if no signatures show the processing step
  if (user_status.signing_incomplete && !organization_data.hideInviteClientButton) {
    if (SigningPowerData.HasSigningPower) {
      $scope.steps.push(steps.signing_incomplete);
    }
    else {
      $scope.steps.push(steps.no_signing_power);
    }

    if (user_status.integration_errors) {
      $scope.steps.push(steps.integration_errors);
    }

    if (jump_to_port) {
      $scope.steps.push(steps.port_jump);
    }
    else if (jump_to_admin) {
      $scope.steps.push(steps.admin_jump);
    }
    else {
      $scope.steps.push(steps.pending_custodian);
      $scope.steps.push(steps.funding);
    }
  }
  else {
    //otherwise show each signature and the stage
    getSignatures().then(function (signers) {
      $scope.steps = $scope.steps.concat(signers);

      if (user_status.integration_errors) {
        $scope.steps.push(steps.integration_errors);
      }

      if (jump_to_port) {
        $scope.steps.push(steps.port_jump);
      }
      else if (jump_to_admin) {
        $scope.steps.push(steps.admin_jump);
      }
      else {
        $scope.steps.push(steps.pending_custodian);
        $scope.steps.push(steps.funding);
      }
    });
  };

  return $scope.steps;
}
