angular.module('app.forms.step.config', [])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		$stateProvider.state('onboarding.form.step', {
			url: '/step/:step_index',
			views: {
				"step": {
					templateUrl: 'join_webserver/forms/step/template.html',
					controller: 'FormStepController'
				}
			},
			params: {
				step_index: '100'
			},
			resolve: {},
			data: {
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		})

	}
]);