appServices.service('BankVerificationService', [
  '$http',
  function(
    $http
  ) {
    this.getBankDetails = function(provider, access_token, login_id) {
      return $http.post('/api/users/bank-verification/' + provider + '/accounts', {
        access_token: access_token,
        login_id: login_id
      });
    };

    this.getWidgetDetails = function(provider, access_token, language) {
      return $http.post('/api/users/bank-verification/' + provider + '/widget', {
        access_token: access_token,
        language: language
      });
    };
  }
]);