appDirectives.directive('calcchart', ['$window', 'locale','$filter', function($window,locale, $filter) {
	
	;(function($) {
		
		var graph_number = 0;
		var supports_orientation_change = 'onorientationchange' in window;
		var resize_timeouts = Array();
		
		/* private functions */
		var helpers = {
		
			compute_control_points: function(K) {
			
				var p1=new Array();
				var p2=new Array();
				var n = K.length-1;
				var m = undefined;

				/*rhs vector*/
				var a=new Array();
				var b=new Array();
				var c=new Array();
				var r=new Array();
				
				/*left most segment*/
				a[0]=0;
				b[0]=2;
				c[0]=1;
				r[0] = K[0]+2*K[1];
				
				/*internal segments*/
				for (var i = 1; i < n - 1; i++)
				{
					a[i]=1;
					b[i]=4;
					c[i]=1;
					r[i] = 4 * K[i] + 2 * K[i+1];
				}
						
				/*right segment*/
				a[n-1]=2;
				b[n-1]=7;
				c[n-1]=0;
				r[n-1] = 8*K[n-1]+K[n];
				
				/*solves Ax=b with the Thomas algorithm (from Wikipedia)*/
				for (var i = 1; i < n; i++)
				{
					m = a[i]/b[i-1];
					b[i] = b[i] - m * c[i - 1];
					r[i] = r[i] - m*r[i-1];
				}
			 
				p1[n-1] = r[n-1]/b[n-1];
				for (i = n - 2; i >= 0; --i)
					p1[i] = (r[i] - c[i] * p1[i+1]) / b[i];
					
				/*we have p1, now compute p2*/
				for (var i=0;i<n-1;i++)
					p2[i]=2*K[i+1]-p1[i+1];
				
				p2[n-1]=0.5*(K[n]+p1[n-1]);
				
				return {p1:p1, p2:p2};
				
			},
			
			scale_data: function(settings) {
	    		
	    		var x_spacing_multiplier = helpers.get_spacing_px(settings);
	    		
	    		for(var i = 0; i < settings.lines.length; i++) {
					
					for(var j = 0; j < settings.lines[0].data.length; j++) {

						settings.lines[i].data[j].y 
							= settings.lines[i].data[j].y / (settings.globals.max_y - settings.globals.min_y) * settings.canvas.height;
							
						settings.lines[i].data[j].x = x_spacing_multiplier * j;
						
					}
					
				}
				
				return settings;
			
			},
			
			invert_y_data: function(settings) {
	
	    		for(var i = 0; i < settings.lines.length; i++) {
					
					for(var j = 0; j < settings.lines[i].data.length; j++) {
	
						settings.lines[i].data[j].y = settings.globals.max_y - settings.lines[i].data[j].y;
						
					}
					
				}
				
				return settings;
	    	
	    	},
			
			get_spacing_px: function(settings) {
			
				return settings.canvas.width / (settings.lines[0].data.length-1);
			
			},
			
			get_min_y: function(settings) {
				
				if(settings.min_y) {
					
					return settings.min_y;
					
				} else {
					
					var min_y = 0;
					
					for(var i = 0; i < settings.lines.length; i++) {
					
						for(var j = 0; j < settings.lines[i].data.length; j++) {
							min_y = (settings.lines[i].data[j].y < min_y) ? settings.lines[i].data[j].y : min_y;
						}
					
					}
					
					var guide_interval = settings.globals.max_y * -0.5;
					
					if(Math.ceil(min_y / guide_interval) * guide_interval < 0) 
						return 0;
					else
						return Math.ceil(min_y / guide_interval) * guide_interval;
					
				}
				
			},
			
			get_max_y: function(settings) {
				
				if(settings.max_y) {
					
					return settings.max_y;
					
				} else {
	
					var max_y = 1;
					
					for(var i = 0; i < settings.lines.length; i++) {
					
						for(var j = 0; j < settings.lines[i].data.length; j++) {
							max_y = (parseFloat(settings.lines[i].data[j].y) > max_y) ? parseFloat(settings.lines[i].data[j].y) : max_y;
						}
					
					}
					
					return Math.ceil(max_y * (settings.pad_data_percent + 1) * 10)/10;
					
				}
				
			},
			
			draw_guides: function(settings) {
				
				if(!settings.guides.enabled) return true;
				
				
				var guides = undefined;
				var values = [];
				var line_max = [];
				//var num_of_guides = 2;
				
				for(var i = 0; i < settings.lines.length; i++) {
					
					line_max[i] = {
						index: 0,
						value: settings.lines[i].data[0].y
					}
					
					for(var j = 0; j < settings.lines[i].data.length; j++) {
						
						if(settings.lines[i].data[j].y < line_max[i].value) {
							line_max[i].index = j;
							line_max[i].value = settings.lines[i].data[j].y;
						}
						
					}
					
					values[i] = Math.ceil((((settings.canvas.height/((settings.globals.max_y - settings.globals.min_y) / settings.globals.max_y)) - line_max[i].value)/(settings.canvas.height/((settings.globals.max_y - settings.globals.min_y) / settings.globals.max_y))) * settings.globals.max_y);
					
					var val;
					if(settings.advantage.type == 'value') {
						val = $filter("currency")(values[i]);
					} else if(settings.advantage.type == 'value_year') {
						var perc = values[0]/settings.globals.max_y;
						val = $filter("currency")(settings.lines[i].draw_target * perc);
						val += "<span class = 'value-year'>" + settings.advantage.interval + "</span>";
					}
		
					var shrink_class = (settings.is_close) ? ' shrink' : '';
					
					var guide_outer = $('<div />', {
						'class': 'chart-guide' + shrink_class,
						style: "position: absolute; top: " + line_max[i].value + "px;"
					});
					
					var guide_inner = $('<div />', {
						'class': 'inner'
					});

					var guide = $('<div />', {
						'class': 'line'
					});

					var amount = $('<sup />');
					var title = $('<span />').html(val);
				
					if(settings.show_mutual_fund_compare !== false || i === 0) {
						$(guide_inner).append(guide);
						$(guide_inner).append(amount);
						$(guide_inner).append(title);
						$(guide_outer).append(guide_inner);
						$(settings.globals.inner_obj).append(guide_outer);
					}	
				}

				
				if(settings.advantage.type == 'value') {
					var diff = parseInt(values[0] - values[1], 10);
					diff = diff.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
				}
				else if(settings.advantage.type == 'value_year') {
					var perc = values[0]/settings.globals.max_y;
					var diff = parseInt((settings.lines[0].draw_target - settings.lines[1].draw_target) * perc, 10);
					diff = diff.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
					diff += "<span class = 'value-year'>" + settings.advantage.interval + "</span>";
				} else if(settings.advantage.type == 'draw') {
					
					var diff = "";
					if(settings.raw.comp) {
						diff = parseInt(settings.raw.nest.data[settings.raw.nest.data.length-1] - settings.raw.comp.data[settings.raw.comp.data.length-1], 10);
						
						for(var i = 0; i < settings.raw.comp.data.length-1; i++) {
							if(settings.raw.comp.data[i] < 0) {
								var diff = parseInt(settings.raw.nest.data[i], 10);
								//$scope.calculations.extra_years = settings.raw.comp.draw.data.length-i;
								break;
							}
						}
					
					}
					
					diff = diff.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
				}
				
				$(settings.advantage.id).html(diff);
				$(settings.globals.inner_obj).append(guides);
				
			},
			
			draw_columns: function(settings) {
				
				var x_spacing_multiplier = helpers.get_spacing_px(settings);
				var col_cont_obj = $('<div />', {
					'class': 'ios_line_graph_col_cont',
					'style': 'position: absolute; top: 0; left: 0; width: ' + settings.canvas.width + 'px; height: ' + settings.canvas.height + 'px; z-index: 1;'
				});
					
				if(settings.raw.comp) {	
						
					//find segment that passes through 0
					for(var i = 0; i < settings.raw.comp.data.length-1; i++) {
						if(settings.raw.comp.data[i] < 0) {
							
							var diff = parseInt(settings.raw.nest.data[i], 10);
							diff = diff.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
							
							var x1=(i-1); y1=settings.raw.comp.data[i-1]; x2=i; y2=settings.raw.comp.data[i]; y3=0;
							var m = (y2-y1)/(x2-x1);
							var x3 = ((y3-y2)/m)+x2;
							
							
							var col = $('<div />', {
								'class': 'ios_line_graph_col chart-vert',
								'id': 'ios_line_graph_col_' + i
							}).css({
								position: 'absolute',
								top: 0,
								left: ((x3) * x_spacing_multiplier),
								width: '1px',
								height: '100%',
								opacity: 1
							}).data('ios_line_graph', {
								id: i
							}).bind('mouseenter.ios_line_graph', { 'col_num': i }, helpers.column_hover_in).bind('mouseleave.ios_line_graph', { 'col_num': i }, helpers.column_hover_out);
							
							var guide_inner = $('<div />', {
								'class': 'inner'
							});
		
							var guide = $('<div />', {
								'class': 'line'
							});
							var amount = $('<sup />');
							var title = $('<span />').html("<span class = 'value-year-pre'></span>-" + Math.round((settings.raw.comp.data.length-x3)*10)/10 + "<span class = 'value-year'>years</span>");

							//$(guide_inner).append(guide);
							$(guide_inner).append(amount);
							$(guide_inner).append(title);
							$(col).append(guide_inner);
						
							$(col_cont_obj).append(col);
							
							settings.globals.inner_obj.append(col_cont_obj);
							
							break;
						}
					}
				
				}
				
			},
			
			column_hover_in: function(e) {
				
				$('.ios_line_graph_point_' + e.data.col_num).css({
					boxShadow: '0 0 3px 0 #000'
				});
				
			},
			
			column_hover_out: function(e) {
			
				$('.ios_line_graph_point_' + e.data.col_num).css({
					boxShadow: 'none'
				});
			
			},
			
			draw_key: function(node, settings) {
				
				for(var i = 0; i < settings.lines.length; i++) {
					
					//item 
					var key_item = $('<div />', {
						'class': 'key_cont_item'
					}).css({
						float: 'left',
						margin: '0 0 0 10px'
					});
					
					//item color
					var key_item_color = $('<div />', {
						'class': 'key_cont_item_color'
					}).css({
						float: 'left',
						width: '16px',
						height: '16px',
						'border-radius': '4px',
						background: settings.lines[i].stroke.color
					});
					
					//item text
					var key_item_text = $('<div />', {
						'class': 'key_cont_item_text'
					}).css({
						'margin': '0 0 0 21px',
						'white-space': 'nowrap'
					}).html(settings.lines[i].key.name);
					
					$(key_item).append(key_item_color);
					$(key_item).append(key_item_text);
					
					$(settings.key.container_selection).append(key_item);
					
				}
			
			},
			
			draw_chart: function(node, settings) {
				
				var x_spacing_multiplier = helpers.get_spacing_px(settings);
				settings = helpers.invert_y_data(settings);
				settings = helpers.scale_data(settings);
				
				for(var i = 0; i < settings.lines.length; i++) {

					var svg_stroke_path = "";
					var svg_fill_path = "";
					var svg_cont_obj = $('<div />', {
						'class': 'svg_cont',
						'style': 'position: absolute; top: 0; left: 0; width: ' + settings.canvas.width + 'px; height: ' + settings.canvas.height + 'px; z-index: 1;'
					});
					
					svg_stroke_path += "<path d = 'M -10 " + settings.canvas.height + " L -10 " + settings.lines[i].data[0].y + " ";
					svg_fill_path += "<path d = 'M -10 " + settings.canvas.height + " L -10 " + settings.lines[i].data[0].y + " ";
					
					if(settings.lines[i].stroke.curved) {
						
						var x_array = new Array();
						var y_array = new Array();
						
						for(var j = 0; j < settings.lines[i].data.length; j++) {
						
							x_array[j] = settings.lines[i].data[j].x;
							y_array[j] = settings.lines[i].data[j].y;
							
						}
						
						var px = helpers.compute_control_points(x_array);
						var py = helpers.compute_control_points(y_array);
					
					}
					
					for(var j = 0; j < settings.lines[i].data.length; j++) {
						
						if(settings.lines[i].points.enabled) {
	
							var top = settings.lines[i].data[j].y - settings.lines[i].points.radius;
							var left = (j * x_spacing_multiplier) - settings.lines[i].points.radius;
							var point = $('<div />', {
								'class': 'ios_line_graph_point_' + j,
								style: 'position: absolute; top: ' + top + 'px; left: ' + left + 'px; width: ' + settings.lines[i].points.radius * 2 + 'px; height: ' + settings.lines[i].points.radius * 2 + 'px;'
							}).css({
								'border-radius': settings.lines[i].points.radius,
								'background': settings.lines[i].points.color
							});
	
							$(svg_cont_obj).append(point);
							
						}
						
						if(settings.lines[i].stroke.curved && (j == (settings.lines[i].data.length-1))) break;
						
						if(settings.lines[i].stroke.curved) {
						
							svg_stroke_path += "L " + x_array[j] + " " + y_array[j] + " C " + px.p1[j] + " " + py.p1[j] + " " + px.p2[j] + " " + py.p2[j] + " " + x_array[j+1] + " " + y_array[j+1] + " ";
							svg_fill_path += "L " + x_array[j] + " " + y_array[j] + " C " + px.p1[j] + " " + py.p1[j] + " " + px.p2[j] + " " + py.p2[j] + " " + x_array[j+1] + " " + y_array[j+1] + " ";
						
						} else {
						
							svg_stroke_path += "L " + (j * x_spacing_multiplier) + " " + settings.lines[i].data[j].y + " ";
							svg_fill_path += "L " + (j * x_spacing_multiplier) + " " + settings.lines[i].data[j].y + " ";	
						
						}
						
					}
					
					svg_stroke_path += "L " + ((settings.lines[0].data.length-1) * x_spacing_multiplier + 10) + " " + settings.lines[i].data[settings.lines[0].data.length-1].y + " L " + ((settings.lines[0].data.length-1) * x_spacing_multiplier + 10) + " " + settings.canvas.height + "' stroke = '" + settings.lines[i].stroke.color + "' stroke-width = '" + settings.lines[i].stroke.weight + "' fill = 'none' opacity = '" + settings.lines[i].stroke.opacity + "' />";
					
					svg_fill_path += "L " + ((settings.lines[0].data.length-1) * x_spacing_multiplier + 10) + " " + settings.lines[i].data[settings.lines[0].data.length-1].y + " L " + ((settings.lines[0].data.length-1) * x_spacing_multiplier + 10) + " " + settings.canvas.height + "' stroke = 'none' stroke-width = '" + settings.lines[i].stroke.weight + "' opacity = '" + settings.lines[i].fill.opacity + "' />";
					
					if(settings.show_mutual_fund_compare !== false || i === 0) {

						$(svg_cont_obj).append(settings.globals.svg_open + svg_fill_path + svg_stroke_path + settings.globals.svg_close);
						settings.globals.inner_obj.append(svg_cont_obj);

					}
					
				}
				
				helpers.draw_columns(settings);
				helpers.draw_guides(settings);
				
				locale.ready('calculator').then(function (res) {
					$(".slide").each(function(ind,ele){
						$(ele).find(".chart-guide").eq(0).find("sup").html(locale.getString('calculator.chart.label.main',{
							orgNameShort: window.organization_data.name_short
						}));

						$(ele).find(".chart-guide").eq(1).find("sup").html(locale.getString('calculator.chart.label.other'));
		
					});
				});
				
				
			}
		
		}
	    
	    var methods = {
			
			init: function(options, node) {
				
				if(node == undefined) {
					node = this;
				}
				
				var line_obj_default = {
					'key': {
						'name': 'key-name',
					},
					'stroke': {
						'curved': false,
						'color': '#000',
						'weight': 1,
						'opacity': 1
					},
					'fill': {
						'color': 'none',
						'opacity': 1
					},
					'points': {
						'enabled': true,
						'color': '#000',
						'radius': 4
					},
					'data': Array()
				}
				
				var settings = $.extend(true, {
					'pad_data_percent': 0.1,
					'min_y': undefined,
					'max_y': undefined,
					'globals': {
						'id': undefined,
						'obj': undefined,
						'inner_obj': undefined,
						'svg_obj': undefined,
						'svg_open': undefined,
						'svg_close': undefined,
						'min_y': undefined,
						'max_y': undefined,
						'splines': new Array(),
						'verticies': new Array()
					},
					'canvas': {
						'width': undefined,
						'height': undefined
					},
					'guides': {
						'enabled': true,
						'line': {
							'color': '#000',
							'weight': 1,
							'opacity': 0.2,
						},
						'font': {
							'family': 'inherit',
							'color': 'inherit',
							'weight': 'inherit',
							'size': 'inherit',
							'opacity': 1
						}
					},
					'key': {
						'enabled': true,
						'width': '20%',
						'container_selection': '',
						'stacked': true
					},
					'lines': Array(),
					'raw': {}
				}, options);

				
				for(var i = 0; i < settings.lines.length; i++) {
					var clone = $.extend(true, {}, line_obj_default);
					settings.lines[i] = $.extend(true, clone, options.lines[i]);
				}
				
				return $(node).each(function(i) {
					
					graph_number++;
					settings.globals.id = graph_number;
					resize_timeouts[settings.globals.id];
					settings.globals.obj = this;
					settings.globals.max_y = helpers.get_max_y(settings);
					settings.globals.min_y = helpers.get_min_y(settings);
	
					$(this).data('ios_line_graph', {
						settings: $.extend(true, {}, settings)
					});
					
					settings.globals.svg_open = "<svg xmlns = 'http://www.w3.org/2000/svg' width = '100%' height = '100%'>";
					settings.globals.svg_close = "</svg>";
					settings.canvas.width = $(node).width();
					settings.canvas.height = $(node).height();
					
					var inner_obj = $('<div />', {
						'class': 'inner'
					}).css({
						position: 'relative',
						top: 0,
						left: 0,
						//overflow: 'hidden',
						width: settings.canvas.width + 'px',
						height: settings.canvas.height + 'px'
					});
					
					$(this).append(inner_obj);
					settings.globals.inner_obj = $(this).children('.inner');
					
					$(this).attr('id', 'ios_line_graph_' + settings.globals.id);
					
					if(settings.key.enabled) helpers.draw_key(this, settings);	
				
					helpers.draw_chart(this, settings);
					
					var orientation_event = supports_orientation_change ? 'orientationchange' : 'resize';
					
					$(window).bind(orientation_event + '.ios_line_graph_-' + settings.globals.id, function(e) {
						
						var data = $(settings.globals.obj).data('ios_line_graph');
						
						if(resize_timeouts[data.settings.globals.id] != undefined) clearTimeout(resize_timeouts[data.settings.globals.id]);
						
						resize_timeouts[data.settings.globals.id] = setTimeout(function() {
							methods.update(data.settings, data.settings.globals.obj);
						}, 10);
						
					});
					
				});
				
			},
			
			update: function(settings, node) {
			
				if(node == undefined) {
					node = this;
				}
				
				return $(node).each(function() {
					
					/*var $this = $(this);
					var data = $this.data('ios_line_graph');
					if(data == undefined) return false;*/
					
					methods.destroy(this);
	
					methods.init(settings, this);
			    	
				});
				
			},
			
			destroy: function(node) {
			
				if(node == undefined) {
					node = this;
				}
				
				return $(node).each(function() {
				
					var $this = $(this);
					var data = $this.data('ios_line_graph');
					if(data == undefined) return false;
					
					$(data.settings.globals.obj).children().remove().attr('id', '');
					$(data.settings.key.container_selection).children().remove();
					$(window).unbind('ios_line_graph_' + data.settings.globals.id);
					
				});
				
			}
		
		}
		
		/* public functions */
		$.fn.iosLineGraph = function(method) {
	
			if(methods[method]) {
				return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
			} else if (typeof method === 'object' || !method) {
				return methods.init.apply(this, arguments);
			} else {
				$.error('invalid method call!');
			}
		
	    };
	
	}) (jQuery);
	
    return {
        restrict: "E",
        replace: true,
        scope: {
	        'chart': '=',
	        'show_mutual_fund_compare': '=showMutualFundCompare'
        },
        templateUrl: 'join_webserver/partials/common/calc-chart.html',
		link: function(scope, element, attrs) {
			
			var lines = [];
			var timeouts = [];
			
			angular.forEach(scope.chart.line, function(line, i) {
				
				lines[i] = line;
				lines[i].data = [];
				
				angular.forEach(line.series, function(series, j) {
					
					lines[i].data.push({
						x: j+1,
						y: series
					});
					
				});
				
			});
			
			var chart_obj = {
				min_y: scope.chart.params.min_y,
				max_y: scope.chart.params.max_y,
				is_close: false,
				advantage: {
					id: scope.chart.advantage.id,
					type: scope.chart.advantage.type
				},
				pad_data_percent: 0,
				guides: {
					enabled: true
				},
				key: {
					enabled: false
				},
				lines: lines,
				raw: scope.chart.raw,
				show_mutual_fund_compare: scope.show_mutual_fund_compare
			}
			
			var calibrate = function() {
				
				chart_obj.raw = scope.chart.raw;
				chart_obj.min_y = scope.chart.params.min_y;
				chart_obj.max_y = scope.chart.params.max_y;
				chart_obj.advantage.type = scope.chart.advantage.type;
				chart_obj.advantage.interval = scope.chart.advantage.interval;
				
				if(chart_obj.raw.comp)
					chart_obj.is_close = ((1 - (chart_obj.raw.comp.max-chart_obj.min_y)/(chart_obj.raw.nest.max-chart_obj.min_y)) * angular.element(element).height() <= 60);
				
				clearTimeouts();
				
				var old_chart_obj = $.extend(true, {}, chart_obj);
				var duration = (!scope.chart.animate) ? 1 : 1250;
				var steps = (!scope.chart.animate) ? 1 : duration/20;
				var diff = [];
				var max_diff = 0;
				var min_diff = 0;
				
				angular.forEach(scope.chart.line, function(line, i) {
					
					diff[i] = { series: [] }
					
					angular.forEach(line.series, function(series, j) {
						
						var y = (chart_obj.lines[i].data[j]) ? chart_obj.lines[i].data[j].y : old_chart_obj.lines[i].data[old_chart_obj.lines[i].data.length-1].y;
						
						diff[i].series[j] = (series - y);
						
					});
					
				});
				
				var max_diff = scope.chart.params.max_y - chart_obj.max_y;
				var min_diff = scope.chart.params.min_y - chart_obj.min_y;
				
				var perc = 0;
				
				for(var i = 1; i <= steps; i++) {
					
					var lines = [];
					var min_y = [];
					var max_y = [];
					var perc = i/steps;
					var t = i; 
						t /= steps; 
						t--;
					var ease = Math.pow(t,5) + 1;
					
					angular.forEach(scope.chart.line, function(line, j) {
						
						lines[j] = line;
						lines[j].data = [];
						
						angular.forEach(line.series, function(series, k) {
							
							var y = (old_chart_obj.lines[j].data[k]) ? old_chart_obj.lines[j].data[k].y : old_chart_obj.lines[j].data[old_chart_obj.lines[j].data.length-1].y;
							
							lines[j].data.push({
								x: k+1,
								y: y + (diff[j].series[k] * ease)
							});
							
						});
						
					});
					
					if(max_diff == scope.chart.params.max_y) {
						chart_obj.min_y = scope.chart.params.min_y;
						chart_obj.max_y = scope.chart.params.max_y;
					} else {
						chart_obj.min_y = old_chart_obj.min_y + (min_diff * ease);
						chart_obj.max_y = old_chart_obj.max_y + (max_diff * ease);
					}
					
					chart_obj.lines = lines;
			
					timeout(i, $.extend(true, {}, chart_obj), duration*perc);
				
				}
				
			}
			
			angular.element($window).bind('resize', function() {
				
				calibrate();
				
			});
			
			scope.$watch('chart.params.min_y', function(newVal) {
				calibrate();
			}, true);
			
			scope.$watch('chart.line[0].series', function(newVal) {
				calibrate();
			}, true);
			
			var clearTimeouts = function() {
				
				angular.forEach(timeouts, function(obj) {
					clearTimeout(obj);
				});
				
			}
			
			var timeout = function(i, obj, delay) {
				
				timeouts[i] = setTimeout(function() {
					$(element).iosLineGraph('update', obj);
				}, delay);
				
			}
			
	    },
	    controller: ['$scope', function($scope) {
		    
		    
		    
	    }]
    }
}]);