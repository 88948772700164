'use strict';

var localeSupported = ['en-CA'];
var localeFallbacks = {
  en: 'en-CA'
};

if (organization_data &&
  organization_data.join_data &&
  organization_data.join_data.localization) {
  if (organization_data.join_data.localization.length !== 0) {
    localeSupported = [];
    organization_data.join_data.localization.forEach(function(locale) {
      localeSupported.push(locale.language);
    });
  }
}

var app = angular.module('app', [
  'join_webserver-templates',
  'nwui-toolkit',
  'app.config',
  'app.constants',
  'app.run',

  'app.header',
  'app.footer',
  'app.login',
  'app.signup',
  'app.intro',
  'app.calculator',
  'app.maintenance',
  'app.questionnaire',
  'app.forms',
  'app.status',
  'app.signature',
  'app.iframe-loading',
  'app.remote-logging',
  'app.sso-implicit-callback',
  // LEGACY
  'appServices',
  'appFactories',
  'appDirectives',
  'appControllers',
  'appFilters',

  // LIBS
  'ui.router',
  'ngCookies',
  'ngFileUpload',
  'ngResource',
  'ngStorage',
  'ngAnimate',
  'ngLocalize',
  'ngLocalize.Config',
  'ngLocalize.InstalledLanguages',
  'tmh.dynamicLocale',
  'ngSegment'
]);

app.value(
  'intercomUser', {}
)

  .value('localeSupported', localeSupported)

  .value('localeFallbacks', localeFallbacks)

  .value('localeConf', {
    basePath: 'api/translations/lang', // lang',
    defaultLocale: 'en-CA',
    sharedDictionary: 'common',
    fileExtension: '?bundles=' + window.translationBundles.join(','),
    persistSelection: true,
    cookieName: 'nw_i18n_lang',
    observableAttrs: new RegExp('^data-(?!ng-|i18n)'),
    delimiter: '::',
    validTokens: new RegExp('^[\\w\\.-]+\\.[\\w\\s\\.-\\<\\=\\>\\!]+\\w(:.*)?$')
  })
  .config(function(tmhDynamicLocaleProvider, segmentProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('/static/lang/locale-config/{{locale}}.js');
    if (window.segmentData) {
      segmentProvider.setConfig({
        debug: window.segmentData.debug,
        apiKey: window.segmentData.id
      });
    }
  });
