appServices.service('FormAnswers', [function(){
    this.create = function(obj){
        angular.extend(this,obj)
    }
    this.destroy = function(){
        angular.extend(this, null)
    }
    this.get = function(){
        return this;
    }
}]);