angular.module('app.forms.step.controllers', [])

.controller('FormStepController', [
	'$scope',
	'$timeout',
	'$stateParams',
	'$window',
	'FormService',
	function(
		$scope,
		$timeout,
		$stateParams,
		$window,
		FormService
	) {

		var loadStep = function() {

			if(angular.equals(FormService.showing_step_ifs, {})) return;

			var zero_step_index = $stateParams.step_index-1;

			var index = 0;
			
			for(var i = 0; i < $scope.form_steps.length; i++) {
				if($scope.form_steps[i].showing) {
					index = i;
					break;
				}
			}

			FormService.set_step_by_index(index);

		}

		$scope.data = {
			step_index: $stateParams.step_index
		}

		$scope.form_steps = FormService.steps;

		$scope.$on('form-steps-loaded', function() {
			$timeout(loadStep, 0)
		});

		if(FormService.current_step) {

			if(FormService.current_step_index !== undefined) {
				FormService.set_step_by_index($stateParams.step_index-1+FormService.getSkippedStepsCount(FormService.current_step_index));
			}

		}

	}
]);