angular.module('app.calculator.controllers', [])

  .controller('CalcController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$timeout',
    '$q',
    '$sce',
    '$cookies',
    '$filter',
    '$location',
    'ModalService',
    'ChartService',
    'AuthService',
    'AUTH_EVENTS',
    'User',
    'NwuiSession',
    'NwuiButtonV3Factory',
    'InputValidationService',
    'NwuiAnalyticsService',
    'locale',
    function(
      $rootScope,
      $scope,
      $state,
      $window,
      $timeout,
      $q,
      $sce,
      $cookies,
      $filter,
      $location,
      ModalService,
      ChartService,
      AuthService,
      AUTH_EVENTS,
      User,
      NwuiSession,
      NwuiButtonV3Factory,
      InputValidationService,
      NwuiAnalyticsService,
      locale
    ) {

      var charttimeout = undefined;
      var timeout = undefined;
      var user = $cookies.getObject('user');

      if(user && user.reason.id != 1)
        $scope.user = user;
      else
        $state.go('onboarding.intro');

      $scope.data = {
        year: moment().format('YYYY'),
        org: organization_data
      }

      $scope.user.error = [];
      $scope.user.editAge = $scope.user.age;
      $scope.user.validateAge = function(age) {
          if($scope.user.error.indexOf('age') != -1){
            $scope.user.error.splice($scope.user.error.indexOf('age'), 1);
          }
          var response = InputValidationService.validateAge(age);
          if ( response === true)
            $scope.user.age = $scope.user.editAge;
          else
            $scope.user.error.push('age'); 
      };
      $scope.user.yearly_contribution = $scope.user.yearly_contribution || 15000;
      $scope.user.yearly_draw = $scope.user.yearly_draw || 60000;
      $scope.user.horizon = $scope.user.horizon || 10;

      $scope.user.editHorizon = $scope.user.horizon;
      $scope.user.validateHorizon = function(horizon) {
        var horizonIndex = $scope.user.error.indexOf('horizon');
        if(horizonIndex != -1){
          $scope.user.error.splice(horizonIndex, 1);
        }
        var response = InputValidationService.validateHorizon(horizon);
        if ( response === true)
          $scope.user.horizon = $scope.user.editHorizon;
        else
          $scope.user.error.push('horizon');
      };
      $scope.user.editPrinciple = $scope.user.principle;
      $scope.user.validatePrinciple = function(amount){
        var amountIndex = $scope.user.error.indexOf('principle');
        if(amountIndex != -1){
          $scope.user.error.splice(amountIndex, 1);
        }
        var response = InputValidationService.validateAmount(amount);
        if(response === true)
          $scope.user.principle = $scope.user.editPrinciple;
        else
          $scope.user.error.push('principle');
      }
      $scope.user.editYearlyContribution = $scope.user.yearly_contribution;
      $scope.user.validateYearlyContribution = function(contribution){
        var contributionIndex = $scope.user.error.indexOf('contribution');
        if(contributionIndex != -1){
          $scope.user.error.splice(contributionIndex, 1);
        }
        var response = InputValidationService.validateAmount(contribution);
        if(response === true)
          $scope.user.yearly_contribution = $scope.user.editYearlyContribution;
        else
          $scope.user.error.push('contribution');
      }
      $timeout(function() {
        $scope.calcLoaded = true;
      }, 100);

      $scope.showState = '';
      $scope.showMessage = false;
      $scope.show_chart_draw = false;

      $scope.edit = {
        horizon: $scope.user.horizon
      }

      $scope.buttons = {
        sign_up: new NwuiButtonV3Factory({
          id: 'calc-sign-up-button',
          color: 'secondary',
          text_i18n: 'calculator.main.button.text',
          onClick: function() {
            $scope.getStarted();
          }
        })
      }

      var isFirstRun = true;

      $scope.chartA1 = false;
      $scope.chartA2 = false;
      $scope.chartA3 = false;
      $scope.chartA4 = false;

      $scope.error = {
        message: '',
        inputs: []
      }

      $scope.successMessage = {
        p: [$sce.trustAsHtml("Lorem ipsum dolor sit amet, consectetur <span class = 'text-green'>$44,528</span> elit. Donec id odio vitae tortor tempus suscipit. Integer lacinia ac leo ut malesuada."), $sce.trustAsHtml("You're all set! Let's get started.")],
        button: {
          event: undefined,
          text: "Start Questionnaire",
          showDismiss: true
        }
      }

      var formulas = {
        nper: function(rate, payment, present, future, type) {
          // Initialize type
          var type = (typeof type === 'undefined') ? 0 : type;

          // Initialize future value
          var future = (typeof future === 'undefined') ? 0 : future;

          // Evaluate rate and periods (TODO: replace with secure expression evaluator)
          //rate = eval(rate);

          // Return number of periods
          var num = payment * (1 + rate * type) - future * rate;
          var den = (present * rate + payment * (1 + rate * type));
          return Math.log(num / den) / Math.log(1 + rate);
        },
        pmt: function(rate, nper, pv, fv, type) {
          if (!fv) fv = 0;
          if (!type) type = 0;

          if (rate == 0) return -(pv + fv)/nper;

          var pvif = Math.pow(1 + rate, nper);
          var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

          if (type == 1) {
            pmt /= (1 + rate);
          };

          return pmt;
        }
      }

      $scope.calculations = {
        age: $scope.user.age,
        target_retirement: $scope.user.age + $scope.user.horizon,
        retirement_end: 90,
        advantage: 0,
        flavor: 44528,
        chart: {
          raw: {},
          animate: true,
          advantage: {
            id: '.jq-advantage-1',
            type: 'value'
          },
          params: {
            min_y: 0,
            max_y: 0,
            draw_target: 0,
            is_close: 0
          },
          line: [{
            stroke: {
              curved: true,
              weight: 0
            },
            fill: {
              color: '#7ACCA0',
              opacity: 0.5
            },
            points: {
              enabled: false
            },
            guides: {
              title: ''
            },
            series: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          }, {
            stroke: {
              weight: 0
            },
            fill: {
              color: '#7ACCA0',
              opacity: 0.8
            },
            points: {
              enabled: false
            },
            guides: {
              title: ''
            },
            series: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          }]
        },
        retirement_chart: {
          raw: {},
          animate: true,
          params: {
            min_y: 0,
            max_y: 0,
            draw_target: 0,
            is_close: 0
          },
          advantage: {
            id: '#jq-advantage-2',
            type: 'value_year',
            interval: ''
          },
          line: [{
            stroke: {
              curved: true,
              weight: 0
            },
            fill: {
              color: '#7ACCA0',
              opacity: 0.5
            },
            points: {
              enabled: false
            },
            guides: {
              title: ''
            },
            series: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          }, {
            stroke: {
              weight: 0
            },
            fill: {
              color: '#7ACCA0',
              opacity: 0.8
            },
            points: {
              enabled: false
            },
            guides: {
              title: ''
            },
            series: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          }]
        }
      }

      locale.ready('calculator.chart.label').then(function() {
        $scope.calculations.chart.line[0].guides.title = $scope.calculations.retirement_chart.line[0].guides.title = locale.getString('calculator.chart.label.main', {
          orgNameShort: organization_data.name_short
        });

        $scope.calculations.chart.line[1].guides.title = $scope.calculations.retirement_chart.line[1].guides.title = locale.getString('calculator.chart.label.other');
      });

      locale.ready('calculator.chart.draw.key').then(function() {
        $scope.calculations.retirement_chart.advantage.interval = locale.getString('calculator.chart.draw.key.interval');
      });

      $scope.editInfoOpen = false;

      $scope.openEditInfo = function() {
        $scope.editInfoOpen = true;
      }

      $scope.closeEditInfo = function() {
        $scope.editInfoOpen = false;
      }

      //observer callbacks
      var updateModals = function() {
        $timeout(function() {
          if(ModalService.modals.length > 0)
            ModalService.modals[ModalService.modals.length-1].open();
        }, 1000);

      }

      //register observer

      var openCreateAccountModal = function() {

        NwuiSession.create($scope.user);
        NwuiSession.user.principle = parseInt(String(NwuiSession.user.principle).replace(/,/g, '')) || 0;
        NwuiSession.user.yearly_contribution = parseInt(String(NwuiSession.user.yearly_contribution).replace(/,/g, '')) || 0;

        $rootScope.$emit('AuthModalUpdate', { open: true, locked: false, default_form: 2 });
        return;
      }

      $scope.openCreateAccountModal = openCreateAccountModal;

      var getStarted = function() {

        var token = $cookies.get('token')
        if(!token) {
          openCreateAccountModal();
        } else {
          $scope.calcCompleted = true;
          $timeout(function() {
            $state.go('onboarding.question');
          }, 500);
        }

      }

      $scope.getStarted = getStarted;

      $scope.messageClose = function() {

        if($scope.successMessage.button.event)
          $scope.successMessage.button.event();

      }

      var changeState = function(state) {

        $scope.showState = state;

      }

      $scope.changeState = changeState;

      var changeResetState = function(show) {

        $scope.showRevalidate = show;

      }

      $scope.changeResetState = changeResetState;

      $scope.updatePhrase = function() {

        var phrases = [];
        var disclosures = [];

        var unit = $scope.calculations.units = ($scope.calculations.growth_diff != 1) ? 'years' : 'year';

        var success = '';
        switch($scope.user.reason.id) {
          case 0:
            success = "This could add up to <span class = 'text-green'>$" + $scope.calculations.advantage + "</span> by the time you retire. Taking the next 10 minutes to create an account could give you a much more comfortable retirement. Is it time to start thinking about where to take your first retirement vacation?";
            break;
          case 1:
            success = "This could add up to <span class = 'text-green'>$" + $scope.calculations.advantage + "</span> for you to spend after you retire. Taking the next 10 minutes to create an account could help you make your savings last longer. Or maybe you can think about travelling more.";
            break;
          case 2:
            success = "This could add up to an extra <span class = 'text-green'>$" + $scope.calculations.advantage + "</span> for you to buy a home. Taking the next 10 minutes to create an account could help you buy a bigger home, or pay a mortgage faster. Maybe you’ll be able to afford that house with a garage after all.";
            break;
          case 3:
            success = "Changing to low cost ETFs to could stop these high fees and add an extra <span class = 'text-green'>$" + $scope.calculations.advantage + "</span> to help put your child through school and even help them graduate debt free. Is it time to start looking at schools?";
            break;
        }

        $scope.success = $sce.trustAsHtml(success);

        $scope.selected_phrase = ($scope.user.reason.id == 0)
          ? ($scope.show_chart_draw)
            ? $scope.user.reason.id+1
            : $scope.user.reason.id
          : $scope.user.reason.id+1;
      }

      var calculateChart = function() {

        if (typeof $scope.user.age === 'undefined' || isNaN($scope.user.horizon))
          return;
        var p = ($scope.user.principle === parseInt($scope.user.principle, 10)) ? $scope.user.principle : parseInt($scope.user.principle.replace(/[^0-9]/g, '').trim(), 10);
        var c = parseInt(String($scope.user.yearly_contribution).replace(/[^0-9]/g, '').trim(), 10);
        var t = parseInt($scope.user.horizon, 10)
        var d = parseInt(String($scope.user.yearly_draw).replace(/[^0-9]/g, '').trim(), 10);
        var t2 = ($scope.user.reason.id == 0)
          ? 90 - $scope.user.age - t
          : 90 - $scope.user.age;
        var retirement_competitor_arr = [];
        var competitor_arr = [];
        var retirement_nest_arr = [];
        var nest_arr = [];
        var max_y = [0,0];
        var a = $scope.user.age;

        var nest = ChartService.nestCalc(p,c,a,t,d);
        var comp = ChartService.compCalc(p,c,a,t,d);

        var trim_to = 0;

        //trim
        for(var i = nest.draw.data.length-1; i > 0; i--) {
          if(nest.draw.data[i-1] > 0)
            break;

          trim_to++;
        }

        nest.draw.data.splice(nest.draw.data.length-trim_to, trim_to);
        comp.draw.data.splice(comp.draw.data.length-trim_to, trim_to);

        //calc
        var adv_at_comp = parseInt(nest.draw.data[nest.draw.data.length-1] - comp.draw.data[comp.draw.data.length-1], 10);
        $scope.calculations.retirement_duration = false;
        for(var i = 0; i < comp.draw.data.length-1; i++) {
          if(comp.draw.data[i] < 0) {
            c = nest.draw.data[i];
            $scope.calculations.extra_years = comp.draw.data.length-i;
            $scope.calculations.retirement_duration = true;
            break;
          }
        }

        $scope.calculations.retirement_end = ($scope.user.reason.id == 1)
          ? 90 - trim_to
          : 90;

        var selected_draw = ($scope.user.reason.id == 0) ? 'drawTarget' : 'draw';

        $scope.calculations.chart.raw = {
          nest: nest.growth,
          comp: comp.growth
        }

        $scope.calculations.retirement_chart.raw = {
          nest: nest[selected_draw],
          comp: comp[selected_draw]
        }

        $scope.calculations.retirement_chart.params.is_close = (comp.growth.max/nest.growth.max > 0.9);

        $scope.calculations.chart.params.max_y = nest.growth.max;
        $scope.calculations.retirement_chart.params.max_y = nest[selected_draw].max;

        $scope.calculations.chart.params.min_y = ($scope.user.reason.id == 0) ? 0 : nest.growth.min * 0.9;
        $scope.calculations.retirement_chart.params.min_y = ($scope.user.reason.id != 1 || $scope.calculations.retirement_duration) ? 0 : comp.draw.min * 0.9;

        $scope.calculations.max_growth = $filter('number')($scope.calculations.chart.params.max_y);

        //phrase calcs
        if($scope.user.reason.id == 1)
          $scope.calculations.advantage = $filter('number')(adv_at_comp);
        else
          $scope.calculations.advantage = $filter('number')(nest.growth.max-comp.growth.max);

        $scope.calculations.draw_target = $filter('number')(nest.drawTarget.draw - comp.drawTarget.draw, 0);

        for(var i = 0; i < nest.growth.data.length-1; i++)
          if(nest.growth.data[i] > comp.growth.data[comp.growth.data.length-1])
            break;

        $scope.calculations.growth_diff = comp.growth.data.length - i;

        //TODO: FORMULAS
        var AverageAmortizationPeriod = 300;
        var AverageInterestRate = 0.06;
        var AverageMortgage = 352000;
        var future = 0;
        var NestAdvantage = nest.growth.max-comp.growth.max
        $scope.user.nest_wealth_advantage = NestAdvantage
        $scope.user.typical_mutual_fund_value = comp.growth.max
        $scope.user.nest_wealth_value = nest.growth.max

        $scope.calculations.fewer_months = $filter('number')(AverageAmortizationPeriod - formulas.nper(AverageInterestRate/12, 0 - formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, 0 - AverageMortgage,0), AverageMortgage - NestAdvantage, 0,0), 0);
        $scope.calculations.fewer_months = ($scope.calculations.fewer_months > 18)
          ? Math.round($scope.calculations.fewer_months/12) + " years</span> fewer"
          : $scope.calculations.fewer_months + " months</span> fewer";

        $scope.calculations.mortgage_savings = $filter('number')(AverageAmortizationPeriod * formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, 0 - AverageMortgage, 0) - formulas.nper(AverageInterestRate/12, 0 - formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, 0 - AverageMortgage, 0 ), AverageMortgage - NestAdvantage) * formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, 0 - AverageMortgage, 0, 0), 0);

        var comp_mortgage = comp.growth.max/0.2 - comp.growth.max;
        var nest_mortgage = comp.growth.max/0.2 - comp.growth.max - NestAdvantage;
        var comp_payment_amt = -formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, comp_mortgage, 0, 0);
        var nest_payment_amt = -formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, nest_mortgage, 0, 0);
        var number_months = Math.round(AverageAmortizationPeriod - formulas.nper(AverageInterestRate/12, 0 - formulas.pmt(AverageInterestRate/12, AverageAmortizationPeriod, 0 - comp_mortgage,0), comp_mortgage - NestAdvantage, 0, 0));
        number_months = isNaN(number_months) ? 0 : number_months;

        var p = nest_mortgage;
        var nest_interest_saved = 0;
        for(var i = 0; i < AverageAmortizationPeriod-number_months; i++) {
          if(p <= 0) break;

          var interest = p * AverageInterestRate/12;
          p += interest - comp_payment_amt;
          nest_interest_saved += interest;
        }

        var p = comp_mortgage;
        var comp_interest_saved = 0;
        for(var i = 0; i < AverageAmortizationPeriod; i++) {
          if(p <= 0) break;

          var interest = p * AverageInterestRate/12;
          p += interest - comp_payment_amt;
          comp_interest_saved += interest;
        }

        var interest_saved = Math.round(comp_interest_saved - nest_interest_saved);
        interest_saved = Math.round(interest_saved/Math.pow(10, String(interest_saved).length-3))*Math.pow(10, String(interest_saved).length-3);

        var mortgage_value = Math.round((comp.growth.max/0.2 - comp.growth.max)/0.8);
        mortgage_value = Math.round(mortgage_value/Math.pow(10, String(mortgage_value).length-3))*Math.pow(10, String(mortgage_value).length-3);

        //new mortgage
        $scope.calculations.greater_value = $filter('number')(NestAdvantage/0.2, 0);
        $scope.calculations.mortgage_value = $filter('number')(mortgage_value, 0);
        $scope.calculations.mortgage_months = number_months;

        $scope.calculations.mortgage_months = ($scope.calculations.mortgage_months > 18)
          ? Math.round($scope.calculations.mortgage_months/12) + "&nbsp;years</span>"
          : $scope.calculations.mortgage_months + "&nbsp;months</span>";

        $scope.calculations.interest_saved = $filter('number')(interest_saved, 0);

        $scope.updatePhrase();

        if(isFirstRun) {

          $timeout(function() {

            $scope.chartA1 = true;

          }, 0);

          $timeout(function() {

            $scope.chartA2 = true;

            $scope.calculations.chart.line[0].series = comp.growth.data;
            $scope.calculations.chart.line[1].series = comp.growth.data;
            $scope.calculations.retirement_chart.line[0].series = comp[selected_draw].data;
            $scope.calculations.retirement_chart.line[0].draw_target = comp[selected_draw].draw;
            $scope.calculations.retirement_chart.line[1].series = comp[selected_draw].data;
            $scope.calculations.retirement_chart.line[1].draw_target = comp[selected_draw].draw;

          }, 1000);

          $timeout(function() {

            $scope.chartA3 = true;

            $scope.calculations.chart.line[0].series = nest.growth.data;
            $scope.calculations.retirement_chart.line[0].series = nest[selected_draw].data;
            $scope.calculations.retirement_chart.line[0].draw_target = nest[selected_draw].draw;

          }, 2000);

          $timeout(function() {

            $scope.chartA4 = true;

          }, 3500);

          isFirstRun = false;

        } else {

          $scope.calculations.chart.line[0].series = nest.growth.data;
          $scope.calculations.chart.line[1].series = comp.growth.data;
          $scope.calculations.retirement_chart.line[0].series = nest[selected_draw].data;
          $scope.calculations.retirement_chart.line[0].draw_target = nest[selected_draw].draw;
          $scope.calculations.retirement_chart.line[1].series = comp[selected_draw].data;
          $scope.calculations.retirement_chart.line[1].draw_target = comp[selected_draw].draw;

        }

      }

      $scope.calculateChart = calculateChart;

      $scope.$watch('[user.reason]', function(newVal, oldVal) {

        if($scope.user.reason.id == 1) {
          $scope.show_chart_draw = true;
          $scope.calculations.retirement_chart.advantage.type = 'draw';
        } else {
          $scope.show_chart_draw = false;
          $scope.calculations.retirement_chart.advantage.type = 'value_year';
        }

      }, true);

      $scope.$watch('[edit.horizon]', function(newVal, oldVal) {

        if($scope.user.horizon != $scope.edit.horizon)
          chartWatch(0, false);

      }, true);

      $scope.$watch('[user.horizon]', function(newVal, oldVal) {

        if($scope.user.horizon != $scope.edit.horizon) {
          $scope.edit.horizon = $scope.user.horizon;
          chartWatch(250, true);
        }

      });

      $scope.$watchCollection('[user.age, user.principle, user.yearly_contribution, user.yearly_draw, user.reason]', function(newVal, oldVal) {

        chartWatch(250, true);

      });

      var chartWatch = function(delay, animate) {

        if(!user) return;

        $scope.user.horizon = $scope.edit.horizon;
        $scope.calculations.target_retirement = ($scope.user.reason.id == 1)
          ? parseInt($scope.user.age, 10)
          : parseInt($scope.user.age, 10) + parseInt($scope.user.horizon, 10)
        $scope.calculations.chart.animate = animate;

        $timeout.cancel(charttimeout);
        charttimeout = $timeout(function() {
          calculateChart();
        }, delay);
      }

      angular.element($window).bind('resize', function() {
        $scope.updatePhrase();
      });

    }
  ]);