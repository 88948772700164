angular.module('app.questionnaire.modals.directives', [])

.directive('joinQuestionnaireModals', [
	'$timeout',
	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	function(
		$timeout,
		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'join_webserver/questionnaire/modals/template.html',
			scope: {
				province_modal_open: '=provinceModalOpen'
				// ffs_modal_open: '=ffsModalOpen'
			},
			link: function(scope, element, attrs) {

				scope.organization_data = organization_data;
				
				scope.modals = {
					// ffs_modal: new NwuiModalV2Factory({
					// 	open: false,
					// 	wide: true,
					// 	header: {
					// 		name_i18n: 'questionnaire.modals.ffs_modal.title',
					// 		icon: {
					// 			name: 'info'
					// 		}
					// 	}
					// }),
					alt_province_modal: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: {
							// name: 'Province Availability',
							name_i18n: 'questionnaire.modals.province_availability.title',
							icon: {
								name: 'info'
							}
						},
						copy: {
							buttons: {
								yes: new NwuiButtonV3Factory({
									id: 'provicial-selection-modal-yes',
									text_i18n: 'questionnaire.modals.province_availability.button_yes',
									stretch: true,
									icon: {
										name: 'check'
									},
									onClick: function() {
										scope.province_modal_open = false;
									}
								}),
								no: new NwuiButtonV3Factory({
									id: 'provicial-selection-modal-no',
									text_i18n: 'questionnaire.modals.province_availability.button_no',
									color: 'error',
									stretch: true,
									icon: {
										name: 'close'
									},
									onClick: function() {
										window.location.href = 'https://www.nestwealth.com';
									}
								})
							}
						}
					})	
				}

				// scope.$watch('ffs_modal_open', function(new_val, old_val) {
					

				// 	if(new_val === undefined) return;

				// 	scope.modals.ffs_modal.open = new_val;
					
				// });

				scope.$watch('province_modal_open', function(new_val, old_val) {
					

					if(new_val === undefined) return;

					scope.modals.alt_province_modal.open = new_val;
					
				});

			}
		}
	}
]);