/* eslint-disable */

angular.module('app.questionnaire.controllers', [])

.controller('QuestionController', [
  '$rootScope', 
  '$scope', 
  '$sce', 
  '$parse', 
  '$timeout', 
  '$http', 
  '$location', 
  '$state',
  '$stateParams',
  '$window',
  '$q',
  'locale',
  'FixedsliderService', 
  'FormService', 
  'KYCInstance', 
  'Session',
  'KYCInstanceID',
  'KYCAnswers',
  'PieData', 
  'AUTH_EVENTS',
  'ScoreData',
  'KYCInstanceData',
  'AuthModalLaunchService',
  'NwuiButtonV3Factory',
  'NwuiDrawerV1Factory',
  'NwuiInputService',
  function(
    $rootScope, 
    $scope, 
    $sce, 
    $parse, 
    $timeout, 
    $http, 
    $location, 
    $state,
    $stateParams,
    $window,
    $q,
    locale,
    FixedsliderService, 
    FormService, 
    KYCInstance, 
    Session, 
    KYCInstanceID, 
    KYCAnswers, 
    PieData, 
    AUTH_EVENTS, 
    ScoreData,
    KYCInstanceData, 
    AuthModalLaunchService,
    NwuiButtonV3Factory,
    NwuiDrawerV1Factory,
    NwuiInputService
  ) {
    FormService.isKYC = true;
    var URL_BASE = 'api/risk-profile/';
    var URL_ASSET = 'api/assets';
    var URL_PORTFOLIO = '/portfolio';
    var URL_RESET = '/portfolio/reset';

    if(KYCInstanceData.kyc_instance.onboarding_locked) {
      FormService.setFormReadOnly(KYCInstanceData.kyc_instance.steps)
    }

    var data = {
      visualization: ((((organization_data || {}).join_data || {}).questionnaire || {}).visualization || 'pie'),
      workflow_stages: (
        organization_data &&
        organization_data.join_data &&
        organization_data.join_data.workflow &&
        organization_data.join_data.workflow.stages
      ) ? organization_data.join_data.workflow.stages : [{ state: 'questionnaire' }, { state: 'forms' }]
    }

    $scope.data = {
      env: env
    }

    $scope.riskid = KYCInstanceData.kyc_instance._id;

    $scope.final_score = undefined;

    $scope.mobilePieButton = new NwuiButtonV3Factory({
      id: "mobile-pie-button",
      icon : {
        name: 'donut_large'
      },
      text_i18n : "questionnaire.drawer.button",
      stretch: true,
      color: "warning",
      onClick : function() {
        $scope.mobileGraphDrawer.open = !$scope.mobileGraphDrawer.open;
      }
    });

    $scope.mobileGraphDrawer = new NwuiDrawerV1Factory({
      id: 'mobile-pie-drawer',
      open: false,
      name_i18n: "questionnaire.drawer.drawer_title",
      icon: {
        name: "donut_large"
      },
      empty_icon: {
        name: ""
      },
      orientation: 'right',
      z_index: 3001
    })

    var current_stage_index = 2;    
    for(var i = 0; i < data.workflow_stages.length; i++) {
      if(data.workflow_stages[i].state === 'questionnaire') {
        current_stage_index = i+1;
        break;
      }
    }

    $scope.setOnboardingStep(current_stage_index);
    $scope.user = angular.extend(Session);
    $scope.show_province_modal = false;
    $scope.tax_benefits_modal = false;
    
    if(KYCInstanceData.pie_data.name) {
      $scope.pie_data_name = setLanguageKey(KYCInstanceData.pie_data.name);
      $scope.final_score = KYCInstanceData.kyc_instance.display_score;
      $scope.riskid = KYCInstanceData.kyc_instance._id;
      $scope.assets = KYCInstanceData.assets;
    } else if(data.visualization === 'score') {
      $scope.pie_data_name = "pending";
    }

    $timeout(function() {
      $scope.questionLoaded = true;
    }, 500);


    
    function setLanguageKey(val){
      if(!val) return undefined;

      return val.replace(/[^\w\s]/g , "").replace(/ +/g , "_").toLowerCase();
    }

    //pie
    var processPieData = function() {
        //Since the server passes back the english string, we need to turn that string into a key 
        //with which to look up the strings in the localization files.

        angular.forEach(PieData.data, function(datum, i) {
              datum.type.i18n_key = setLanguageKey(datum.type.key);
              datum.dimensions.forEach(function(dimension){
                dimension.i18n_key = setLanguageKey(dimension.key);
              });
              if(datum.y == -1){

              }else {
                  PieData.total += datum.y;
              }

        });

        angular.forEach(PieData.data, function(datum, i) {
          
              PieData.legend.push({
                  key: datum.key,
                  i18n_key: datum.i18n_key,
                  description: datum.description,
                  value: datum.y,
                  color: datum.color,
                  percent: Math.round(datum.y/PieData.total * 100)
              });

      });
      
    }
    
    $scope.current_step;
    $scope.answers;
    
    var province_change = function(field) {
      
      if(organization_data.join_data.questionnaire.province_check === true) {
        switch(field.input.answer) {
          case 'ON':
          case 'AB':
          case 'BC':
          case 'MB':
          case 'NB':
          case 'NL':
          case 'NS':
          case 'PE':
          case 'QE':
          case 'SK':
            break;
          default:
            $scope.show_province_modal = true;
            field.input.answer = ""
            NwuiInputService.inputs["join-forms-province"].clearCb();
            NwuiInputService.inputs["join-forms-province"].model = "";

            var element = document
              .getElementById('join-forms-province')
              .getElementsByTagName('select')[0];
            element.value = "";
        }
      }
    }

    var step_change = function() {
      $scope.current_step_index = FormService.current_step.index;
    }

    var kycInstanceFromServer = {};
    
    var form_complete = function() {
      $scope.questionCompleted = true;
    }
    
    var form_save_func = function(step, cb) {
      
      if(KYCInstanceData.kyc_instance.onboarding_locked
      || kycInstanceFromServer.onboarding_locked) {
        cb(null);
      }
      else {
        FormService.prepareAndSendKycInstance(step)
        .then(function(res){
          kycInstanceFromServer = res.data.kyc_instance;
          ScoreData.data = res.data.score_data
          PieData.setData(res.data.pie_data)
          processPieData()

          if(res.data.pie_data.name) {
            $scope.pie_data_name = setLanguageKey(res.data.pie_data.name);
            $scope.final_score = res.data.kyc_instance.normalized_score;
          } else if(data.visualization === 'score') {
            $scope.pie_data_name = "pending";
          }
          
          $rootScope.$broadcast('new_pie_data');
          
          if(res.data.kyc_instance.onboarding_locked) {
            FormService.setFormReadOnly(FormService.steps);
          }

          FormService.set_answers(res.data.kyc_answers);

          for(var key in res.data.kyc_answers) {
            (FormService.public_observer_cbs.step_ifs[key] || []).forEach(function(cb) {
              cb(res.data.kyc_answers[key]);
            });
          }

          cb(null);
        }, function(error) {
          cb(error);
        });

      }
    
      
    }

    //Used root scope since processPieData() exists here, easy access from a2 component.. not ideal
    $rootScope.update_pie_data = function(data) {
      PieData.setData(data)
      processPieData()
      $rootScope.$broadcast('new_pie_data');
    };
    $rootScope.pie_disabled = false;
    
    $scope.score_data = {}
    ScoreData.data = KYCInstanceData.score_data
    PieData.create(KYCInstanceData.pie_data)
    $scope.score = ScoreData
    $scope.pie = PieData;
    $scope.pieColorArray = PieData.data.map(function(datum){
      return datum.color
    })
    processPieData()
    KYCAnswers.create(KYCInstanceData.kyc_answers)
    KYCAnswers.age = (KYCAnswers.age == 0) ? '' : KYCAnswers.age; //for direct sign up support
    KYCAnswers.horizon = (KYCAnswers.horizon === 0) ? '' : KYCAnswers.horizon; //for direct sign up support
    KYCInstanceID.create(KYCInstanceData.kyc_instance._id)
    
    FormService.form_name = 'question';
    FormService.current_stage_index = 1;
    FormService.set_steps(KYCInstanceData.kyc_instance.steps);
    FormService.set_answers(KYCAnswers);
    FormService.set_save_func(form_save_func);
    FormService.register_step_observer_cb(step_change);
    FormService.register_province_observer_cb(province_change);
    FormService.register_form_complete_observer_cb(form_complete);
    FormService.showing_step_ifs = {};

    $scope.form_steps = FormService.steps;

    var setKYC = function(data){
      $scope.kyc_instance = data.kyc_instance
      $scope.kyc_instance.nodes = data.nodes.map ( function ( datum ) {
        datum.kyc_instance_id = data.kyc_instance.id
        return datum
      } )
    }
    
    //init();
    
    $scope.close_selects = function() {
      FormService.close_all_selects();
    }

    $scope.$on('form-steps-loaded', function() {
      $timeout(function() {
        var state_split = $state.current.name.split('.');
        if(state_split[state_split.length-1] !== 'step') {
          var index = 0;

          for(var i = 0; i < $scope.form_steps.length; i++) {
            if($scope.form_steps[i].showing) {
              index = i;
              break;
            }
          }

          FormService.set_step_by_index(index);
          $state.go($state.current.name + '.step', { step_index: index+1-FormService.getSkippedStepsCount(index) });
        }
      }, 0)
    });

    $scope.checkMobile = function() {
      $scope.isMobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 1023;
    }

    var window_timeout = undefined;
    $scope.$watch(function() {
      return angular.element($window)[0].innerWidth;
    }, function(new_val, old_val) {
      if(new_val === old_val) return;
      $timeout.cancel(window_timeout);
      window_timeout = $timeout(function() {
        $scope.checkMobile();
      }, 150);
    });

    $scope.checkMobile();
  
  }
]);
