appDirectives.directive('authmodal', ['$rootScope', '$q', '$sce', '$timeout', '$cookies', 'AuthService', 'User', 'AUTH_EVENTS','AuthModalLaunchService', function($rootScope, $q, $sce, $timeout, $cookies, AuthService, User, AUTH_EVENTS, AuthModalLaunchService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'join_webserver/partials/auth/auth-modal.html',
        scope:{
			'showing': '='
		},
        link: function(scope, element, attrs) {
			var changeState = function(state) {
				
				scope.showState = state;
				scope.state.flags.sign_in = true;
				scope.state.flags.reset = true;
				scope.state.flags.revalidate = true;
				
			}
			
			var changeResetState = function(show) {
				scope.showRevalidate = show;	
			}
	
	        var validateSignin = function(form, type) {
		        
		        var deferred = $q.defer();
				var email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				
				scope.state.flags.sign_in = true;
				
				$timeout(function() {
					
					scope.error.inputs = [];
					
					if((type == 'submit') || form.email.$touched) {
						if(!scope.user.email) {
							scope.error.message = $sce.trustAsHtml("Please enter your email and&nbsp;password.");
							scope.error.inputs.push('signin-email');
							scope.state.flags.sign_in = false;
						} else if(!email_regex.test(scope.user.email)) {
							scope.error.message = $sce.trustAsHtml("That email address is&nbsp;invalid.");
							scope.error.inputs.push('signin-email');
							scope.state.flags.sign_in = false;
						}
					}
					
					if((type == 'submit') || form.password.$touched) {
						if(!scope.user.password) {
							scope.error.message = $sce.trustAsHtml("Please enter your email and&nbsp;password.");
							scope.error.inputs.push('signin-password');
							scope.state.flags.sign_in = false;
						}
					}
					
					return deferred.resolve();
				
				}, 400);
				
				return deferred.promise;
		        
	        }
	        
	        var signinAccount = function(form) {
				
				if(scope.processing) return true;
				
				scope.processing = true;
		
				validateSignin(form, 'submit')
					.then(function(res) {
						
						var credentials = {
							email: scope.user.email,
							password: scope.user.password
						}
						
						if(scope.state.flags.sign_in) {
							
							//$cookies.remove('token');
                            //$cookies.remove('token', {domain: ".nestwealth.com"});
		
					        AuthService.login(credentials)
					            .then(function(user) {
					                
					                scope.processing = false;
									scope.resetLink = false;
									//scope.showMessage = true;
									
									$rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
					                
					            }, function(res) {

					                scope.processing = false;
									scope.state.flags.sign_in = false;
									scope.resetLink = false;

									switch(res.nw_error_code) {
										case 4501:
											scope.error.message = $sce.trustAsHtml(res.nw_error_message);
											break;
										case 4510:
											scope.error.message = $sce.trustAsHtml(res.nw_error_message);
											scope.resetLink = true;
											break;
										default:
											scope.error.message = $sce.trustAsHtml("Unable to sign&nbsp;in.");	
									}
					                
					            })
							
						} else {
							
							scope.processing = false;
							scope.resetLink = false;
							
						}
						
					}, function(res) {});
				
			}
			
	        var validateReset = function(form, type) {
				
				var deferred = $q.defer();
				var email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				
				scope.state.flags.reset = true;
				
				$timeout(function() {
					
					scope.error.inputs = [];
					
					if((type == 'submit') || form.email.$touched) {
						if(!scope.user.email) {
							scope.error.message = $sce.trustAsHtml("Please enter your&nbsp;email.");
							scope.error.inputs.push('reset-email');
							scope.state.flags.reset = false;
						} else if(!email_regex.test(scope.user.email)) {
							scope.error.message = $sce.trustAsHtml("That email address is&nbsp;invalid.");
							scope.error.inputs.push('reset-email');
							scope.state.flags.reset = false;
						}
					}
					
					return deferred.resolve();
				
				}, 400);
				
				return deferred.promise;
				
			}
	        
	        var resetAccount = function(form) {
				
				if(scope.processing) return true;
				
				scope.processing = true;
		
				validateReset(form, 'submit')
					.then(function(res) {
						
						var credentials= {
							email: scope.user.email
						}
						
						if(scope.state.flags.reset) {
		
					        AuthService.forgotPassword(credentials)
					            .then(function(user) {
					                
					                scope.processing = false;
									scope.showMessage = true;
									
									//success
									scope.successMessage = {
										p: [$sce.trustAsHtml("Success! An email with instructions to reset your password has been sent to <strong>" + scope.user.email + "</strong>")],
										button: {
											event: function() {
												scope.showMessage = false;
												scope.changeState('login');
											},
											text: "Back to login",
											showDismiss: false
										}
									}
									//scope.setCurrentUser(user);
					                //$state.go('');
					                
					            }, function(res) {
					                
					                scope.processing = false;
									scope.state.flags.reset = false;
									
									switch(res.nw_error_code) {
										default:
											scope.error.message = $sce.trustAsHtml("Unable to reset&nbsp;password.");	
									}
					                
					            })
							
						} else {
							
							scope.processing = false;
							
						}
						
					}, function(res) {});
				
			}
	        
	        var validateRevalidate = function(form, type) {
				
				var deferred = $q.defer();
				var email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				
				scope.state.flags.revalidate = true;
				
				$timeout(function() {
					
					scope.error.inputs = [];
					
					if((type == 'submit') || form.email.$touched) {
						if(!scope.user.email) {
							scope.error.message = $sce.trustAsHtml("Please enter your&nbsp;email.");
							scope.error.inputs.push('revalidate-email');
							scope.state.flags.revalidate = false;
						} else if(!email_regex.test(scope.user.email)) {
							scope.error.message = $sce.trustAsHtml("That email address is&nbsp;invalid.");
							scope.error.inputs.push('revalidate-email');
							scope.state.flags.revalidate = false;
						}
					}
					
					return deferred.resolve();
				
				}, 400);
				
				return deferred.promise;
				
			}
			
			var revalidateAccount = function(form) {
				
				if(scope.processing) return true;
				
				scope.processing = true;
		
				validateReset(form, 'submit')
					.then(function(res) {
						
						var credentials= {
							email: scope.user.email
						}
						
						if(scope.state.flags.revalidate) {
		
					        User.revalidate(credentials)
					            .then(function(user) {
					                
					                scope.processing = false;
									scope.showMessage = true;
									
									//success
									scope.successMessage = {
										p: [$sce.trustAsHtml("Success! An email with instructions to complete your account activation has been sent to <strong>" + scope.user.email + "</strong>")],
										button: {
											event: function() {
												scope.showMessage = false;
												scope.changeState('login');
											},
											text: "Back to login",
											showDismiss: false
										}
									}
									//scope.setCurrentUser(user);
					                //$state.go('');
					                
					            }, function(res) {
					                
					                scope.processing = false;
									scope.state.flags.revalidate = false;
									
									switch(res.nw_error_code) {
										default:
											scope.error.message = $sce.trustAsHtml("Unable to send revalidation&nbsp;email.");	
									}
					                
					            })
							
						} else {
							
							scope.processing = false;
							
						}
						
					}, function(res) {});
				
			}
	        
	        var signinBlur = function(form) {
				
				validateSignin(form, 'blur')
					.then(function(res) {}, function(res) {});
				
			}
			
			var resetBlur = function(form) {
				
				validateReset(form, 'blur')
					.then(function(res) {}, function(res) {});
				
			}
			
			var revalidateBlur = function(form) {
				
				validateRevalidate(form, 'blur')
					.then(function(res) {}, function(res) {});
				
			}
			
			var close = function() {
				
				if(!scope.state.force)
					scope.classes.open = false;
				
			}
	
	        scope.state = {
		        force: false,
		        flags: {
			        sign_in: true,
			        reset: true,
			        revalidate: true
		        }
	        }
	        
	        scope.classes = {
		        open: false
	        }
	        
	        scope.error = {
				message: '',
				inputs: []
			}

			scope.classes.open = scope.showing;
			scope.state.force = scope.showing;

	        scope.$on(AUTH_EVENTS.notAuthenticated, function(e, args) {
				scope.classes.open = true;
				scope.state.force = true;
			});
			
			scope.$on(AUTH_EVENTS.loginSuccess, function(e, args) {
				
				scope.classes.open = false;
				
			});
			
			scope.changeState = changeState;
			scope.changeResetState = changeResetState;
			scope.signinBlur = signinBlur;
			scope.signinAccount = signinAccount;
			scope.resetBlur = resetBlur;
			scope.resetAccount = resetAccount;
			scope.revalidateBlur = revalidateBlur;
			scope.revalidateAccount = revalidateAccount;
			scope.close = close;
			
			$rootScope.$broadcast('auth-modal-loaded');
	        
	    },
        controller: ['$scope', function($scope) {
	        
	        
	        
        }]
    };
}] )