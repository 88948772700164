angular.module('app.calculator.config', [
	'ui.router'
])

.config([
	"$stateProvider",
	'NWUI_AUTH_ROLES',
	"$locationProvider",
	"$urlRouterProvider",
	function(
		$stateProvider,
		NWUI_AUTH_ROLES,
		$locationProvider,
		$urlRouterProvider
	) {

		if(organization_data.join_data.calculator.show !== true) return;

		$stateProvider.state('onboarding.calc', {
			url: '/calculator',
			views: {
				"calc": {
				templateUrl: 'join_webserver/partials/calc/onboarding.content.calc.html',
				controller: 'CalcController'
				},
				"edit-info@onboarding.calc": {
					templateUrl: 'join_webserver/partials/calc/onboarding.content.calc.edit-info.html',
					controller: 'CalcEditController'
				},
				"get-started@onboarding.calc": {
					templateUrl: 'join_webserver/partials/calc/onboarding.content.calc.get-started.html'
				},
				"chart@onboarding.calc": {
					templateUrl: 'join_webserver/partials/calc/onboarding.content.calc.chart.html',
					controller: 'CalcChartController'
				}
			},
			data: {
				auth_roles: []
			}
		});

	}
]);
