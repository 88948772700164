'use strict';

angular.module('app.forms.controllers', [])

  .controller('FormController', [
    '$rootScope',
    '$scope',
    '$timeout',
    '$http',
    '$sce',
    '$state',
    'locale',
    'NwuiSession',
    'NwuiAuthService',
    'NwuiAnalyticsService',
    'User',
    'Session',
    'FormInstance',
    'FormAnswers',
    'FormInstanceID',
    'AUTH_EVENTS',
    'FormSteps',
    'FormInstanceData',
    'KYCInstanceData',

    'FormService',
    'KYCService',
    'AuthModalLaunchService',
    'NwuiButtonService',
    'NwButtonService',
    'NwuiToastManagerService',
    'NwuiModalV2Factory',
    'NwuiCardHeaderV2Factory',
    'NwuiButtonV3Factory',
    'NwuiToastFactory',
    '$stateParams',
    '$window',
    function(
      $rootScope,
      $scope,
      $timeout,
      $http,
      $sce,
      $state,
      locale,
      NwuiSession,
      NwuiAuthService,
      NwuiAnalyticsService,
      User,
      Session,
      FormInstance,
      FormAnswers,
      FormInstanceID,
      AUTH_EVENTS,
      FormSteps,
      FormInstanceData,
      KYCInstanceData,

      FormService,
      KYCService,
      AuthModalLaunchService,
      NwuiButtonService,
      NwButtonService,
      NwuiToastManagerService,
      NwuiModalV2Factory,
      NwuiCardHeaderV2Factory,
      NwuiButtonV3Factory,
      NwuiToastFactory,
      $stateParams,
      $window,
    ) {
      function setLanguageKey(val) {
        return val.replace(/[^\w\s]/g, '').replace(/ +/g, '_').toLowerCase();
      }
      const urlParams = new URLSearchParams($window.location.search);
      const redirectFromRpqPrev = urlParams.get('rpq_redirect_prev');
      const redirectFromRpqNext = urlParams.get('rpq_redirect_next');
      // console.log('Redirect from RPQ Next- ' + redirectFromRpqNext);
      // console.log('Redirect from RPQ Prev- ' + redirectFromRpqPrev);
      const clientUserData = NwuiSession.userData.user;
      //console.log(clientUserData);
      var data = {
        workflow_stages: (
          organization_data &&
        organization_data.join_data &&
        organization_data.join_data.workflow &&
        organization_data.join_data.workflow.stages
        ) ? organization_data.join_data.workflow.stages : [{ state: 'questionnaire' }, { state: 'forms' }]
      };

      KYCService.save(KYCInstanceData);
      if (!FormInstanceData) return;

      KYCInstanceData.pie_data.data.forEach(function(datum) {
        datum.type.i18n_key = setLanguageKey(datum.type.key);

        datum.dimensions.forEach(function(dimension) {
          dimension.i18n_key = setLanguageKey(dimension.key);
        });
      });

      $scope.kyc_dimensions = KYCInstanceData.pie_data.data;

      var current_stage_index = 3;
      for (var i = 0; i < data.workflow_stages.length; i++) {
        if (data.workflow_stages[i].state === 'forms') {
          current_stage_index = i + 1;
          break;
        }
      }

      $scope.setOnboardingStep(current_stage_index);
      $scope.user = angular.extend(Session);
      $scope.show_tax_benefits_modal = false;

      var saved_submit = {
        step: undefined,
        cb: undefined
      };

      var form_instance_id = '';

      FormService.isKYC = false;

      var form_save_func = function(step, cb, cb_cancel) {
        return FormService.prepareAndSendFormInstance(step, cb).then(function(res) {
          // segment.identify the user
          NwuiAnalyticsService.identify(NwuiSession.user.uuid, {
            city: res.AHHomeCity,
            province: res.AHHomeProvince,
            country: res.AHHomeCountry,
            maritalStatus: res.AHMaritalStatusValue
          });

          $scope.modals.offline_forms.open = false;
          NwButtonService.buttons['offline-forms-yes-button'].processing = false;

          if ($scope.modals.offline_forms.all_offline)
            $state.go('onboarding.status');
          else
            cb(null);
        }, function(error) {
          if (typeof cb === 'function')
            cb(error);
        });
      };

      var step_change = function() {
        $scope.current_step_index = FormService.current_step.index;
      };

      var form_complete = function() {
        $scope.formCompleted = true;
      };
      var tax_benefits_change = function(field){
        const isCanResident = FormAnswers.AHIsCanResidentValue === "Yes";
        const isJointCanResident = FormAnswers.AHJointIsCanResidentValue === "Yes";
        const isTaxTreatyBenefitsNo = FormAnswers.AHTaxTreatyBenefits === "No";
        const isJointTaxTreatyBenefitsNo = FormAnswers.AHJointTaxTreatyBenefits === "No";
        
        if ((isCanResident && isTaxTreatyBenefitsNo) || (isJointCanResident && isJointTaxTreatyBenefitsNo)) {
          $scope.show_tax_benefits_modal = true;
        }
      }
      FormAnswers.create(FormInstanceData.form_answers);
      FormInstanceID.create(FormInstanceData.form_instance._id);
      FormSteps.data = FormInstanceData.form_instance.steps;

      $scope.overview = FormInstanceData.overview;
      $scope.portfolio = FormInstanceData.portfolio;

      FormService.form_name = 'form';
      FormService.set_steps(FormInstanceData.form_instance.steps);
      FormService.set_answers(FormAnswers);
      FormService.set_save_func(form_save_func);
      FormService.register_step_change_observer_cb(step_change);
      FormService.register_form_complete_observer_cb(form_complete);
      FormService.set_fee_data(FormInstanceData.additionalInformation);
      FormService.set_custodian_slug(FormInstanceData.custodianSlug);
      FormService.register_tax_benefits_observer_cb(tax_benefits_change);
      $scope.custodian_translation_slug = FormService.custodian_translation_slug;
      $scope.locale_prefix = 'forms' + $scope.custodian_translation_slug;
      $scope.form_steps = FormService.steps;

      $rootScope.$on('modalUpdate', function(e, data) {
      // make sure there's a match
        if (data.model && $scope.modules.modals[data.model]) {
          if (data.open)
            $scope.modules.modals[data.model].open = data.open;
          if (data.locked)
            $scope.modules.modals[data.model].locked = data.locked;
        }
      });

      // scope nwui modules
      $scope.modules = {
        modals: {}
      };

      $scope.$on('form-steps-loaded', function() {
        $timeout(function() {
          var state_split = $state.current.name.split('.');
          if (state_split[state_split.length - 1] !== 'step') {
            var index = 0;

            for (var i = 0; i < $scope.form_steps.length; i++) {
              if ($scope.form_steps[i].showing) {
                index = i;
                break;
              }
            }
            if (redirectFromRpqNext && clientUserData.use_rpq) {
              // for MD only now , presuming Personal Information is always on 3rd step 
              index = 2;
            }
            if (redirectFromRpqPrev && clientUserData.use_rpq) {
              // for MD only now , presuming Account Selection is always on 2nd step 
              index = 1;
            }
            FormService.set_step_by_index(index);
            $state.go($state.current.name + '.step', { step_index: index + 1 - FormService.getSkippedStepsCount(index) });
          }

          // steps
          for (var i = 0; i < $scope.form_steps.length; i++) {
          // rows
            for (var j = 0; j < $scope.form_steps[i].rows.length; j++) {
            // fields
              for (var k = 0; k < $scope.form_steps[i].rows[j].fields.length; k++) {
              // if has modal
                if ($scope.form_steps[i].rows[j].fields[k].modal) {
                  $scope.form_steps[i].rows[j].fields[k].modal.content = 'nwui.forms.questions.' + $scope.form_steps[i].rows[j].fields[k].i18n + '.modal.content';
                  $scope.modules.modals[$scope.form_steps[i].rows[j].fields[k].input.model] = new NwuiModalV2Factory(
                    angular.extend({
                      open: false,
                      locked: false,
                      header: new NwuiCardHeaderV2Factory({
                        name_i18n: 'nwui.forms.questions.' + $scope.form_steps[i].rows[j].fields[k].i18n + '.modal.title',
                        icon: {
                          name: 'info_circle'
                        }
                      })
                    }, $scope.form_steps[i].rows[j].fields[k].modal)
                  );
                }
              }
            }
          }

          $scope.formLoaded = true;
        }, 0);
      });

      $scope.addStep = function(name, index) {
        var step;
        switch (name) {
          case 'spouse':
            step = spouse_step;
        }
        step.indicator = $scope.form_steps.data.length + 1;
        $scope.form_steps.data.push(step);
      };
      var spouse_step = {
        indicator: 0,
        title: 'Spousal Information',
        subtitle: 'Information needed about your spouse',
        complete: false,
        showing: false,
        rows: [
          {
            ng_if: [
              {
                ng_if_model: 'AHMaritalStatusValue',
                ng_if_values: [
                  'Married',
                  'CommonLaw',
                  'CivilUnion'
                ]
              }
            ],
            fields: [
              {
                width: 20,
                label: 'Salutation',
                description: '',
                input: {
                  input_type: 'radio',
                  placeholder: '--',
                  model: 'AHSSalutationValue',
                  options: [
                    {
                      id: 0,
                      value: 'Mr',
                      text: 'Mr'
                    },
                    {
                      id: 1,
                      value: 'Mrs',
                      text: 'Mrs'
                    },
                    {
                      id: 2,
                      value: 'Miss',
                      text: 'Miss'

                    },
                    {
                      id: 3,
                      value: 'Ms',
                      text: 'Ms'
                    },
                    {
                      id: 4,
                      value: 'Dr',
                      text: 'Dr'
                    }
                  ]
                }
              },
              {
                width: 40,
                label: 'First name',
                description: '',
                input: {
                  input_type: 'input',
                  model: 'AHSFirstName',
                  placeholder: 'John',
                  maxlength: 20,
                  validate: 'alphabetic'
                }
              },
              {
                width: 40,
                label: 'Last name',
                description: '',
                input: {
                  input_type: 'input',
                  model: 'AHSLastName',
                  placeholder: 'Smith',
                  maxlength: 20,
                  validate: 'alphabetic'
                }
              }

            ]
          },
          {
            ng_if: [
              {
                ng_if_model: 'AHMaritalStatusValue',
                ng_if_values: [
                  'Married',
                  'CommonLaw',
                  'CivilUnion'
                ]
              }
            ],
            fields: [
              {
                width: 50,
                label: 'Date of Birth',
                description: '',
                input: {
                  input_type: 'input',
                  model: 'AHSDob',
                  placeholder: 'YYYY-MM-DD',
                  mask: 'date',
                  validate: 'date-past'
                }
              },
              {
                width: 50,
                label: 'Social Insurance Number(SIN)',
                description: '',
                input: {
                  input_type: 'input',
                  model: 'AHSSin',
                  placeholder: '123-456-789',
                  mask: 'sin',
                  validate: 'sin'
                }
              }
            ]
          }
        ]
      };

      $scope.modals = {
        validate_email: new NwuiModalV2Factory({
          open: false,
          locked: true,
          header: new NwuiCardHeaderV2Factory({
            name: 'Verification Required',
            icon: {
              name: 'verified_user'
            }
          }),
          copy: {
            buttons: {
              yes: new NwuiButtonV3Factory({
                id: 'forms-reset-email-button',
                text: 'Resend Email',
                color: '',
                icon: {
                  name: 'email'
                },
                stretch: true,
                onClick: function() {
                  NwButtonService.buttons['forms-reset-email-button'].processing = true;

                  var credentials = {
                    email: NwuiSession.user.email
                  };

                  User.revalidate(credentials).then(
                    function(user) {
                      NwButtonService.buttons['forms-reset-email-button'].processing = false;

                      NwuiToastManagerService.register(
                        new NwuiToastFactory({
                          text: 'Email Sent Successfully!',
                          icon: {
                            name: 'check'
                          }
                        }
                        ));
                    }
                  );
                }
              })
            }
          }
        }),
        offline_forms: new NwuiModalV2Factory({
          all_offline: false,
          open: false,
          locked: true,
          wide: false,
          header: new NwuiCardHeaderV2Factory({
            name: 'Offline Forms',
            icon: {
              name: 'info_circle'
            }
          }),
          copy: {
            buttons: {
              yes: new NwuiButtonV3Factory({
                id: 'offline-forms-yes-button',
                text: 'Okay',
                color: '',
                icon: {
                  name: 'check'
                },
                stretch: true,
                onClick: function() {
                  NwButtonService.buttons['offline-forms-yes-button'].processing = true;

                  // if($scope.modals.offline_forms.all_offline) {
                  form_save_func_2(saved_submit.step, saved_submit.cb);
                // } else {
                  // form_save_func_2(saved_submit.step, saved_submit.cb);
                // }
                }
              }),
              no: new NwuiButtonV3Factory({
                id: 'offline-forms-no-button',
                text: 'Cancel',
                icon: {
                  name: 'close'
                },
                color: 'error',
                stretch: true,
                onClick: function() {
                  $scope.modals.offline_forms.open = false;

                  if (saved_submit.cb_cancel)
                    saved_submit.cb_cancel();
                }
              })
            }
          }
        })
      };

      $scope.close_selects = function() {
        FormService.close_all_selects();
      };

      $scope.$watch('modals.offline_forms.open', function(new_val) {
        if (new_val === undefined) return;

        if (new_val === false && NwuiButtonService.buttons['next-step-0-button']) NwuiButtonService.buttons['next-step-0-button'].processing = false;
      });

      $scope.loaded = false;

      var poll_validation = function() {
        NwuiAuthService.isTokenValid().then(function(res) {
          $scope.loaded = true;

          if (res.valid_email === false) {
            $scope.modals.validate_email.open = true;
            $timeout(function() {
              poll_validation();
            }, 5000);
          } else {
            $scope.modals.validate_email.open = false;
          }
        }, function(res) {
          $scope.loaded = true;
        });
      };

      // if validation required
      if (organization_data.join_data.forms.validate_email === true)
        poll_validation();
    }
  ]);
