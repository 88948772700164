/*
appFactories.factory('KYCInstanceFactory', ["KYCInstanceResource","$q", function(KYCInstanceResource, $q){
    function KYCInstance(obj){
        var kyc_instance = this
        kyc_instance.questions = []
        //angular.extend(kyc_instance, obj)
        //if(!kyc_instance.id){
            KYCInstanceResource.save().$promise.then(function(data){
                kyc_instance.questions = data.nodes.map(function(datum){return datum})
                kyc_instance.setData(data.kyc_instance)
            })
        //}else {
        //    KYCInstanceResource.get({id: kyc_instance.id}).$promise.then(function(data){
        //        kyc_instance.questions = data.nodes.map(function(datum){return datum})
        //        kyc_instance.setData(data.kyc_instance)
        //    })
        //}

    }
    KYCInstance.prototype = {
        setData : function(data){
            angular.extend(this, data)
        }
    }
    return KYCInstance

}]);
*/