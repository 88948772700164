appServices.service('KYCInstance',["KYCInstanceResource", "$http", function(KYCInstanceResource, $http){
	
    var KYCInstance = {}
    KYCInstance.get = function(kyc_instance_id) { return KYCInstanceResource.get({id: kyc_instance_id}).$promise }
    KYCInstance.create = function() { return KYCInstanceResource.save().$promise }
    KYCInstance.getChartData = function(kyc_instance_id) {
        return KYCInstanceResource.getChartData({id:kyc_instance_id}).$promise
    }
    return KYCInstance

    /*var promise = KYCInstanceResource.save().$promise.then(function(data) {
        myData.kyc_instance = data.kyc_instance
        myData.nodes = data.nodes.map ( function ( datum ) {
            datum.kyc_instance_id = data.kyc_instance.id
            return datum
        } )
    })

    return {
        promise:promise,
        create : function(){
            return myData;
        }
    }*/
}]);