angular.module('app.intro.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		if(organization_data.join_data.intro.show !== true) return;

		$stateProvider.state('onboarding.intro', {
			url: '/',
			views: {
				"intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.html',
					controller: 'IntroController'
				},
				"step1@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.step1.html',
				},
				"step2@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.step2.html',
				},
				"step3@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.step3.html',
				},
				"step4@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.step4.html',
				},
				"step5@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.step5.html',
				},
				"step6@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.step6.html',
				},
				"errors@onboarding.intro": {
					templateUrl: 'join_webserver/partials/intro/onboarding.content.intro.errors.html',
				}
			},
			data: {
				auth_roles: []
			}
		})

	}
]);