angular.module('app.header.directives', [])

.directive('joinHeader', [
	'$timeout',
	'locale',
	'User',
	'localeSupported',
	'NwuiLocalizeService',
	'UserDataService',
	'DomainParserService',
	'GeneralService',
	'NwuiSession',
	function(
		$timeout,
		locale,
		User,
		localeSupported,
		NwuiLocalizeService,
		UserDataService,
		DomainParserService,
		GeneralService,
		NwuiSession
	) {
		return {
			restrict: "E",
			transclude: true,
			replace: true,
			templateUrl: 'join_webserver/header/template.html',
			link: function(scope, element, attrs) {

				scope.show_header = !organization_data.join_data.custom_header;

				scope.subLogoOpts = {}
				scope.altLogoOpts = {}
				scope.show_header = !organization_data.join_data.custom_header;

				scope.isAdvisorImpersonating = NwuiSession.isAdvisorImpersonating();

				scope.logoOpts = {
					imgName : 'header-logo.png',
					imgPath: 'img/brand/'+window.org_slug
				}

				UserDataService.userDataObs.subscribe(function(res){
					if(res.sub_org_slug) {
						scope.subLogoOpts = {
							imgName : 'sub-header-logo.png',
							imgPath: 'img/brand/'+res.sub_org_slug,
							class: 'sub-logo'
						}
					}
				});

				scope.has_sub_logo = (
					organization_data &&
					organization_data.join_data &&
					organization_data.join_data.header &&
					organization_data.join_data.header.sub_logo
				)

				if(scope.has_sub_logo) {
					scope.altLogoOpts = {
						imgName : 'alt-header-logo.png',
						imgPath: 'img/brand/'+org_slug,
						class: 'alt-logo'
					}
				}

				scope.has_localization = (
					organization_data &&
					organization_data.join_data &&
					organization_data.join_data.localization &&
					organization_data.join_data.localization.length > 1
				)

				scope.show_localization = (
					organization_data &&
					organization_data.join_data &&
					organization_data.join_data.language &&
					organization_data.join_data.language.toggle &&
					organization_data.join_data.language.toggle.post_auth
				)

				if(
					organization_data &&
					organization_data.footer &&
					organization_data.footer.contact
				) {
					organization_data.footer.contact.forEach(function(contact) {
						var notClient = (
							NwuiSession.user && 
							NwuiSession.user.Role && 
							NwuiSession.user.Role.name_slugged !== 'client'
						);
						if((contact.type === 'phone' || contact.type === 'email') && notClient) {
							contact.label = contact.label + "_admin";
						}
					})
				}

				var appendLangToExternalLinks = function(links) {
					return links.map(function(link) {
						link.url = link.url + '?lang=' +  (NwuiLocalizeService.getLang() || 'en-CA').replace('-CA', '');
						return link;
					});
				}

				scope.external_links = organization_data.join_data.header && organization_data.join_data.header.external_links
          ? appendLangToExternalLinks(organization_data.join_data.header.external_links)
          : null;

				scope.data = {
					override_intercom_css: ((organization_data || {}).intercom || {}).override_css,
					user: NwuiSession.user,
					org_name : window.org_slug,
					org: organization_data
				}

				var onLangToggle = function(overrideLang) {

					var domain = DomainParserService.parse(window.location.href);
					var lang;

					if(overrideLang) {
						lang = overrideLang;
					} else if(NwuiLocalizeService.getLang() == "en-CA") {
						lang = 'fr-CA';
					} else {
						lang= 'en-CA';
					}

					locale.setLocaleCookie(lang, domain);

					if(NwuiSession.user && NwuiSession.user.id) {
						User.updateUserLang(lang).then(function(){
							window.location.reload();
						});
					} else {

						GeneralService.setLanguage(lang).then(function(res) {
							window.location.href = res.data[lang] + window.location.pathname + '?lang='+lang;
						}).catch(function() {
							console.error("There was an error switching the language");
						})

					}

				}

				if(window.NW_GLOBAL_HELPERS && typeof window.NW_GLOBAL_HELPERS.langReady === 'function') {
					window.NW_GLOBAL_HELPERS.langReady(onLangToggle);
				}

				scope.buttons = {
					locale_toggle: {
						id: 'header-locale-toggle',
						text_i18n: 'header.help.locale',
						size: 'short',
						color: 'secondary',
						onClick: onLangToggle
					}
				}

			}
		}
	}
]);
