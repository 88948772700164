angular.module('app.questionnaire.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		$stateProvider.state('onboarding.question', {
			url: '/questionnaire',
			views: {
				"question": {
					templateUrl: 'join_webserver/questionnaire/template.html',
					controller: 'QuestionController'
				}
			},
			resolve: {
				KYCInstanceData: ["KYCInstance", "NwuiAuthService", function(KYCInstance, NwuiAuthService) {
					return NwuiAuthService.isTokenValid().then(function() {
						return KYCInstance.create()
					}, function(error) {
					})
				}]
			},
			data: {
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		})

	}
]);