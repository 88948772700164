'use strict';
angular
  .module('app.forms.results.directives', [])

  .directive('joinFormResults', [
    '$timeout',
    '$window',
    'NwuiAccordionFactory',
    'NwuiSession',
    function($timeout, $window, NwuiAccordionFactory, NwuiSession) {
      return {
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
          overview: '=overview',
          portfolio: '=portfolio',
          kyc_dimensions: '=kycDimensions',
        },
        templateUrl: 'join_webserver/forms/results/template.html',
        link: function(scope, element, attrs) {
          var targets = $('.accordion-block .accordion');

          scope.data = {
            user: NwuiSession.user,
            env: env,
            year: moment().format('YYYY'),
            org: organization_data,
          };

          scope.style_content = [];
          scope.organization_data = organization_data;
          scope.isAdvisorImpersonating = NwuiSession.isAdvisorImpersonating();

          scope.accordions = {};
          if (
            organization_data.join_data &&
            organization_data.join_data.forms &&
            organization_data.join_data.forms.show_opening_your_account
          ) {
            scope.accordions.opening_your_account = {
              name_i18n: 'forms.opening_your_account.title',
              expanded: true,
            };
          }

          if (organization_data.join_data.questionnaire.pie.allocation_only) scope.kyc_dimensions = [scope.kyc_dimensions[0]];
          if (scope.kyc_dimensions[0] && !organization_data.join_data.forms.hide_allocation) {
            scope.accordions.portfolio = {
              name_i18n: 'nwui.questionnaire.portfolio.type.' + scope.kyc_dimensions[0].type.i18n_key,
              expanded: true,
            };
          }
          if (scope.kyc_dimensions[1] && !organization_data.join_data.forms.hide_allocation) {
            scope.accordions.portfolio2 = {
              name_i18n: 'nwui.questionnaire.portfolio.type.' + scope.kyc_dimensions[1].type.i18n_key,
              expanded: true,
            };
          }

          scope.toggle = function(e, i) {
            var accordion = $(e.currentTarget).parent();
            var size = accordion.find('.size');
            var content = accordion.find('.content');

            if (accordion.hasClass('open')) {
              accordion.removeClass('open');

              scope.style_content[i] = {
                height: 0,
              };
            } else {
              accordion.addClass('open');
              scope.style_content[i] = {
                height: size.height() + 'px',
              };
            }
          };
          var calibrate = function(obj, i) {
            var accordion = $(obj);
            var accordion_number = accordion.attr('data-accordion');
            var size = accordion.find('.size');
            var content = accordion.find('.content');

            if (accordion.hasClass('open')) {
              scope.style_content[accordion_number] = {
                height: size.height() + 'px',
              };
            } else {
              scope.style_content[accordion_number] = {
                height: 0,
              };
            }
          };

          $timeout(function() {
            targets = $('.accordion-block .accordion');
            angular.forEach(targets, function(title, i) {
              calibrate(title, i);
            });
          }, 500);
        },
      };
    },
  ]);
