appServices.service('PieData', [function(){
    this.setData = function(obj){
        for(var key in this){
            if(this.hasOwnProperty(key)){
                if(typeof key != 'function'){
                    //delete key;
                }
            }
        }
        angular.extend(this,obj)
    }
    this.create = function(obj){
        angular.extend(this,obj)
    }
    this.destroy = function(){
        angular.extend(this, null)
    }
}]);